import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    allVariants: {
      color: 'rgb(230, 230, 230)',
      fontFamily:[
        'IBM PLEX MONO'
      ].join(','),
    },
    button: {
      textTransform: 'none'
    },
    fontFamily:[
      'IBM PLEX MONO'
    ].join(','),
    fontSize: 12,
    h1: {
      fontSize: '1.7rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.4rem',
    },
    h4: {
      fontSize: '1.2rem',
    },
    h5: {
      fontSize: '1.1rem',
    },
    h6: {
      fontSize: '0.9rem',
    },
    subtitle1: {
      fontSize: '1rem',
    },
    textLabel: {
      fontSize: '0.8rem',
    },
    body1: {
      fontSize: '0.75rem',
    },
    body2: {
      fontSize: '0.65rem',
    },
    body3: {
      fontSize: '0.55rem',
    },
    small: {
      fontSize: '0.45rem',
    }
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: 'rgb(230, 230, 230)'
        },
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(20, 20, 20, 1)',
          backgroundImage: 'None',
          padding: 0,
          height: '100%',
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: 'calc(100% - 32px)',
          padding: '16px',
          '&:last-child': {
            paddingBottom: '16px',
          }
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'rgb(230, 230, 230)'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgb(230, 230, 230)',
          fontSize: '0.75rem',
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    text: {
      primary: 'rgb(230, 230, 230)',
      secondary: 'rgba(230, 230, 230, 0.7)',
      disabled: 'rgba(230, 230, 230, 0.5)',
      icon: 'rgba(230, 230, 230, 0.12)',
      offWhite: 'rgb(230, 230, 230)',
      offBlack: '#1A1A1A'
    },
    scheme1: [
      '#FFB56A', '#62526C', '#5F9596', '#8C965F', '#E8DDB7', '#B876A9', '#611132', '#23661F'
    ],

    tcaScheme:[
      [0.1, "#0ecb81"],
      [0.2, "#44d29b"],
      [0.3, "#7ad9b4"],
      [0.4, "#b0e0cd"],
      [0.5, "#e6e6e6"],
      [0.6, "#e6e6e6"],
      [0.7, "#eabec4"],
      [0.8, "#ee96a2"],
      [0.9, "#f26e80"],
      [1.0, "#f6465d"],
    ],

    exchangeBalance: {
      1: "#2E0038",
      2: "#5D2C6F", // Interpolated between #2E0038 and #C5EBC3
      3: "#8A5989", // Interpolated between #C5EBC3 and #B7C8B5
      4: "#9F6C7F", // Interpolated between #B7C8B5 and #A790A5
      5: "#B25D76", // Interpolated between #A790A5 and #CC7B2F
      6: "#CF8A47", // Interpolated between #CC7B2F and #C5EBC3
      7: "#D8B5A2", // Interpolated between #C5EBC3 and #B7C8B5
      8: "#E4D3A4"  // Interpolated between #B7C8B5 and #CC7B2F
    },
    primary: {
      dark2: '#66482a',
      dark: '#996d40',
      main: '#FFB56A',
      light: '#ffb56a',
      light2: '#ffc88f',
    },
    secondary: {
      dark: '#3b2747',
      main: '#62526c',
      light: '#897d91',
    },
    info: {
      dark3: 'rgb(60, 60, 60)',
      dark: 'rgb(200, 200, 200)',
      main: 'rgb(230, 230, 230)',
      light: 'rgb(230, 230, 230)',
    },
    info2: {
      main: 'rgb(57, 124, 191)',
    },
    color2: {
      dark: '#0ecb81',
      main: '#0ecb81',
      light: '#0ecb81',
    },
    color3: {
      primary: '#b17265',
      secondary: '#4F3466',
      third: '#624185'
    },
    grey: {
      light: 'rgb(180, 180, 180)',
      main: 'rgb(120, 120, 120)',
      dark: 'rgb(49, 50, 51)',
      dark2: 'rgb(40, 40, 40)',
      disabled: 'rgb(102, 102, 102)',
      transparent: 'rgba(100, 90, 100, 0.25)',
    },
    card: {
      main: 'rgb(20, 20, 20)',
      dark: 'rgb(34, 76, 117)',
    },
    orderBook:{
      bid: '#11312f',
      bidPrice: 'rgb(31, 166, 125)',
      ask: '#392831',
      askPrice: 'rgb(237, 112, 136)'
    },
    options:{
      ask: '#ca3f64',
      bid: '#25a750',
      put:'#29171b',
      call: '#152419',
      default: '#141414',
      highlight:'#787878',
    },
    charts: {
      gridLines: '#333333',
      red: '#f6465d',
      redTransparent: 'rgba(246, 70, 93, 0.5)',
      green: '#0ecb81',
      greenTransparent: 'rgba(14, 203, 129, 0.5)',
      offWhite: 'rgb(230, 230, 230)',
      orange: '#ffa344',
      orangeTransparent: 'rgba(255, 163, 68, 0.5)',
      OTC: 'rgb(255, 115, 0)',
      OTCTransparent: 'rgba(255, 115, 0, 0.25)',
      blue: 'rgba(57, 124, 191, 0.8)',
      purple: '#62526c',
      purpleTransparent: 'rgba(98, 82, 108, 0.5)',
      gray: '#787878',
      pink: '#B876A9',
      pinkTransparent: 'rgba(184, 118, 169, 0.5)',
    },
    exchangeColors: {
      OKX: 'rgb(169, 169, 169)',
      OKXTransparent: 'rgba(169, 169, 169, 0.75)',
      Binance: 'rgb(230, 181, 26)',
      BinanceTransparent: 'rgba(230, 181, 26, 0.75)',
      Bybit: 'rgb(230, 138, 26)',
      BybitTransparent: 'rgba(230, 138, 26, 0.75)',
      Deribit: 'rgb(51, 204, 204)',
      DeribitTransparent: 'rgba(51, 204, 204, 0.75)',
      Coinbase: 'rgb(26, 127, 229)',
      CoinbaseTransparent: 'rgba(26, 127, 229, 0.75)',
      MockExchange: 'rgb(255, 255, 255)',
      MockExchangeTransparent: 'rgba(255, 255, 255, 0.75)'
    },
    success: {
      dark2: '#02472c',
      dark: '#087a4d',
      main: '#0ecb81',
      light: '#6ee0b3'
    },
    error: {
      dark2: '#5c141e',
      dark: '#942a38',
      main: '#f6465d',
      light: '#fa909e',
    },
  },
})

export { theme }
