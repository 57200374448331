import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import styled from '@emotion/styled';

const StyledSellToggleButton = styled(ToggleButton)(({ theme }) => {
  return {
    '&.Mui-selected': {
      backgroundColor: theme.palette.charts.red,
      color: theme.palette.text.primary,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.charts.redTransparent,
    },
    color: theme.palette.text.disabled,
    py: 0,
  };
})

const StyledBuyToggleButton = styled(ToggleButton)(({ theme }) => {
  return {
    '&.Mui-selected': {
      backgroundColor: theme.palette.charts.green,
      color: theme.palette.text.offBlack,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.charts.greenTransparent,
    },
    color: theme.palette.text.disabled,
    py: 0,
  };
})

export function BuySellButtons({
  handleSelectedSide,
  isBuySide,
  selectedPair,
  selectedSide,
  isCompact,
}) {
  return (
    <ToggleButtonGroup
      exclusive
      fullWidth
      style={{height: isCompact ? '50%' : '100%'}}
      value={selectedSide}
      onChange={(e, newpair) => handleSelectedSide(e, newpair, selectedSide, selectedPair)}
    >
      <StyledBuyToggleButton aria-label="buy" value="buy">
        <Typography color={isBuySide ? 'text.offBlack' : 'text.offWhite'} variant='body1'>
            Buy
        </Typography>
      </StyledBuyToggleButton>
      <StyledSellToggleButton aria-label="sell" value="sell">
        <Typography color="text.offWhite" variant='body1'>
            Sell
        </Typography>
      </StyledSellToggleButton>
    </ToggleButtonGroup>
  )
}