import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { AmendButton, PauseResumeButton, CancelButton } from './OrderButtons';
import {ApiError, pauseOrder, resumeOrder, submitCancel} from "../../../../apiServices";
import {BasicModal} from "../../../../shared/Modal";
import {AmendOrderDialog} from "../../../../shared/AmendOrderDialog";

function OrderActions({ OrderSummaryData, showAlert, loadOrderData}) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [handleConfirm, setHandleConfirm] = useState(() => {});
  const [confirmModalText, setConfirmModalText] = useState('');
  const [amendDialogOpen, setAmendDialogOpen] = useState(false);

  if (!OrderSummaryData || Object.keys(OrderSummaryData).length === 0) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100%">
        <ScaleLoader color="#FFFFFF" />
      </Box>
    );
  }

  const {
    id,
    account_names,
    pair,
    side,
    parent_order = undefined,
    pov_target,
    status,
    super_strategy_name,
    unique_venues,
  } = OrderSummaryData;

  const handleCancel = async () => {
    setConfirmModalOpen(false);
    try {
      await submitCancel(id);
      showAlert({ severity: 'success', message: 'Successfully canceled the specified order.' });
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }

    loadOrderData(id);
  };

  const handlePause = async () => {
    setConfirmModalOpen(false);
    try {
      await pauseOrder(id);
      showAlert({ severity: 'success', message: 'Successfully paused the specified order.' });
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }

    loadOrderData(id);
  };

  const handleResume = async () => {
    setConfirmModalOpen(false);
    try {
      await resumeOrder(id);
      showAlert({ severity: 'success', message: 'Successfully resumed the specified order.' });
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }

    loadOrderData(id);
  };

  return (
    <Box alignItems="center" justifyContent="center" sx={{ width: '100%', height: '50%' }}>
      <Stack
        alignItems="center" direction="row" justifyContent="center" spacing={1}
        sx={{ width: '100%', height: '100%' }}>
        <AmendButton setAmendDialogOpen={setAmendDialogOpen} status={status} />
        <PauseResumeButton
          handlePause={handlePause}
          handleResume={handleResume}
          isPaused={status === 'PAUSED'}
          parent_order={parent_order}
          setConfirmModalOpen={setConfirmModalOpen}
          setConfirmModalText={setConfirmModalText}
          setHandleConfirm={setHandleConfirm}
          status={status}
          super_strategy_name={super_strategy_name} />
        <CancelButton
          handleCancel={handleCancel}
          setConfirmModalOpen={setConfirmModalOpen}
          setConfirmModalText={setConfirmModalText}
          setHandleConfirm={setHandleConfirm}
          status={status} />
      </Stack>
      <BasicModal
        confirmButtonText="Yes"
        handleConfirm={handleConfirm}
        message={confirmModalText}
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
      />
      <AmendOrderDialog
        amendDialogOpen={amendDialogOpen}
        exchangeNames={unique_venues}
        orderAccounts={account_names}
        orderId={id}
        OrderSummaryData={OrderSummaryData}
        pair={pair}
        pov_target={pov_target}
        setAmendDialogOpen={setAmendDialogOpen}
        showAlert={showAlert}
        side={side}
      />
    </Box>
  );
}

export { OrderActions };
