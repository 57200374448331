
import { Box, Button, Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import React, { useContext, useState } from 'react';
import { useTheme } from '@emotion/react';
import { cancelAllOrders } from '../../apiServices';
import { ErrorContext } from '../context/ErrorProvider';
import { BasicModal } from '../Modal';

export default function ChipStatusFilter({
  typeFilter,
  statusHighlight,
  dashboardView = false,
  setStatusHighlight,
  setTypeFilter,
  loadOrders,
  optionsView = false,
  selectedPair,
  setPairFilter,
  pairFilter,
  isDev,
}){
  const {setHasError, setErrorContent} = useContext(ErrorContext);
  const theme = useTheme();

  const [cancelAllModalOpen, setCancelAllModalOpen] = useState(false);

  const handleCancelAllConfirm = async () => {
    setCancelAllModalOpen(false);
    try{
      await cancelAllOrders().then((response) => {
        setErrorContent({severity: 'success', message: response.message})
        setHasError(true);
        loadOrders()
      })
    } catch(e) {
      setErrorContent({severity: 'error', message: e.message})
      setHasError(true);
    }
    setHasError(true);
  }

  const handleStatusFilter = (statusType, statusChange = false) => {
    if (statusChange) {
      if (statusHighlight.includes(statusType)) {
        setStatusHighlight([]);
      } else {
        setStatusHighlight([statusType]);
      }
    }

    if ( typeFilter.includes(statusType) ) {
      setTypeFilter((prevHighlight) => {
        return prevHighlight.filter((status) => status!== statusType)
      })
    } else {
      setTypeFilter((prevHighlight) => {
        return [
          ...prevHighlight,
          statusType,
        ]
      })
    }
  }

  const chipSxProps = {
    borderRadius: '3px',
    minWidth: '80px',
  }


  return (
    <Stack
      direction="row"
      justifyContent='space-between'
      spacing={1}
      style={{
        marginBottom: '10px',
        overflowX: 'auto',
      }}>
      <Stack direction="row" spacing={1} style={{overflowX: 'auto'}}>
        {(!optionsView && dashboardView) && <Chip
          disabled={!selectedPair}
          label={selectedPair && selectedPair.id ? selectedPair.id : 'BTC-USDT'}
          sx={{
            borderColor: pairFilter ? 'rgba(255, 181, 106, 0.7) ': theme.palette.text.disabled,
            color: pairFilter ? theme.palette.primary.main : theme.palette.text.disabled,
            ...chipSxProps,
          }}
          variant="outlined"
          onClick={() => setPairFilter(!pairFilter)}
        /> }
        <Divider orientation="vertical" style={{ height: 'auto'}} variant="middle" />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('ACTIVE') ?
            'primary' : 'info'}
          label='Active'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('ACTIVE', true)}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('CANCELED') ?
            'error' : 'info'}
          label='Canceled'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('CANCELED', true)}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('COMPLETE') ?
            'success' : 'info'}
          label='Finished'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('COMPLETE', true)}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('SCHEDULED') ?
            'secondary' : 'info'}
          label='Scheduled'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('SCHEDULED', true)}
        />
        <Divider orientation="vertical" style={{ height: 'auto'}} variant="middle" />
        <Chip
          color={typeFilter.length > 0 && typeFilter.includes('CONDITIONAL') ? 'primary' : 'info'}
          label='Conditional'
          sx={chipSxProps}
          variant="outlined"
          onClick={() => handleStatusFilter('CONDITIONAL')}
        />
        {!optionsView && (
          <Chip
            color={typeFilter.length > 0 && typeFilter.includes('MULTI') ? 'primary' : 'info'}
            label='Multi'
            sx={chipSxProps}
            variant="outlined"
            onClick={() => handleStatusFilter('MULTI')}
          />
        )}
        {isDev && !optionsView && (
          <Chip
            color={typeFilter.length > 0 && typeFilter.includes('CHAINED') ? 'primary' : 'info'}
            label='Chained'
            sx={chipSxProps}
            variant="outlined"
            onClick={() => handleStatusFilter('CHAINED')}
          />
        )}

      </Stack>
      { dashboardView &&
        <Box>
          <Button
            color='error'
            sx={{
              minWidth: '124px',
              whiteSpace: 'nowrap',
            }}
            variant='outlined'
            onClick={() => setCancelAllModalOpen(true)}
          >
            Cancel All
          </Button>
          <BasicModal
            confirmButtonText='Yes'
            handleConfirm={handleCancelAllConfirm}
            message='Are you sure you want to cancel all orders?'
            open={cancelAllModalOpen}
            setOpen={setCancelAllModalOpen}
          />
        </Box>
      }
    </Stack>
  )
}