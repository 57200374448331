import { Box, Card, CardContent, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchChainedOrderDetailData, fetchChainedOrderBenchmarkData } from '../../../apiServices';
import { Loader } from '../../../shared/Loader';
import { ErrorContext } from '../../../shared/context/ErrorProvider';
import { ChainedOrderSummary} from './ChainedSummary'; 

function ChainedOrderDetailsPage() {
  const { uuid } = useParams();
  const [orderBenchmark, setOrderBenchmark] = useState({});
  const [orderSummaryState, setOrderSummaryState] = useState({});
  const [ordersInChain, setOrdersInChain] = useState([]);
  const [chainedOrderStats, setChainedOrderStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };

  const loadOrderData = async (order_id) => {
    let orderData;
    try {
      orderData = await fetchChainedOrderDetailData(order_id);
    } catch (e) {
      showAlert({ severity: 'error', message: `Failed to fetch order details: ${e.message}` });
      return null;
    }

    setOrderSummaryState(orderData.chained_order);
    setOrdersInChain(orderData.orders_in_chain);
    setChainedOrderStats(orderData);
    return orderData;
  };

  useEffect(() => {
    let intervalId;
    const order_id = uuid;

    setIsLoading(true);
    const loadData = async () => {
      let benchmarkData;
      try {
        benchmarkData = await fetchChainedOrderBenchmarkData(order_id);
      } catch (e) {
        showAlert({ severity: 'error', message: `Failed to fetch order details: ${e.message}` });
        return false;
      }

      setOrderBenchmark(benchmarkData);

      const chainedData = await loadOrderData(order_id);
      const isTerminated = chainedData.orders_in_chain.every(
        (order) => order.status === 'COMPLETE' || order.status === 'CANCELED'
      );

      if (!chainedData || isTerminated) {
        clearInterval(intervalId);
      }

      setIsLoading(false);

      return true;
    };

    loadData();
    intervalId = setInterval(() => {
      loadData();
    }, 2000);

    return () => clearInterval(intervalId);
  }, [uuid]);

  if (isLoading) {
    return (
      <Box height="100%">
        <Card>
          <CardContent>
            <Loader />
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Stack direction="row" height="100%" spacing={1} width="100%">
      <Stack style={{ height: '100%', width: '30%' }}>
        <Stack direction="column" spacing={1} style={{ height: '100%' }}>
          <Card style={{ height: '45%' }}>
            <CardContent style={{ height: '100%' }}>
              <ChainedOrderSummary
                accountNames={chainedOrderStats.order_venues}
                orderSummaryData={orderSummaryState}
              />
            </CardContent>
          </Card>
          <Card style={{ height: '50%', padding: 8 }}>
            <CardContent style={{ height: '100%', overflow: 'auto', padding: 8 }}>
              <ChainedOrderBenchmark benchmarkData={orderBenchmark} />
            </CardContent>
          </Card>
        </Stack>
      </Stack>
      <Stack style={{ height: '100%', width: '70%' }}>
        {/* You can add other components here */}
      </Stack>
    </Stack>
  );
}

export default ChainedOrderDetailsPage;
