/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
import { ContentCopyRounded } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Switch, Table, TableBody, TableHead, TableRow, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { connectNettingServer, getDicyCreds, resetPassword, updateUserPreferences } from '../../apiServices';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import { showAlert } from '../../shared/context/util';
import { StyledTableCell } from '../../shared/orderTable/util';
import { OPEN_NEW_TAB_ON_SUBMIT } from '../../constants';

export default function AccountSettings() {
  const { user, setUser, isRetail, loadUserMetadata } = useUserMetadata();
  const [dicyCreds, setDicyCreds] = useState([]);
  const [server, setServer] = useState('');

  const [apiTokenVisible, setApiTokenVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const {setHasError, setErrorContent} = useContext(ErrorContext);

  const [passwordOpen, setPasswordOpen] = useState(false);

  const toggleApiTokenVisibility = () => {
    setApiTokenVisible(!apiTokenVisible);
  };

  useEffect(() => {
    const fetchDicyCreds = async () => {
      try {
        const creds = await getDicyCreds();
        setDicyCreds(creds);
      } catch (error) {
        showAlert({severity: 'error', message: `Error fetching Dicy credentials: ${error.message}`,
          setHasError, setErrorContent});
      }
    };

    fetchDicyCreds();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await connectNettingServer(server)

      const data = await response.json();
      setServer(data.server);
    } catch (error) {
      showAlert({severity: 'error', message: `Failed to connect to netting server: ${error.message}`,
        setHasError, setErrorContent});
    }
  };

  const passwordReset = async (event) => {
    event.preventDefault();
    try {
      await resetPassword( user.user_id, oldPassword, newPassword, confirmPassword)

      showAlert({severity: 'success', message: 'Password reset successfully!', setHasError, setErrorContent});
    } catch (error) {

      showAlert({severity: 'error', message: `Error resetting password: ${  error}`, setHasError, setErrorContent});
    }
  };

  const handleClick = (event) => {
    setPasswordOpen(true);
  };

  const handleClose = () => {
    setPasswordOpen(false);
  };

  const handlePreferenceSwitchChange = async (name, value) => {
    user.preferences[name] = value;
    setUser(user);
    try {
      await updateUserPreferences({[name]: value});
    } catch (error) {
      showAlert({severity: 'error', message: `Failed to update preferences: ${error.message}`,
        setHasError, setErrorContent});
    }

    loadUserMetadata();
  }

  const authTokenHeader = dicyCreds.api_token && `Authorization: Token ${dicyCreds.api_token.key}`;
  const copyCodeToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(authTokenHeader);
    } catch (err) {
      // nothing
    }
  }

  const renderPrefName = (name) => {
    if (name === OPEN_NEW_TAB_ON_SUBMIT) {
      return 'Open new tab on order submit';
    }

    return name;
  }

  const renderValueAsInputField = (name, value) => {
    if (typeof(value) === 'boolean') {
      return (
        <Switch
          checked={value}
          onChange={(e) => {
            handlePreferenceSwitchChange(name, e.target.checked);
          }}
        />
      );
    }

    // figure out how to render other types of values
    return <Typography>{value}</Typography>
  }

  if (Object.keys(user).length === 0) {
    return <div/>
  }

  return (
    <Stack
      spacing={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
      }}
    >
      <Paper sx={{ p: 2, mb: 4, width: '75%' }}>
        <Typography gutterBottom variant="h3">
          Profile
        </Typography>
        {(user && Object.keys(user).length > 0) && (
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>{user.username}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Password</StyledTableCell>
                <StyledTableCell>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={handleClick}
                  >
                    <Typography>Reset Password</Typography>
                  </Button>
                  <Dialog

                    id="password-dialog"
                    open={passwordOpen}
                    onClose={handleClose}
                  >
                    <DialogTitle>Reset Password</DialogTitle>
                    <DialogContent style={{
                      paddingTop: '10px',
                    }}>
                      <Stack direction='column' spacing={2}>
                        <TextField
                          label="Old Password"
                          type="password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <TextField
                          label="New Password"
                          type="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <TextField
                          label="Confirm Password"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <Box>
                          <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={passwordReset}>
                              Reset
                            </Button>
                            <Button variant="contained" onClick={handleClose}>
                              Close
                            </Button>
                          </Stack>
                        </Box>
                      </Stack>
                    </DialogContent>
                  </Dialog>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Account Created</StyledTableCell>
                <StyledTableCell>{user.date_joined}</StyledTableCell>
              </TableRow>
              { isRetail && <TableRow>
                <StyledTableCell>Referral Code</StyledTableCell>
                <StyledTableCell>{user.referral_code}</StyledTableCell>
              </TableRow>
              }
            </TableBody>
          </Table>
        )}
      </Paper>

      {!isRetail &&
      <Paper sx={{ p: 2, mb: 4, width: '75%', position: 'relative', minHeight: '85px'}}>
        <Button
          color="secondary"
          sx={{top: 16, right: 16, position:'absolute'}}
          variant="contained"
          onClick={toggleApiTokenVisibility}>
          <Typography>{ apiTokenVisible ? "Hide API Token" : "Show API Token" }</Typography>
        </Button>
        <Stack direction='column' spacing={2}>
          <Typography gutterBottom variant="h3">
            API Key
          </Typography>
          {(apiTokenVisible && dicyCreds && dicyCreds.api_token) && (
            <Stack direction="row" position='relative' width='100%' >
              <Typography
                component="pre"
                sx={{
                  fontFamily: 'monospace',
                  color: 'error.main',
                  backgroundColor: 'grey.dark',
                  borderRadius: '4px',
                  padding: '8px',
                  overflowX: 'auto',
                  width: '100%',
                }}
              >
                {authTokenHeader}
              </Typography>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: '8px',
                }}
                onClick={copyCodeToClipboard}
              >
                <ContentCopyRounded />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Paper>
      }
      <Paper sx={{p: 2, width: '75%'}}>
        <Stack direction="column" spacing={2}>
          <Typography gutterBottom variant="h3">
            Preferences
          </Typography>

          <Table>
            <TableBody>
              {
                Object.entries(user.preferences).map(([name, value]) => (
                  <TableRow key={name}>
                    <StyledTableCell>{renderPrefName(name)}</StyledTableCell>
                    <StyledTableCell>{renderValueAsInputField(name, value)}</StyledTableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Stack>
      </Paper>
      {!isRetail &&
      <Paper sx={{ p: 2, width: '75%' }}>
        <Stack direction="column" spacing={2}>
          <Typography gutterBottom variant="h3">
            Internal Netting
          </Typography>
          <form action="/account/connect/" id="add_dicy_server" method="post" onSubmit={handleSubmit}>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                placeholder='Server'
                value={server}
                onChange={(e) => setServer(e.target.value)}
              />
              <Button
                color='secondary'
                sx={{whiteSpace: 'nowrap'}}
                type="submit"
                variant="contained"
              >
                Connect
              </Button>
            </Stack>
          </form>
          {Object.keys(dicyCreds).length > 0 && dicyCreds.dicy_credentials && (
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Server</StyledTableCell>
                  <StyledTableCell>User ID</StyledTableCell>
                  <StyledTableCell>API Key</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dicyCreds.dicy_credentials.map((cred) => (
                    <TableRow key={cred.dicy_user_name}>
                      <StyledTableCell>{cred.server}</StyledTableCell>
                      <StyledTableCell>{cred.dicy_user_name}</StyledTableCell>
                      <StyledTableCell>{cred.dicy_token}</StyledTableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          )}
        </Stack>
      </Paper>
      }
    </Stack>
  );
}
