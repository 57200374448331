import { useTheme } from '@emotion/react'
import React, { useRef, useLayoutEffect } from 'react'
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import useWatermark from '../../../../shared/hooks/useWatermark';

const calculateTimeInterval = (origTimeEnd, timeStart) => {
  const timeDelta = Date.parse(origTimeEnd) - Date.parse(timeStart)
  const rawInterval = timeDelta / 5
  const roundedInterval = Math.ceil(rawInterval / 60000) * 60000
  return roundedInterval
}

function PriceSpreadChart({
  data,
  timeStart,
  origTimeEnd
}) {

  const chartComponent = useRef(null);
  const theme = useTheme()

  useLayoutEffect(() => {
    function updateSize() {
      if(chartComponent.current){
        chartComponent.current.chart.reflow();
      }}
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const options = {
    chart: {
      animation: false,
      backgroundColor: 'transparent',
      zooming: {
        mouseWheel: false
      },
      zoomType: null,
      marginLeft: 80,
    },
    series:[
      {
        name: 'Buy Fill',
        type: 'scatter',
        color: theme.palette.charts.green,
        data: data.culminative_fills.buy,
        marker:{
          enabled: true,
          radius: 3,
          symbol: 'circle',
        }
      },
      {
        name: 'Sell Fill',
        type: 'scatter',
        color: theme.palette.charts.red,
        data: data.culminative_fills.sell,
        marker:{
          enabled: true,
          radius: 3,
          symbol: 'circle',
        },
      },
      {
        name:'Buy Mid Price',
        type: 'line',
        data: data.mid_prices.buy,
        color: theme.palette.charts.greenTransparent,
        step: 'left',
        enableMouseTracking: false,
      },
      {
        name: 'Sell Mid Price',
        type: 'line',
        data: data.mid_prices.sell,
        color: theme.palette.charts.redTransparent,
        step: 'left',
        enableMouseTracking: false,
      },
    ],
    yAxis: {
      title: {
        text: "Price",
        style: {
          color: theme.palette.text.offWhite
        },
      },
      opposite: false,
      gridLineColor: theme.palette.charts.gridLines,
      labels: {
        style: {
          color: theme.palette.text.offWhite
        },
      },
    },
    xAxis: {
      type: 'datetime',
      startOnTick: false,
      endOnTick: false,
      softMax: Date.parse(origTimeEnd),
      min: Date.parse(timeStart),
      tickInterval: calculateTimeInterval(origTimeEnd, timeStart), // 4 tick intervals
      dateTimeLabelFormats: {
        minute: '%H:%M'
      },
      labels: {
        style: {
          color: theme.palette.text.offWhite
        },
      },
      ordinal: false,
    },
    legend: {
      enabled: false,
      itemStyle: {
        color: theme.palette.text.offWhite,
      }
    },
    tooltip: {
      outside: true,
      shared: true,
      useHTML: true,
      valueDecimals: 4,
      formatter () {
        try {
          const { series, x } = this
          let s = `<div>${  Highcharts.dateFormat('%B %e, %H:%M:%S', x)  }`;

          const { point } = this
          s += `<br/>${  point.series.name}: <b>${ point.y}</b>`;
          s += `<br/>Quantity: <b>${ Number(point.quantity)}</b>`;

          s += '</div>'

          return s


        } catch (err) {
          return false
        }
      }
    },

    plotOptions: {
      series: {
        keys: [
          'x', 'y', 'quantity'
        ],
        allowPointSelect: false,
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          },
          select: {
            enabled: false
          }
        }
      }
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    }
  };

  const watermarkedOptions = useWatermark({options, position: 'bottom-right'});

  return (
    <HighchartsReact
      constructorType='stockChart'
      containerProps={{ style: { height: "50%" } }}
      highcharts={Highcharts}
      options={watermarkedOptions}
      ref={chartComponent} />
  )

}

export { PriceSpreadChart };