/* eslint-disable no-param-reassign */
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@emotion/react';
import { Button, Typography } from '@mui/material';
import moment from 'moment';


const getSymbolColor = (index, chartColors) => {
  if(index >= 8)
  {
    index %= 8;
  }

  return {
    color: chartColors[index],
    backgroundColor: chartColors[index],
  };
}

const removeDuplicates = (arrayA, key) => {
  return arrayA.reduce((arr, item) => {
    const removed = arr.filter(i => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};

export default function AssetAreaChart({balanceData, selectedAccount, currentAssets, assetOrdering}) {
  const [hiddenLabels, setHiddenLabels] = useState([]);

  const theme = useTheme()

  const chartColors = [
    ...Object.values(theme.palette.exchangeBalance),
  ]

  // Move this to backend, filter out duplicates
  const selectedAccountData = selectedAccount && balanceData[selectedAccount[0]] ? balanceData[selectedAccount[0]] : {};
  Object.entries(selectedAccountData).forEach(x => {
    if(selectedAccountData[x[0]] !== undefined){
      selectedAccountData[x[0]] = removeDuplicates(selectedAccountData[x[0]], 'date')
    }
  })

  const buildNetExposureData = () => {
    if (!selectedAccountData) {
      return [];
    }

    const exposureByDate = Object.values(selectedAccountData).reduce((acc, symbolData) => {
      symbolData.forEach(row => {
        acc[row.date] = (acc[row.date] || 0) + row.notional;
      });
      return acc;
    }, {});

    return Object.entries(exposureByDate).map(([date, notional]) => {
      return { x: new Date(date).getTime(), y: notional };
    }).sort((a, b) => a.x - b.x);
  }

  const netExposureData = buildNetExposureData();


  // use net exposure data's x values as it includes data from all symbols
  const allTimestamps = netExposureData.map(x => x.x);

  const buildNotionalDataBySymbol = () => {
    return Object.entries(selectedAccountData).reduce((notionalBySymbol, [symbol, values]) => {
      if (symbol in hiddenLabels) {
        return notionalBySymbol;
      }

      const dataPoints = values.map(row => ({
        x: new Date(row.date).getTime(),
        y: row.notional
      }));

      const dateLookup = dataPoints.reduce((lookup, point) => {
        lookup[point.x] = true;
        return lookup;
      }, {});

      const completeDataPoints = allTimestamps.reduce((points, timestamp) => {
        if (!(timestamp in dateLookup)) {
          points.push({ x: timestamp, y: 0 });
        }
        return points;
      }, dataPoints);

      completeDataPoints.sort((a, b) => a.x - b.x);
      notionalBySymbol[symbol] = completeDataPoints;

      return notionalBySymbol;
    }, {});
  }

  const smoothData = (data, windowSize) => {
    return data.map((point, index, array) => {
      const start = Math.max(0, index - windowSize + 1);
      const end = index + 1;
      const window = array.slice(start, end);
      const average = window.reduce((sum, p) => sum + p.y, 0) / window.length;
      return { ...point, y: average };
    });
  };

  // if there's only one data point we want to show something on chart since
  // 2 data points are needed to render an area
  const chartDataPointRadius = netExposureData.length === 1 ? 5 : 0;

  const chartData = Object.entries(buildNotionalDataBySymbol())
    .sort((a, b) => assetOrdering[selectedAccount[1]].indexOf(a[0]) - assetOrdering[selectedAccount[1]].indexOf(b[0]))
    .map(([symbol, newChartData], i) => {
      const colors = getSymbolColor(i, chartColors)

      return {
        label: symbol,
        data: newChartData,
        borderColor: colors.color,
        backgroundColor: colors.backgroundColor,
        borderWidth: 0.5,
        pointRadius: chartDataPointRadius,
        fill: "origin",
        stack: "notionalStack",
      };
    });

  // Apply smoothing to each dataset
  const smoothedChartData = chartData.map(dataset => ({
    ...dataset,
    data: smoothData(dataset.data, 3)  // Apply a moving average with a window size of 3
  }));

  const smoothedNetExposureData = smoothData(netExposureData, 3);


  const data = {
    datasets: [
      {
        label: 'Net Exposure',
        data: smoothedNetExposureData,
        borderColor: theme.palette.charts.offWhite,
        borderWidth: 2,
        pointRadius: chartDataPointRadius,
      },
      ...smoothedChartData,
    ],
    labels: allTimestamps,
  }

  const config = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x:
        {
          type: "time",
          time: {
            unit: "day",
          },
        },
      y:
        {
          stacked: true,
        },
    },
    plugins: {
      legend: {
        position: "top",
        onClick: (event, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const ci = legend.chart;
          if (ci.isDatasetVisible(index)) {
            ci.hide(index);
            legendItem.hidden = true;
          } else {
            ci.show(index);
            legendItem.hidden = false;
          }

          if (legendItem.text in hiddenLabels) {
            setHiddenLabels(hiddenLabels.filter(x => x !== legendItem.text));
          } else {
            setHiddenLabels([...hiddenLabels, legendItem.text])
          }
        }
      },
      filler: {
        propagate: false,
      },
      tooltip: {
        mode: 'index',
        callbacks: {
          label (tooltipItem) {
            const value = Number((tooltipItem.formattedValue || '').replace(/,/g, ''));
            if (value === 0) {
              return null;
            }
            return `${tooltipItem.dataset.label  }: ${  value}`;
          },
        },
        filter (tooltipItem) {
          const value = Number((tooltipItem.formattedValue || '').replace(/,/g, ''));
          return value !== '0';
        },
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },

  };

  return (
    <Box sx={{ width: '100%', height: '100%'}}>
      { selectedAccountData !== undefined && Object.keys(selectedAccountData).length > 0 ?
        <Line data={data} options={config}/> :
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <Box mb={2}>
            <Typography>
              Get started by linking your exchange API keys
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              window.location.href = '/account/exchange_accounts'
            }}
          >
            Go to Key Management
          </Button>
        </Box>
      }
    </Box>
  );
}