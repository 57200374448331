import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { assignAccountPermission, getAdminPanelData, unassignAccountPermission } from '../../apiServices';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import {
  StyledTableCell
} from '../../shared/orderTable/util';
import { theme } from '../../theme/theme';

function UserTable({ users }) {
  return <Table>
    <TableHead>
      <TableRow>
        <StyledTableCell>ID</StyledTableCell>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell>Joined</StyledTableCell>
        <StyledTableCell>Last Login</StyledTableCell>
        <StyledTableCell>Groups</StyledTableCell>
        <StyledTableCell>Active</StyledTableCell>
        <StyledTableCell>Superuser</StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {users.map((user) => (
        <TableRow key={user.id}>
          <StyledTableCell>{user.id}</StyledTableCell>
          <StyledTableCell>{user.username}</StyledTableCell>
          <StyledTableCell>{user.date_joined}</StyledTableCell>
          <StyledTableCell>{user.last_login}</StyledTableCell>
          <StyledTableCell>{user.group_names}</StyledTableCell>
          <StyledTableCell style={{ color: user.is_active ? theme.palette.success.main : theme.palette.error.main }}>
            {user.is_active.toString()}
          </StyledTableCell>
          <StyledTableCell style={{ color: user.is_superuser ? theme.palette.success.main : theme.palette.error.main }}>
            {user.is_superuser.toString()}
          </StyledTableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
}

function AccountPermissionsTable({ permissions, handleDeleteAccountPermission }) {
  return <Table>
    <TableHead>
      <TableRow>
        <StyledTableCell>User</StyledTableCell>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell>Exchange</StyledTableCell>
        <StyledTableCell>API Key</StyledTableCell>
        <StyledTableCell>Authorized Groups</StyledTableCell>
        <StyledTableCell />
      </TableRow>
    </TableHead>
    <TableBody sx={{overflow: 'auto'}}>
      {permissions.map((cred) => (
        <TableRow key={cred.cred.id}>
          <StyledTableCell>{cred.cred.user}</StyledTableCell>
          <StyledTableCell>{cred.cred.name}</StyledTableCell>
          <StyledTableCell>{cred.cred.exchange}</StyledTableCell>
          <StyledTableCell>{cred.cred.api_key}</StyledTableCell>
          <StyledTableCell>{cred.group.name}</StyledTableCell>
          <StyledTableCell>
            <Button
              color="error"
              variant="contained"
              onClick={() => handleDeleteAccountPermission(cred.cred.id, cred.group.id)}
            >
              Delete
            </Button>
          </StyledTableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
}

function AssignPermissionsForm({ accounts, groups, handleSubmit}) {
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();

    handleSubmit({ account_id: selectedAccount.id, group_id: selectedGroup.id });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Grid container spacing={2}>
        <Grid item md={5}>
          <FormControl fullWidth>
            <InputLabel id="account-label">Accounts</InputLabel>
            <Select
              id="accounts"
              label="Accounts"
              labelId="account-label"
              value={selectedAccount}
              onChange={(e) => setSelectedAccount(e.target.value)}
            >
              {accounts.map((account) => (
                <MenuItem key={account.id} value={account}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={5}>
          <FormControl fullWidth>
            <InputLabel id="group-label">Groups</InputLabel>
            <Select
              id="groups"
              label="Groups"
              labelId="group-label"
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
            >
              {groups.map((group) => (
                <MenuItem key={group.id} value={group}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <Button
            color="primary"
            sx={{ mt: 2 }}
            type="submit"
            variant="contained"
          >
              Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

function AdminPanel() {
  const { user } = useUserMetadata();
  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const [users, setUsers] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [groups, setGroups] = useState(null);
  const [accounts, setAccounts] = useState(null);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  }

  const loadData = async () => {
    let data = null;
    try {
      data = await getAdminPanelData();
    } catch (error) {
      showAlert({severity: 'error', message: `Failed to get users: ${error.message}`});
      return;
    }
    setUsers(data.users);
    setPermissions(data.permissions);
    setGroups(data.groups);
    setAccounts(data.accounts);
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleDeleteAccountPermission = async (account_id, group_id) => {
    try {
      await unassignAccountPermission(account_id, group_id);
    } catch (error) {
      showAlert({severity: 'error', message: `Failed to unassign account permission: ${error.message}`});
    }

    loadData();
  }

  const handleSubmit = async ({account_id, group_id}) => {
    try {
      await assignAccountPermission(account_id, group_id);
    } catch (error) {
      showAlert({severity: 'error', message: `Failed to assign permissions: ${error.message}`});
    }

    loadData();
  }

  if (Object.keys(user).length > 0 && !user.is_superuser) {
    return <Navigate to="/" />
  }

  if (Object.keys(user).length === 0 || !users || !permissions) {
    return <div />
  }

  return (
    <Stack alignItems="center" direction="column" height='100%' overflow="auto" spacing={1}>
      <Box justifyContent="center" width='75%'>
        <Card>
          <CardContent>
            <Typography variant="h5">Users</Typography>
            <UserTable users={users} />
          </CardContent>
        </Card>
      </Box>
      <Box justifyContent="center" width='75%'>
        <Card>
          <CardContent>
            <Typography variant="h5">Account Permissions</Typography>
            <AccountPermissionsTable
              handleDeleteAccountPermission={handleDeleteAccountPermission}
              permissions={permissions} />
            <Stack direction="column" mt={5} spacing={2}>
              <Typography variant="h5">Assign Permissions</Typography>
              <AssignPermissionsForm
                accounts={accounts}
                groups={groups}
                handleSubmit={handleSubmit}
                users={users}
              />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Stack>
  )
}

export default AdminPanel;
