import React from 'react';
import { useTheme } from '@mui/material/styles';  
import { Modal, Typography, Box, Button } from '@mui/material';

const VersionUpdateModal = ({ open, onClose, versionContent, onReadMore }) => {
  const theme = useTheme();
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '27.75rem',
    bgcolor: '#191919',
    boxShadow: '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    p: 4,
    overflow: 'auto',
    fontFamily: "'IBM Plex Mono', monospace",
    color: '#fff'
  };

  const buttonStyle = {
    fontSize: '0.9rem',
    backgroundColor: 'transparent',
    color: '#FFB56A', 
    border: 'none', 
    cursor: 'pointer', 
    transition: 'color 0.3s ease', 
    '&:hover': {
      color: '#ffc88f', 
    },
    '&:focus': {
      outline: 'none' 
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <div dangerouslySetInnerHTML={{ __html: versionContent }} sx={{ mt: 1 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button onClick={onClose} size="small" sx={buttonStyle}>
            Close
          </Button>
          <Button onClick={onReadMore} size="small" variant="contained">
            <Typography variant="h6" color="inherit">
              Read More
            </Typography>
            </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default VersionUpdateModal;
