import React, { useEffect, useRef } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Divider from '@mui/material/Divider';
import {
  StyledHeaderTableCell,
  StyledHeaderTableCellWithLine,
  StyledIBMTypography,
  StyledStrikeTableCell,
  StyledTableOptionCell
} from '../../shared/orderTable/util';

export const exchangeQuoteMapping = {
  'Binance': 'USDT',
  'OKX': 'USDT',
}

export const exchangeColumnMapping = {
  'Binance':{
    markPrice: 'markPrice',
    bidIV: 'bidIV',
    askIV: 'askIV',
    markIV: 'markIV',
    delta: 'delta',
    bid: 'bid',
    ask: 'ask',
    theta: 'theta',
    gamma: 'gamma',
    vega: 'vega',
  },
  'OKX': {
    markPrice: 'markPrice',
    bidIV: 'bidVol',
    askIV: 'askVol',
    markIV: 'markVol',
    delta: 'delta',
    bid: 'bid',
    ask: 'ask',
    theta: 'theta',
    gamma: 'gamma',
    vega: 'vega',
  }
}


export const displayDefaultTableCell = (column, value, style, Cell = StyledTableOptionCell) => {

  if(!value || Math.abs(Number(value).toFixed(2)) === 0.00){
    return (
      <Cell align={column.align} key={`${column.id}defaultCell${value}`} style={style}
      >
        -
      </Cell>
    );
  }

  return (
    <Cell align={column.align} key={`${column.id}defaultCell${value}`} style={style}
    >
      {column.format
        ? column.format(value)
        : (Number(value).toFixed(4))}
    </Cell>
  );
}

export const displayColorTableCell =
 (column, value, ivValue, style, color, percentColor, Cell = StyledTableOptionCell) => {

   const formatValue = (val, iv = false) => {
     if(!value || Math.abs(Number(value).toFixed(2)) === 0.00){
       return '-';
     }
     return iv ? `${(Number(val) * 100).toFixed(2)}%` : Number(val).toFixed(4)
   }

   return (
     <Cell align={column.align} key={`${column.id}cell${value}`} style={style}
     >
       <StyledIBMTypography color={color} sx={{lineHeight: 1.43}}>
         {formatValue(value)}
       </StyledIBMTypography>
       <StyledIBMTypography color={percentColor} sx={{lineHeight: 1.43}}>
         {formatValue(ivValue, true)}
       </StyledIBMTypography>
     </Cell>
   );
 }

export function convertBinanceDateToTaas(input) {
  if (typeof input !== 'string' || input.length !== 6) {
    throw new Error("Input must be a string of length 6 in the format YYMMDD");
  }

  const year = input.slice(0, 2);
  const month = input.slice(2, 4);
  const day = input.slice(4, 6);

  // Assuming the input is in the 21st century (20YY)
  const fullYear = `20${year}`;

  return `${fullYear}.${month}.${day}`;
}

function convertTaasDatetoBinance(input) {
  if (typeof input !== 'string' || input.length !== 10) {
    throw new Error("Input must be a string of length 10 in the format YYYY.MM.DD");
  }

  const [year, month, day] = input.split('.');

  if (year.length !== 4 || month.length !== 2 || day.length !== 2) {
    throw new Error("Invalid date format");
  }

  const shortYear = year.slice(2);

  return `${shortYear}${month}${day}`;
}
export function FocusableDivider({ theme }) {
  const dividerRef = useRef(null);

  useEffect(() => {
    if (dividerRef.current) {
      dividerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

    }
  }, []);

  return (
    <TableRow>
      <TableCell colSpan="100%" style={{ padding: 0 }}>
        <Divider ref={dividerRef} sx={{ height: '2px', backgroundColor: theme.palette.primary.main }} />
      </TableCell>
    </TableRow>
  );
}
