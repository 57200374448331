import TextField from "@mui/material/TextField";
import {ignoreScrollEvent, noArrowStyle} from "../../../../util";
import { NumericFormatCustom } from "../../../../shared/fields/NumberFieldFormat";

function AlgoNumberField({InputProps, hidden, sx, ...props}) {
  if (hidden) {
    return null;
  }

  return (
    <TextField
      fullWidth
      autoComplete='off'
      InputLabelProps={{
        sx: {
          color: 'gray', // Default color for the label
          '&.MuiInputLabel-shrink': {
            color: 'white', // Color when the label is shrunk (after input is entered)
          }
        }
      }}
      InputProps={{
        inputComponent: NumericFormatCustom,
        ...InputProps
      }}
      size="small"
      sx={{
        ...noArrowStyle,
        ...sx
      }}
      onWheel={ignoreScrollEvent}
      {...props}
    />
  )
}
export default AlgoNumberField