/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Stack, Typography, Card, CardContent, Box, CardHeader, ButtonGroup, Button,
  ToggleButtonGroup, ToggleButton
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Link from '@mui/material/Link';
import LeadershipPageComponent from './LeadershipPageComponent';


function PointsPageComponent() {
  return (
    <Stack direction="column" spacing={2}
      sx={{
        height: '100%',
        width: '100%',
        margin: '0 auto'  // centers the grid horizontally
      }}
    >
      <div style={{ width: '100%', height: '40%', marginTop: '10px'}}>
        <Stack direction='row' spacing={2} sx={{ height: '100%', width: '100%' }}>
          <Card style={{ width: '40%', height: '100%' }}>
            <CardContent>
              <Stack direction="row" justifyContent='space-between' spacing={1}>
                <Typography fontSize={16} fontWeight={600}>Total Points</Typography>
                <ButtonGroup align="right" aria-label="Small button group" size="small">
                  <Button key="total_points_all" variant='contained'>All</Button>,
                  <Button key="total_week_all">Week</Button>,
                  <Button key="total_month_all">Month</Button>,
                </ButtonGroup>
              </Stack>
              <Box alignItems="center" display="flex" height='100%' justifyContent="center">
                <Typography variant='h6'>No points yet</Typography>
              </Box>
            </CardContent>
          </Card>
          <Card style={{ width: '60%', height: '100%' }}>
            <CardContent >
              <Stack direction="row" justifyContent='space-between'>
                <Typography fontSize={16} fontWeight={600}>Points Chart</Typography>
                <ButtonGroup align="right" aria-label="Small button group" size="small">
                  <Button key="total_week" variant='contained'>Week</Button>,
                  <Button key="total_month">Month</Button>,
                  <Button key="total_year">Year</Button>,
                </ButtonGroup>
              </Stack>
              <Box alignItems="center" display="flex" height='100%' justifyContent="center">
                <Typography variant='h6'>No points yet</Typography>
              </Box>
            </CardContent>
          </Card>
        </Stack>
      </div>
      <div style={{ height: '50%' }}>
        <Card style={{ width: '100%', height: '100%' }}>
          <CardContent>
            <Typography fontSize={16} fontWeight={600}>Points Activity</Typography>
            <Box alignItems="center" display="flex" height='100%' justifyContent="center">
              <Stack direction="column" spacing={4}>
                <Typography variant='h6'>No points yet</Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    window.location.href = '/'
                  }}
                >
                  Go to Trade
                </Button>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </div>
    </Stack>
  )
}


function PointsPage() {

  const [pointsView, setPointsView] = useState(true);


  return (

    <Box
      container
      spacing={2}
      sx={{
        height: '100%',
        width: '70%',
        margin: '0 auto'  // centers the grid horizontally
      }}
    >
      <div style={{ height: '130px' }} >

        <ToggleButtonGroup  exclusive color='primary' size="small" sx={{paddingBottom: '6px'}}
          value={pointsView} onChange={(e, newValue) => setPointsView(newValue)}>
          <ToggleButton value fontWeight={500} key="total_points_all"
            variant='contained'>Points</ToggleButton>
          <ToggleButton key="total_week_all" value={false} variant='contained'>Leaderboard</ToggleButton>
        </ToggleButtonGroup>
        <Box>
          <Typography fontFamily="Jost" fontSize={48} fontWeight={400} variant='h1'>
            {pointsView ? 'Points' : 'Leaderboard'}
          </Typography>
          { pointsView && <Typography variant='h6'>
          Accumulate points by trading on tread.fi. Points are awarded each month.
            <Link href="#" underline="none">
            Learn more
            </Link>
          </Typography>
          }
        </Box>
      </div>
      {pointsView ? <PointsPageComponent /> : <LeadershipPageComponent />}
    </Box>

  )
}

export default PointsPage;
