import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import { Box, Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { getPairPrice } from "../../apiServices";
import AccountDropdown from "../../shared/fields/AccountDropdown";
import BalancePreview from "../../shared/fields/BalancePreview";
import { NumericFormatCustom } from "../../shared/fields/NumberFieldFormat";
import { theme } from '../../theme/theme';
import { ignoreScrollEvent } from "../../util";
import PairSelector from "../dashboard/orderEntry/PairSelector";
import { PositionSideButtons } from "../dashboard/orderEntry/PositionSideButtons";

function OrderFormItem({
  accounts,
  balances,
  dispatch,
  favouritePairs,
  index,
  orderItemState,
  setFavouritePairs,
  showAlert,
  tokenPairs,
  exchangeSettingsByAccount,
}) {
  const [selectedPairPrice, setSelectedPairPrice] = useState({pair: '', price: 0});
  const [fetchPairAttempts, setFetchPairAttempts] = useState(0);

  const fetchPairPrice = async () => {
    if (fetchPairAttempts > 2 || !orderItemState.pair || orderItemState.accounts.length === 0) {
      return null;
    }

    let pairPrice = selectedPairPrice.price;
    const selectedPairName = orderItemState.pair.id;
    if (selectedPairPrice.pair !== selectedPairName) {
      try {
        const result = await getPairPrice(selectedPairName, orderItemState.accounts[0].exchangeName)
        pairPrice = result[selectedPairName]
      } catch (e) {
        setFetchPairAttempts(fetchPairAttempts + 1);
        return null;
      }
      setFetchPairAttempts(0);
      setSelectedPairPrice({pair: selectedPairName, price: pairPrice});
    }

    return pairPrice;
  }

  const calculateAssetBalance = (symbol) => {
    let totalAmount = 0;

    orderItemState.accounts.forEach(a => {
      if (!balances[a.id]) {
        return;
      }

      balances[a.id].assets.forEach(asset => {
        if(asset.symbol === symbol) {
          totalAmount += asset.amount
        }
      })
    })
    return totalAmount
  }

  const calculateQuoteAssetBalance = (pairPrice) => {
    if(!orderItemState.pair){
      return null
    }

    if (!orderItemState.pair.is_inverse) {
      return calculateAssetBalance(orderItemState.pair.quote);
    }

    return calculateAssetBalance(orderItemState.pair.base) * pairPrice;
  }

  const totalBaseBalance = () => {
    if(!orderItemState.pair){
      return null
    }
    const baseAsset = orderItemState.pair.is_contract ? orderItemState.pair.id : orderItemState.pair.base;
    return Math.abs(calculateAssetBalance(baseAsset));
  }

  const handleAccountChange = (value) => {
    const newAccountValue = typeof value=== 'string' ? value.split(',') : value;

    const selectedAccounts = newAccountValue.map(account_name => accounts[account_name]);

    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: selectedAccounts,
        pair: orderItemState.pair,
        side: orderItemState.side,
        qty: orderItemState.qty,
        isBaseAsset: orderItemState.isBaseAsset,
        posSide: orderItemState.posSide,
      }
    });
  }

  const handlePairChange = (value) => {
    if (!value) {
      dispatch({
        type: 'UPDATE_ROW',
        payload: {
          rowIndex: index,
          accounts: orderItemState.accounts,
          pair: null,
          side: orderItemState.side,
          qty: orderItemState.qty,
          isBaseAsset: orderItemState.isBaseAsset,
          posSide: orderItemState.posSide,
        }
      });
      return;
    }

    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: orderItemState.accounts,
        pair: value,
        side: orderItemState.side,
        qty: orderItemState.qty,
        isBaseAsset: orderItemState.isBaseAsset,
        posSide: orderItemState.posSide,
      }
    });
  }

  const handleQtyChange = (event) => {
    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: orderItemState.accounts,
        pair: orderItemState.pair,
        side: orderItemState.side,
        qty: event.target.value,
        isBaseAsset: orderItemState.isBaseAsset,
        posSide: orderItemState.posSide,
      }
    });
  }

  const handlePosSideChange = (value) => {
    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: orderItemState.accounts,
        pair: orderItemState.pair,
        side: orderItemState.side,
        qty: orderItemState.qty,
        isBaseAsset: orderItemState.isBaseAsset,
        posSide: value,
      }
    });
  }

  const handleDeleteOnClick = () => {
    dispatch({
      type: 'REMOVE_ROW',
      payload: {
        rowIndex: index,
        side: orderItemState.side,
      }
    });
  }

  const isPairNameValidForDeconstruct = orderItemState.pair &&
    orderItemState.pair.id.match(/([a-zA-Z0-9]+)(:\w+)?-([a-zA-Z0-9]+)/);
  const conditionalAssetName = orderItemState.pair &&
    (orderItemState.isBaseAsset ? orderItemState.pair.base : orderItemState.pair.quote);
  const asset = !orderItemState.pair || !isPairNameValidForDeconstruct ? '' : conditionalAssetName;

  const swapAssetTooltipMessage = orderItemState.isBaseAsset ?
    `Swap asset to ${orderItemState.pair?.quote}` : `Swap asset to ${orderItemState.pair?.base}`;
  const swapAssetTooltip = !orderItemState.pair ? '' : swapAssetTooltipMessage;

  const swapAssetOnClick = () => {
    dispatch({
      type: 'UPDATE_ROW',
      payload: {
        rowIndex: index,
        accounts: orderItemState.accounts,
        pair: orderItemState.pair,
        side: orderItemState.side,
        qty: '',
        isBaseAsset: !orderItemState.isBaseAsset,
      }
    });
  }

  const noArrowStyle = {
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    },
    'input[type=number]': {
      MozAppearance: 'textfield',
    }
  };

  const isPairSelected = orderItemState.pair.id !== '';

  const isReadyToPickQty = orderItemState.accounts && orderItemState.accounts.length > 0 && isPairSelected;

  const isHedgeMode = (
    Object.keys(exchangeSettingsByAccount).length > 0 &&
    orderItemState.accounts.length > 0 &&
    orderItemState.accounts.every(acc => {
      const exchangeSettings = exchangeSettingsByAccount[acc.id];
      return exchangeSettings && exchangeSettings.pos_mode === 'long_short_mode';
    })
  );

  const posSideEnabled = isHedgeMode && orderItemState.pair.id && orderItemState.pair.is_contract;

  useEffect(() => {
    if (posSideEnabled) {
      handlePosSideChange('long');
    } else {
      handlePosSideChange('');
    }
  }, [posSideEnabled])

  return (
    <Stack alignItems='start' direction='row' padding={2} spacing={1} sx={{paddingBottom: 1, paddingTop: 0}}>
      <Box position='relative' width='30%'>
        <AccountDropdown
          multiple
          accounts={accounts}
          extraStyling={{ height: '50.25px' }}
          handleSelectedAccountsChange={(event) => handleAccountChange(event.target.value)}
          handleSelectedAccountsDelete={(value) => handleAccountChange(value)}
          selectedAccounts={orderItemState.accounts.map(acc => acc.name)}
        />
      </Box>
      <Box
        alignItems='center'
        display="flex"
        height='40.25px'
        justifyContent='center'
        padding='4px'
        sx={{
          cursor: 'pointer',
          border: `1px solid ${theme.palette.text.disabled}`,
          borderRadius: '4px',
        }}
        width='30%'
      >
        <PairSelector
          multiOrder
          accounts={accounts}
          balances={balances}
          favourites={favouritePairs}
          pairs={tokenPairs}
          selectedAccounts={orderItemState.accounts.map(acc => acc.name)}
          selectedPairName={orderItemState.pair.id}
          setFavourites={setFavouritePairs}
          setSelectedPair={handlePairChange}
          showAlert={showAlert}
        />
      </Box>
      <FormControl
        fullWidth
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: isHedgeMode ? '20%' : '40%' }}
        variant="outlined"
      >
        <Box width='100%'>
          <Tooltip
            disableFocusListener={isPairSelected}
            disableHoverListener={isPairSelected}
            title='Please select a trading pair'>
            <OutlinedInput
              fullWidth
              disabled={!isPairSelected}
              endAdornment={
                <>
                  <Typography sx={{display: isPairSelected ? 'inline': 'none'}} variant="body2">
                    {asset}
                  </Typography>
                  <Tooltip title={swapAssetTooltip}>
                    <span>
                      <IconButton disabled={!isPairSelected} onClick={swapAssetOnClick}>
                        <SwapHoriz />
                      </IconButton>
                    </span>
                  </Tooltip>
                </>
              }
              inputComponent= {NumericFormatCustom}
              inputProps={{
                step: 'any',
              }}
              placeholder='Quantity'
              style={{paddingRight: 0}}
              sx={noArrowStyle}
              value={orderItemState.qty}
              onChange={handleQtyChange}
              onWheel={ignoreScrollEvent}
            />
          </Tooltip>
          <BalancePreview
            balances={balances}
            isBase={orderItemState.isBaseAsset}
            isReadyToPickQty={isReadyToPickQty}
            selectedPair={isPairSelected ? orderItemState.pair : null}
            totalBaseAsset={totalBaseBalance}
            // totalQuoteAsset={calculateQuoteAssetBalance}

            totalQuoteAsset = { () => {
              if (!selectedPairPrice.price) {
                fetchPairPrice();
              }
              return calculateQuoteAssetBalance(selectedPairPrice.price);
            }}
          />
        </Box>
      </FormControl>
      { posSideEnabled &&
        <Box height='50.25px' width='20%'>
          <PositionSideButtons
            posSide={orderItemState.posSide}
            setPosSide={handlePosSideChange}
          />
        </Box>
      }
      <Tooltip title='Remove order item'>
        <IconButton
          sx={{
            height: '75%',
          }}
          onClick={handleDeleteOnClick}
        >
          <RemoveCircleOutline sx={{fontSize: 25, color: theme.palette.error.main}} />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}

export default OrderFormItem;