import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import { fetchAccountBalances } from "../../../apiServices";
import * as FormAtoms from "./hooks/useFormReducer";
import { useUserMetadata } from "../../../shared/context/UserMetadataProvider";

const AccountBalanceContext = createContext();

export function AccountBalanceProvider({children, showAlert}) {
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [balances, setBalances] = useAtom(FormAtoms.balancesAtom)
  const { user } = useUserMetadata();
  const getAccountBalances = async () => {
    let data;
    setIsBalanceLoading(true);
    try {
      data = await fetchAccountBalances();
    } catch (e) {
      showAlert({ severity: 'error', message: `Unable to load account balances: ${e.message}` });
      setIsBalanceLoading(false);
      return false;
    }
    const entryBalances = {}

    data.balances.forEach(balance => {
      entryBalances[balance.account_id] = balance
    })

    setBalances(entryBalances);
    setIsBalanceLoading(false);
    return true;
  }

  useEffect(() => {
    if(user && user.is_authenticated) {

      getAccountBalances();

      const intervalId = setInterval(() => { getAccountBalances() }, 13000);
      return () => clearInterval(intervalId);
    }
    return () => {}
  }, []);

  const memoizedAccountBalances = useMemo(() => ({balances, isBalanceLoading}), [balances, isBalanceLoading]);

  return (
    <AccountBalanceContext.Provider value={memoizedAccountBalances}>
      {children}
    </AccountBalanceContext.Provider>
  )
}

export const useAccountBalanceContext = () => useContext(AccountBalanceContext);
