import { Box } from "@mui/material";
import ScaleLoader from "react-spinners/ScaleLoader";
import BeatLoader from "react-spinners/BeatLoader";

export function Loader() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <ScaleLoader color="rgb(230, 230, 230)" />
    </Box>
  )
}

export function ThinLoader() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
      <BeatLoader color="rgb(150, 150, 150)" size={10} speedMultiplier={2/3} />
    </Box>
  )
}
