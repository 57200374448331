import React from 'react';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';
import { BorderRight } from '@mui/icons-material';


function ProgressBar({ progress, orderStatus, isPov, fullWidth = false }) {

  const theme = useTheme();

  const formatPctFilled = () => {
    if(isPov){
      return [theme.palette.success.main, theme.palette.success.dark2]
    }
    if (progress < 99 && orderStatus === 'ACTIVE'){
      return [theme.palette.success.main, theme.palette.success.dark2]
    } if (progress <= 101 && progress >= 95){
      return [theme.palette.success.main, theme.palette.success.dark2]
    } if (progress > 101 || orderStatus === 'COMPLETE'){
      return [theme.palette.error.main, theme.palette.error.dark2]
    }
    return [theme.palette.primary.main, theme.palette.primary.dark2]
  }

  const color = formatPctFilled()

  const containerStyle = {
    position: 'relative',
    height: '15px',
    width: fullWidth ? '120px' : '80px',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center', // Vertically center the text
    justifyContent: 'center', // Horizontally center the text
    border: `2px solid ${color[1]}`,
  };

  const barContainerStyle = {
    position: 'absolute',
    width: '110%',
    height: '100%',
    borderRadius: '5px',
    backgroundColor: 'inhert',
    overflow: 'hidden'
  };

  const barStyle = {
    height: '15px',
    width: `${progress}%`,
    backgroundColor: color[1],
    borderRadius: '0px',
    borderRight: `2px solid ${color[1]}`,

    transition: 'width 0.5s ease-in-out',
    zIndex: 0
  };

  const textStyle = {
    color: color[0],
    zIndex: 1
  };

  return (
    <div style={containerStyle}>
      <div style={barContainerStyle}>
        <div style={barStyle} />
      </div>
      <Typography fontFamily={['Inter', 'Jost']} style={textStyle} variant='body2'>{`${progress}%`}</Typography>
    </div>
  );
}

export default ProgressBar;
