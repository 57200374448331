import { useTheme } from '@emotion/react';
import { HelpOutline, SwapHoriz } from '@mui/icons-material';
import { Box, IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { fetchPreviewPrice } from '../util';
import { ignoreScrollEvent, noArrowStyle, smartRound, validatePair } from "../../../util";
import { NumericFormatCustom } from '../../../shared/fields/NumberFieldFormat';
import * as multiPageAtoms from '../hooks/multiOrderAtoms';

export default function DynamicLimitSpread(
  {
    limitPriceSpread, setLimitPriceSpread,
    showAlert,
    formState
  }
) {
  const [buyPreviewPriceCache, setBuyPreviewPriceCache] = useState({});
  const [sellPreviewPriceCache, setSellPreviewPriceCache] = useState({});
  const [isLoadingBuyPreviewPriceCache, setIsLoadingBuyPreviewPriceCache] = useState(false);
  const [isLoadingSellPreviewPriceCache, setIsLoadingSellPreviewPriceCache] = useState(false);
  const [dynamicLimitBPSFormat, setDynamicLimitBPSFormat] = useAtom(multiPageAtoms.dynamicLimitBPSFormat);

  const theme = useTheme()

  const isOrderCommitted = (order) => {
    return order.accounts.length > 0 && order.pair && order.pair.id !== '';
  }

  const isReadyToPickLimitPrice = formState.buy.length === 1 && formState.sell.length === 1 &&
    isOrderCommitted(formState.buy[0]) && isOrderCommitted(formState.sell[0]);

  const buildPairKey = (exchange, pair) => {
    return `${exchange}|${pair}`;
  }

  const loadPreviewPrice = async (side) => {
    const order = formState[side][0];
    const exchange = order.accounts[0].exchangeName;
    const pair = order.pair.id;
    const key = buildPairKey(exchange, pair);

    if (!validatePair(pair)) {
      return;
    }

    const previewPrice = side === 'buy' ? buyPreviewPriceCache : sellPreviewPriceCache;
    let price = -1;
    if (key in previewPrice) {
      price = previewPrice[key];
    } else {
      if (side === 'buy') {
        setIsLoadingBuyPreviewPriceCache(true);
      } else {
        setIsLoadingSellPreviewPriceCache(true);
      }

      price = await fetchPreviewPrice(exchange, pair, showAlert);

      if (side === 'buy') {
        setIsLoadingBuyPreviewPriceCache(false);
      } else {
        setIsLoadingSellPreviewPriceCache(false);
      }

      const setPreviewLimitPrice = side === 'buy' ? setBuyPreviewPriceCache : setSellPreviewPriceCache;
      setPreviewLimitPrice({...previewPrice, [key]: price});
    }
  }

  useEffect(() => {
    if (isReadyToPickLimitPrice) {
      loadPreviewPrice('buy');
      loadPreviewPrice('sell');
    }

  }, [isReadyToPickLimitPrice, formState])

  useEffect(() => {
    const resetLimitPriceValues = () => {
      setLimitPriceSpread('');
      setBuyPreviewPriceCache({});
      setSellPreviewPriceCache({});
    }

    if (!isReadyToPickLimitPrice) {
      resetLimitPriceValues();
    }
  }, [formState]);

  const buyPair = formState.buy.length > 0 && formState.buy[0].pair ? formState.buy[0].pair.id : 'Select Buy Pair';
  const buyExchange = formState.buy.length > 0 && formState.buy[0].accounts.length > 0 ?
    formState.buy[0].accounts[0].exchangeName : '';
  const buyPreviewPrice = buyPreviewPriceCache[buildPairKey(buyExchange, buyPair)];
  const sellPair = formState.sell.length > 0 && formState.sell[0].pair ? formState.sell[0].pair.id : 'Select Sell Pair';
  const sellExchange = formState.sell.length > 0 && formState.sell[0].accounts.length > 0 ?
    formState.sell[0].accounts[0].exchangeName : '';
  const sellPreviewPrice = sellPreviewPriceCache[buildPairKey(sellExchange, sellPair)];
  const calculatedSpread = smartRound(Number(sellPreviewPrice) - Number(buyPreviewPrice));
  const calculatedBps = (
    smartRound(
      (Number(sellPreviewPrice) - Number(buyPreviewPrice)) /
      Number(buyPreviewPrice) * 10000
    )
  );

  return (
    <div>
      <Link
        href= "https://tread-labs.gitbook.io/api-docs/multi-spread-orders/dynamic-limit-spread"
        rel= "noopener noreferrer"
        target= "_blank"
      >
        <Box alignItems='center' display='flex' gap={1}
          sx={{
            '&:hover': {
              '.icon-hover': {
                color: 'grey.light',
              }
            }
          }}
        >
          <Typography variant='h5'>
              Dynamic Limit Spread
          </Typography>
          <HelpOutline className='icon-hover'
            sx={{
              color: 'grey.main',
            }}
          />
        </Box>
      </Link>
      <Stack alignItems='center' direction='row' height='200px' justifyContent='center' marginTop='-54px' spacing={5}>
        <Stack alignItems='end' direction='column' justifyContent='center' width='33%'>
          <Typography
            color={isReadyToPickLimitPrice ? 'success.main' : 'grey.main'}
            variant='subtitle1'
          >
            {buyPair}
          </Typography>
          {
            isLoadingBuyPreviewPriceCache ?
              <CircularProgress size='0.85rem' sx={{color: 'info.main'}} /> :
              <Typography
                display={buyPreviewPrice ? 'block' : 'none'}
                variant='body2'
              >
                Price: {smartRound(Number(buyPreviewPrice))}
              </Typography>
          }
        </Stack>
        <Box>
          <Stack alignItems='center' direction='column' justifyContent='center'>
            <ToggleButtonGroup
              exclusive
              aria-label="Platform"
              color="primary"
              disabled={!isReadyToPickLimitPrice}
              size='small'
              sx={{paddingBottom: '5px'}}
              value={dynamicLimitBPSFormat}
              onChange={(e, newValue) => setDynamicLimitBPSFormat(newValue)}
            >
              <ToggleButton sx={{width: '125px', height: '25px'}}
                value={false}>
                <Typography variant='body2'>Price</Typography></ToggleButton>
              <ToggleButton value
                sx={{width: '125px', height: '25px'}} ><Typography variant='body2'>BPS</Typography></ToggleButton>
            </ToggleButtonGroup>
            <FormControl sx={{ m: 0 }}>
              <TextField
                autoComplete='off'
                disabled={!isReadyToPickLimitPrice}

                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{display: !dynamicLimitBPSFormat ? 'flex': 'none'}}
                    >
                  $</InputAdornment>),
                  endAdornment:(
                    <Typography
                      sx={{display: dynamicLimitBPSFormat ? 'inline': 'none', paddingRight: '5px'}}
                      variant="body2"
                    >
                    bps
                    </Typography>
                  ),
                  step: 'any',
                  inputComponent: NumericFormatCustom,
                }}
                sx={{...noArrowStyle, width: '250px'}}
                value={limitPriceSpread}
                onChange={(e) => setLimitPriceSpread(e.target.value)}
                onWheel={ignoreScrollEvent}
              />
            </FormControl>
            <Typography
              color='grey.main'
              hidden={!isReadyToPickLimitPrice && ( !isLoadingBuyPreviewPriceCache && !isLoadingSellPreviewPriceCache )}
              sx={{
                paddingTop: '5px',
              }}
              variant='body2'
            >
              Spread: ${calculatedSpread} | {calculatedBps} bps
            </Typography>
          </Stack>
        </Box>
        <Stack alignItems='start' direction='column' justifyContent='center' width='33%'>
          <Typography
            color={isReadyToPickLimitPrice ? 'error.main' : 'grey.main'}
            variant='subtitle1'
          >
            {sellPair}
          </Typography>
          {
            isLoadingSellPreviewPriceCache ?
              <CircularProgress size='0.85rem' sx={{color: 'info.main'}} /> :
              <Typography
                display={sellPreviewPrice ? 'block' : 'none'}
                variant='body2'
              >
                Price: {smartRound(Number(sellPreviewPrice))}
              </Typography>
          }
        </Stack>
      </Stack>
    </div>
  )
}
