import { useTheme } from '@emotion/react';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import QueryStatsIcon from '@mui/icons-material/QueryStatsOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { Button, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useAtom } from 'jotai';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ApiError, openInNewTab, pauseOrder, resubmitOrder, resubmitRemainingOrder,
  resumeOrder, submitCancel
} from '../../apiServices';
import { OPEN_NEW_TAB_ON_SUBMIT } from '../../constants';
import { BASEURL, numberWithSpaces, removeFalsyAndEmptyKeys, smartRound, titleCase } from '../../util';
import { BasicModal } from '../Modal';
import { ErrorContext } from '../context/ErrorProvider';
import { useUserMetadata } from '../context/UserMetadataProvider';
import ProgressBar from '../fields/ProgressBar/ProgressBar';
import CollapsedRow from './CollapsedRow';
import TableOrderConfirmationModel from './TableOrderConfirmationModel';
import {
  StyledHeaderTableCellWithLine, StyledPaddingTableCell,
  StyledTableCell
} from './util';

const displayDefaultTableCell = (column, value, style) => {
  return (
    <StyledTableCell align={column.align} key={column.id} style={style}>
      {column.format && typeof value === 'number'
        ? column.format(value)
        : value}
    </StyledTableCell>
  );
}

const getColumns = ( dashboardView ) => {
  return [
    ...(!dashboardView ? [{ id: 'account_names', label: 'Accounts', minWidth: 100,  align: 'left' }] : []),
    { id: 'pair', label: 'Pair', minWidth: 100,  align: 'left' },
    { id: 'side', label: 'Side', minWidth: 30,  align: 'left' },
    { id: 'executed_notional', label: 'Executed Notional', minWidth: 100,  align: 'right' },
    ...(!dashboardView ?
      [{ id: 'executed_price', label: 'Average Executed Price', minWidth: 100,  align: 'right' }] : []),
    ...(!dashboardView ? [{ id: 'target_qty', label: 'Target Quantity', minWidth: 100,  align: 'left' }] : []),
    { id: 'pct_filled', label: 'Filled', minWidth: 30, align: 'center' },
    { id: 'time_start', label: 'Time Start', minWidth: 170, width: dashboardView ? 170 : undefined, align: 'left' },
    { id: 'super_strategy', label: 'Strategy', minWidth: 140, align: 'left' },
    { id: 'status', label: '', minWidth: 30, align: 'left' },
  ]
};

const parseStatus = (row) => {
  switch(row.status) {
  case 'SUBMITTED':
    return <Typography color='primary.main'>
      Submitted
    </Typography>
  case 'CANCELED':
    return <Typography color='error.main'>
      Canceled
    </Typography>
  case 'COMPLETE':
    return <Typography color='success.main'>
      Finished
    </Typography>
  case 'SCHEDULED':
    return <Typography color='secondary.main'>
      Scheduled
    </Typography>
  case 'PAUSED':
    return <Typography color='info.main'>
      Paused
    </Typography>
  default:
    return <Typography color='primary.main'>
      Active
    </Typography>
  }
}

const getOrderPath = (order) => {
  let url = null;
  if (order.parent_order) {
    url = `/multi_order/${order.parent_order}`;
  } else if (order.is_simple) {
    url = `/simple_order/${order.id}`;
  } else {
    url = `/order/${order.id}`;
  }

  return url;
}

function DisplayRow({
  row, columns, setCancelModalOpen, dashboardView,
  setIsResubmit, setRowData, handlePause, handleResume, setOpenModal, theme
}){
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [pauseTooltipOpen, setPauseTooltipOpen] = useState(false);
  const [resumeTooltipOpen, setResumeTooltipOpen] = useState(false);
  const [cancelTooltipOpen, setCancelTooltipOpen] = useState(false);
  const [resubmitTooltipOpen, setResubmitTooltipOpen] = useState(false);
  const [resubmitRemainingTooltipOpen, setResubmitRemainingTooltipOpen] = useState(false);
  const [viewTooltipOpen, setViewTooltipOpen] = useState(false);

  const StyledCell = dashboardView ? StyledPaddingTableCell : StyledTableCell;

  const isTerminalStatus = ["COMPLETE", "CANCELED"].includes(row.status);

  const handleViewOrder = (order, isMiddleClick = false) => {
    const url = getOrderPath(order);

    if (isMiddleClick) {
      window.open(`${BASEURL}${url}`, '_blank');
    } else {
      navigate(url);
    }
  }

  return (
    <>
      <TableRow
        hover
        key={row.id}
        sx={{
          // already a border on collapseable row
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }}
        onClick={() => { setOpen(!open); }}
      >
        {columns.map((column) => {
          let value = row[column.id];
          switch(column.id){
          case 'pair':
            return (
              <StyledCell align={column.align} key={column.id}>
                <b>{column.format && typeof value === 'number'
                  ? column.format(value)
                  : value}
                </b>
              </StyledCell>
            );

          case 'executed_notional':
            value = `$${numberWithSpaces(Number(row[column.id]).toFixed(3))}`;
            return displayDefaultTableCell(column, value, {whiteSpace: 'nowrap'})

          case 'status':
            return (<StyledCell align={column.align} key={column.id}>
              {parseStatus(row)}
            </StyledCell>);

          case 'account_names':
            if(row.account_names === undefined || row.account_names.length === 0) {
              return (<StyledCell align={column.align} key={column.id}>
                <i>[Deleted]</i>
              </StyledCell>);
            }
            return displayDefaultTableCell(column, value)

          case 'pct_filled':
            return (
              <StyledCell align={column.align} key={column.id} >
                <Box alignItems="center"
                  display="flex"
                  height="100%"
                  justifyContent="center">
                  <ProgressBar
                    isPov={row.pov_limit || row.pov_target}
                    orderStatus={row.status}
                    progress={Math.round(Number(value))}
                  />
                </Box>
              </StyledCell>
            );

          case 'super_strategy':
            if( row.parent_order !== undefined && row.parent_order !== null){
              return displayDefaultTableCell(column, 'Multi')
            }
            return displayDefaultTableCell(column, value)

          case 'side':
            return displayDefaultTableCell(
              column,
              titleCase(value),
              {color: value === 'buy' ? theme.palette.success.main : theme.palette.error.main}
            )

          case 'time_start':
            return displayDefaultTableCell(column, value.substring(0, value.length - 4))

          case 'executed_price':
            return displayDefaultTableCell(column, value ? `$${value.toFixed(6)}` : '')

          case 'target_qty':
            return ( row.buy_token_amount ? (
              displayDefaultTableCell(column, `${smartRound(Number(row.buy_token_amount))} ${
                row.market_type !== 'option' ? row.buy_token : 'Contracts'}` )
            ) : (
              displayDefaultTableCell(column, `${smartRound(Number(row.sell_token_amount))} ${
                row.market_type !== 'option' ? row.sell_token : 'Contracts'}` )
            ))

          default:
            return displayDefaultTableCell(column, value)
          }
        })}
        <StyledCell
          sx={{
            minWidth: 124,
            height: 32,
            justifyContent: 'flex-end', // Aligns the content to the right
            alignItems: 'center', // Aligns the content to the center
            textAlign: 'end'
          }}>
          <Tooltip
            disableHoverListener
            open={resubmitRemainingTooltipOpen}
            placement="top"
            title='Resubmit Remaining Order'>
            <span>
              <IconButton aria-label="resubmit_remaining"
                color='main.info2'
                disabled={!isTerminalStatus}
                size='small'
                sx={{
                  marginRight: '3px',
                  padding: '0',
                  ':hover': {
                    color: theme.palette.primary.main,
                  }
                }}
                variant="contained"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsResubmit(false)
                  setRowData(row)
                  setOpenModal(true)
                }}
                onMouseEnter={() => setResubmitRemainingTooltipOpen(true)}
                onMouseLeave={() => setResubmitRemainingTooltipOpen(false)}
              >
                <CallMissedOutgoingIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            disableHoverListener
            open={resubmitTooltipOpen}
            placement="top"
            title='Resubmit Order'>
            <span>
              <IconButton aria-label="resubmit"
                color='main.info2'
                disabled={!isTerminalStatus}
                size='small'
                sx={{
                  marginRight: '3px',
                  padding: '0',
                  ':hover': {
                    color: theme.palette.primary.main,
                  }

                }}
                variant="contained"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsResubmit(true)
                  setRowData(row)
                  setOpenModal(true)
                }}
                onMouseEnter={() => setResubmitTooltipOpen(true)}
                onMouseLeave={() => setResubmitTooltipOpen(false)}
              >
                <ReplayIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            disableHoverListener
            open={viewTooltipOpen}
            placement="top"
            title="View Order"
          >
            <span>
              <IconButton
                aria-label="view"
                color='main.info2'
                size='small'
                sx={{
                  marginRight: '3px',
                  padding: '0',
                  ':hover': {
                    color: theme.palette.primary.main,
                  }
                }}
                onAuxClick={(event) => {
                  event.stopPropagation();
                  handleViewOrder(row, true);
                }}
                // eslint-disable-next-line react/jsx-sort-props
                onClick={(event) => {
                  event.stopPropagation();
                  handleViewOrder(row);
                }}
                // eslint-disable-next-line react/jsx-sort-props
                variant="outlined"
                onMouseEnter={() => setViewTooltipOpen(true)}
                onMouseLeave={() => setViewTooltipOpen(false)}
              >
                <QueryStatsIcon/>
              </IconButton>
            </span>
          </Tooltip>
          {row.status === 'PAUSED' ?
            <Tooltip disableHoverListener
              open={pauseTooltipOpen}
              placement="top"
              title='Resume Order'>
              <span>
                <IconButton aria-label="resume"
                  color='main.info2'
                  size='small'
                  sx={{
                    marginRight: '3px',
                    padding: '0',
                    ':hover': {
                      color: theme.palette.primary.main,
                    }
                  }}
                  variant="contained"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleResume(row.id);
                  }}
                  onMouseEnter={() => setPauseTooltipOpen(true)}
                  onMouseLeave={() => setPauseTooltipOpen(false)}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
              </span>
            </Tooltip> :
            <Tooltip
              disableHoverListener
              open={resumeTooltipOpen}
              placement="top"
              title='Pause Order'>
              <span>
                <IconButton aria-label="pause"
                  color='main.info2'
                  disabled = { !["ACTIVE"].includes(row.status) }
                  size='small'
                  sx={{
                    marginRight: '3px',
                    padding: '0',
                    ':hover': {
                      color: theme.palette.primary.main,
                    }
                  }}
                  variant="contained"
                  onClick={(event) => {
                    event.stopPropagation();
                    handlePause(row.id);
                  }}
                  onMouseEnter={() => setResumeTooltipOpen(true)}
                  onMouseLeave={() => setResumeTooltipOpen(false)}
                >
                  <PauseCircleOutlineIcon />
                </IconButton>
              </span>
            </Tooltip>
          }
          <Tooltip
            disableHoverListener
            open={cancelTooltipOpen}
            title='Cancel Order'>
            <span>
              <IconButton aria-label="cancel"
                color='error'
                disabled = { ["COMPLETE", "CANCELED"].includes(row.status) }
                placement="top"
                size='small'
                sx={{
                  padding: '0'
                }}
                variant="contained"
                onClick={(event) => {
                  event.stopPropagation();
                  setCancelModalOpen(row.id);
                }}
                onMouseEnter={() => setCancelTooltipOpen(true)}
                onMouseLeave={() => setCancelTooltipOpen(false)}
              >
                <HighlightOffIcon />
              </IconButton>
            </span>
          </Tooltip>
        </StyledCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} style={{ padding: 0 }}>
          <CollapsedRow open={open} row={row} style={{ padding: 0 }}/>
        </TableCell>
      </TableRow>
    </>
  )
}

function SharedOrderTable({
  orderData = [],
  orderRefresh,
  dashboardView = false,
  FormAtoms,
}) {
  const { user, isRetail } = useUserMetadata();

  const [initialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const {setHasError, setErrorContent} = useContext(ErrorContext);

  const [openModal, setOpenModal] = useState(false);
  const [submitModalMessage, setSubmitModalMessage] = useState('');

  const [rowData, setRowData] =  useState({ side: 'buy'})

  // refactor this to reducers
  const [isResubmit, setIsResubmit] = useState(true)

  const theme = useTheme()

  const openNewTabOnSubmit = user.preferences ? user.preferences[OPEN_NEW_TAB_ON_SUBMIT] : false

  const handleCancel = async (rowId) => {
    setCancelModalOpen(false)
    try{
      await submitCancel(rowId);
      setErrorContent({severity: 'success', message: 'Successfully canceled the specified order.'})
      orderRefresh()
    } catch(e) {
      if (e instanceof ApiError) {
        setErrorContent({severity: 'error', message: e.message})
      } else {
        throw e;
      }
    }
    setHasError(true);
  }

  const reSubmit = async (row) => {
    try {
      const orderResubmitData = {
        ...removeFalsyAndEmptyKeys(row),
        alpha_tilt: row.alpha_tilt,
        engine_passiveness: row.engine_passiveness,
        schedule_discretion: row.schedule_discretion,
      }
      const result = await resubmitOrder({...orderResubmitData});

      if (openNewTabOnSubmit) {
        openInNewTab(getOrderPath(result.order))
      }

      setErrorContent({severity: 'success', message: 'Successfully resubmitted the specified order.'})
      orderRefresh()
    } catch(e) {
      if (e instanceof ApiError) {
        setErrorContent({severity: 'error', message: e.message})
      } else {
        throw e;
      }
    }
    setOpenModal(false)
    setHasError(true);
  };

  const reSubmitRemaining = async (row) => {
    try {
      const orderResubmitData = {
        ...removeFalsyAndEmptyKeys(row),
        alpha_tilt: row.alpha_tilt,
        engine_passiveness: row.engine_passiveness,
        schedule_discretion: row.schedule_discretion,
      }
      const result = await resubmitRemainingOrder({...orderResubmitData});

      if (openNewTabOnSubmit) {
        openInNewTab(getOrderPath(result.order))
      }

      setErrorContent({severity: 'success', message: 'Successfully resubmitted the specified order.'})
      orderRefresh()
    } catch(e) {
      if (e instanceof ApiError) {
        setErrorContent({severity: 'error', message: e.message})
      } else {
        throw e;
      }
    }
    setOpenModal(false)
    setHasError(true)
  }

  const handlePause = async (id) => {
    try {
      await pauseOrder(id);
      setErrorContent({ severity: 'success', message: 'Successfully paused the specified order.' });
      orderRefresh()
    } catch (e) {
      if (e instanceof ApiError) {
        setErrorContent({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }
    setHasError(true);

  };

  const handleResume = async (id) => {
    try {
      await resumeOrder(id);
      setErrorContent({ severity: 'success', message: 'Successfully resumed the specified order.' });
      orderRefresh()
    } catch (e) {
      if (e instanceof ApiError) {
        setErrorContent({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }

    setHasError(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const columns = getColumns(dashboardView)
  const tableContainerHeight = dashboardView ? '100%' : 'calc(100% - 60px)';

  return (
    <Box sx={{ height: '100%' }}>
      <TableContainer style={{height: tableContainerHeight}}>
        <Table stickyHeader aria-label="sticky table" size='small'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledHeaderTableCellWithLine
                  align={column.align}
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    width : column.width || undefined,
                  }}
                >
                  {column.label}
                </StyledHeaderTableCellWithLine>
              ))}
              <StyledHeaderTableCellWithLine
                align='left'
                key="actions"
                style={{ width: 190 }}
              >
                {}
              </StyledHeaderTableCellWithLine>
            </TableRow>
          </TableHead>
          <TableBody sx={{overflow: 'auto'}}>
            {orderData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <DisplayRow
                  columns={columns}
                  dashboardView={dashboardView} handlePause={handlePause}
                  handleResume={handleResume}
                  key={row.id}
                  row={row}
                  setCancelModalOpen={setCancelModalOpen}
                  setIsResubmit={setIsResubmit}
                  setOpenModal={setOpenModal}
                  setRowData={setRowData}
                  theme={theme}
                />
              ))}
          </TableBody>
        </Table>
        {orderData.length === 0 && (
          <Box alignItems="center" display="flex" height='calc(100% - 60px)' justifyContent="center">
            { user && user.is_authenticated ?
              <Typography variant='h6'>No orders found</Typography> :
              <Stack direction='row' gap={1}>
                <Button href='account/login/'
                  size='small' sx={{ backgroundColor: theme.palette.primary.dark2 }} variant='contained'>
                  <Typography variant='h6'>Log in</Typography></Button>
                { isRetail && <><Typography sx={{paddingTop: '4px'}} variant='h6' > or </Typography>
                  <Button color='primary' href='account/signup/' size='small' variant='contained'>
                    <Typography color={theme.palette.text.offBlack} variant="h6">
                Sign up
                    </Typography>
                  </Button></>}
                <Typography sx={{paddingTop: '4px'}} variant='h6' > to see orders</Typography>
              </Stack>
            }
          </Box>
        )}
      </TableContainer>

      { !dashboardView ? <TablePagination
        component="div"
        count={orderData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[20]}
        sx={{height: '60px'}}
        onPageChange={handleChangePage}
      /> : null }
      <BasicModal
        confirmButtonText='Yes'
        handleConfirm={() => handleCancel(cancelModalOpen)}
        message='Are you sure you want to cancel this order?'
        open={cancelModalOpen !== false}
        setOpen={setCancelModalOpen} />
      <TableOrderConfirmationModel
        dashboardView={dashboardView}
        data={rowData}
        FormAtoms={FormAtoms}
        handleResubmit={reSubmit}
        handleResubmitRemaining={reSubmitRemaining}
        initialLoadValue={initialLoadValue}
        isBuy={rowData.side === 'buy'}
        isResubmit={isResubmit}
        modalText={submitModalMessage}
        open={openModal}
        setOpen={setOpenModal}
      />
    </Box>
  )
}

export { SharedOrderTable };
