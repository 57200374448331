const joinUrl = (base, path) => new URL(path, base).href;

export class ApiError extends Error {}

export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const getCsrfTokenFromCookies = () => {
  const cookies = document.cookie.split(';');
  const csrfTokenCookie = cookies.find(cookie => cookie.trim().startsWith('csrftoken='));
  if (!csrfTokenCookie) return null;
  return csrfTokenCookie.split('=')[1];
};

const commonHeaders = ({csrfToken}) => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept-Encoding': 'gzip',
  }

  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken
  }
  return headers;
};

const handleResponse = async (response) => {
  if (response.ok) {
    return response.json();
  }
  let errorBody = '';
  try {
    errorBody = await response.json();
  } catch (e) {
    throw new ApiError("Server Error. Please contact your administrator.");
  }

  let errorMsg = errorBody;
  if (errorBody.errors) {
    errorMsg = errorBody.errors;
  } else if (errorBody.error) {
    errorMsg = errorBody.error;
  }

  throw new ApiError(errorMsg);
};

const makeApiCall = async (path, options = {}) => {
  const url = joinUrl(window.location.origin, path);
  const fetchOptions = {...options};
  fetchOptions.headers = {...options.headers, ...commonHeaders({csrfToken: getCsrfTokenFromCookies()})};

  try {
    const response = await fetch(url, fetchOptions);
    return await handleResponse(response);
  } catch (error) {
    throw new ApiError(error);
  }
};

export const fetchAccountData = () => {
  return makeApiCall('account/balances_page_data/', {
    method: 'GET',
  });
};

export const fetchAccountBalances = () => {
  return makeApiCall('oms/account_balances', {
    method: 'GET',
  });
}

export const fetchOrderEntryFormData = () => {
  return makeApiCall('oms/order_form_data', {
    method: 'GET',
  });
};

export const fetchNoUserOrderEntryFormData = () => {
  return makeApiCall('oms/no_user_order_form_data', {
    method: 'GET',
  });
};


export const fetchOptionOrderEntryFormData = () => {
  return makeApiCall('oms/option_order_form_data', {
    method: 'GET',
  });
};

export const fetchOrderDetailData = (order_id) => {
  return makeApiCall(`analytics/order/${order_id}`, {
    method: 'GET',
  });
}

export const fetchMultiOrderSummaryData = (order_id) => {
  return makeApiCall(`analytics/multi_order_summary/${order_id}`, {
    method: 'GET',
  });
}

export const fetchMultiOrderBenchmarkData = (order_id) => {
  return makeApiCall(`analytics/multi_order_benchmark/${order_id}`, {
    method: 'GET',
  });
}

export const fetchMultiOrderDetailData = (order_id) => {
  return makeApiCall(`analytics/multi_order/${order_id}`, {
    method: 'GET',
  });
}

export const cancelMultiOrder = (multi_order_id) => {
  return makeApiCall(`api/multi_order/${multi_order_id}`, {
    method: 'DELETE',
  });
}

export const pauseMultiOrder = (multi_order_id) => {
  return makeApiCall(`api/pause_multi_order/`, {
    method: 'POST',
    body: JSON.stringify({multi_order_id}),
  });
}

export const resumeMultiOrder = (multi_order_id) => {
  return makeApiCall(`api/resume_multi_order/`, {
    method: 'POST',
    body: JSON.stringify({multi_order_id}),
  });
}

export const submitChainedOrder = async (fields = {}) => {
  return makeApiCall('api/chained_orders/', {
    method: 'POST',
    body: JSON.stringify({ ...fields }),
  });
}

export const cancelChainedOrder = (chained_order_id) => {
  return makeApiCall(`api/chained_orders/${chained_order_id}`, {
    method: 'DELETE',
  });
}

export const pauseChainedOrder = (chained_order_id) => {
  return makeApiCall('api/pause_chained_order/', {
    method: 'POST',
    body: JSON.stringify({ chained_order_id }),
  });
}

export const resumeChainedOrder = (chained_order_id) => {
  return makeApiCall('api/resume_chained_order/', {
    method: 'POST',
    body: JSON.stringify({ chained_order_id }),
  });
}

export const fetchChainedOrderDetailData = (order_id) => {
  return makeApiCall(`analytics/chained_order/${order_id}`, {
    method: 'GET',
  });
}

export const fetchChainedOrderSummaryData = (order_id) => {
  return makeApiCall(`analytics/chained_order_summary/${order_id}`, {
    method: 'GET',
  });
}

export const fetchChainedOrderBenchmarkData = (order_id) => {
  return makeApiCall(`analytics/chained_order_benchmark/${order_id}`, {
    method: 'GET',
  });
}

export const fetchPovOrderChartData = (order_id) => {
  return makeApiCall(`analytics/pov_order_chart_data/${order_id}`, {
    method: 'GET',
  });
}

export const fetchExchangePairs = (exchange_names, pair) => {
  const params = new URLSearchParams({exchange_names: exchange_names.join(','), pair});

  return makeApiCall(`sor/exchange_pairs?${params}`, {
    method: 'GET',
  });
}

export const convertQty = (
  accounts, pair, qty, is_base_asset,
  pre_calculated_price=null, convert_to_num_contracts=false
) => {
  const paramsObj = { accounts, pair };

  if (pre_calculated_price) {
    paramsObj.pre_calculated_price = pre_calculated_price;
  }

  if (convert_to_num_contracts) {
    paramsObj.convert_to_num_contracts = convert_to_num_contracts;
  }

  if (is_base_asset) {
    paramsObj.base_asset_qty = qty;
  } else {
    paramsObj.quote_asset_qty = qty;
  }

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`account/convert_qty?${params}`, {
    method: 'GET',
  });
};

export const getPairPrice = (pair, exchange_name) => {
  const paramsObj = { pair, exchange_name };

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`sor/get_pair_price?${params}`, {
    method: 'GET',
  });
};

export const submitOrder = (fields = {}) => {
  return makeApiCall('api/orders/', {
    method: 'POST',
    body: JSON.stringify({...fields}),
  });
}

export const getBulkOrder = (ids) => {
  const paramsObj = { ids };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`oms/bulk_orders?${params}`, {
    method: 'GET',
  });
}

export const getBulkChainedOrders = (ordersInChainIds) => {
  const paramsObj = { ids: ordersInChainIds.map(order => order.id).join(',') };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`oms/bulk_chained_orders?${params}`, {
    method: 'GET',
  });
}

export const resubmitOrder = (fields = {}) => {
  return makeApiCall('oms/resubmit_order', {
    method: 'POST',
    body: JSON.stringify({...fields}),
  });
}

export const resubmitRemainingOrder = (fields = {}) => {
  return makeApiCall('oms/resubmit_remaining_order', {
    method: 'POST',
    body: JSON.stringify({...fields}),
  });
}

export const submitMultiOrder = (fields = {}) => {
  return makeApiCall('api/multi_orders/', {
    method: 'POST',
    body: JSON.stringify({...fields}),
  });
}

export const submitCancel = (pk, fields = {}) => {
  return makeApiCall(`oms/cancel_order/${pk}`, {
    method: 'POST',
    body: JSON.stringify({...fields}),
  });
}

export const validateOrderCondition = (order_condition) => {
  const paramsObj = { order_condition };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`account/validate_order_condition?${params}`, {
    method: 'GET',
  });
}

export const cancelAllOrders = () => {
  return makeApiCall('api/cancel_all_orders/', {
    method: 'POST',
  })
}

export const getPredictionChartData = ({exchangeName, pair, startTime, endTime, currentTime}) => {
  const paramsObj = {
    "exchange_name": exchangeName,
    "pair": pair,
    "start_time": startTime,
    "end_time": endTime,
    "current_time": currentTime,
  };

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`marketdata/get_prediction_chart_data?${params}`, {
    method: 'GET',
  });
}

export const getMarketExchangeAndPairs = () => {
  return makeApiCall('marketdata/get_exchanges_and_pairs', {
    method: 'GET',
  });
}

export const getPreTradePrediction = async (exchange_names, pair, base_asset_qty, duration, limit_price, side) => {
  const paramsObj = {exchange_names, pair, base_asset_qty, duration, limit_price, side};
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`marketdata/pretrade_predictions?${params}`, {
    method: 'GET',
  });
};

export const addExchangeAccount = async (name, exchange, api_key, api_secret, password) => {
  const data = {name, exchange, api_key, api_secret};

  if (password) {
    data.password = password;
  }

  return makeApiCall('api/accounts/', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const deleteAccount = async (name) => {
  const data = {name};
  return makeApiCall('api/accounts/', {
    method: 'DELETE',
    body: JSON.stringify(data),
  });
}

export const getAccounts = async () => {
  return makeApiCall('api/accounts/', {
    method: 'GET',
  });
}

export const updateAccount = async ({id, margin_mode}) => {
  const data = {id, margin_mode};
  return makeApiCall('api/accounts/', {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export const getActiveExchanges = async () => {
  return makeApiCall('account/active_exchanges', {
    method: 'GET',
  });
}

export const refreshAccountBalanceCache = async (account_id) => {
  const data = {account_id};

  return makeApiCall('account/refresh_account_balance_cache/', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const calculateDurationForPov = async (exchange_names, pair, base_asset_qty, pov_target) => {
  const paramsObj = {
    exchange_names: exchange_names.join(','),
    pair,
    base_asset_qty,
    pov_target,
  };

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`marketdata/calculate_duration_for_pov?${params}`, {
    method: 'GET',
  });
}

export const getOrderBook = async(exchange_name, pair) => {
  const paramsObj = {
    exchange_name,
    pair,
  }

  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`sor/get_order_book?${params}`, {
    method: 'GET',
  });
}

export const getOrderTemplates = async () => {
  return makeApiCall(`oms/order_templates`, {
    method: 'GET',
  });
}

export const createOrderTemplate = async (data) => {
  return makeApiCall('oms/order_templates', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const deleteOrderTemplates = async (template_ids) => {
  const data = {template_ids};
  return makeApiCall('oms/order_templates', {
    method: 'DELETE',
    body: JSON.stringify(data),
  });
}

export const amendOrder = async (order_id, changes) => {
  const data = {order_id, changes};
  return makeApiCall(`api/amend_order/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const pauseOrder = async (order_id) => {
  const data = {order_id};
  return makeApiCall(`api/pause_order/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const resumeOrder = async (order_id) => {
  const data = {order_id};
  return makeApiCall(`api/resume_order/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const emailHelp = async (order_id) => {
  return makeApiCall(`ems/get_email_help?order_id=${order_id}`, {
    method: 'GET',
  });
}

export const getUserFavouritePairs = async () => {
  return makeApiCall(`account/user_favourite_pairs/`, {
    method: 'GET',
  });
}

export const addUserFavouritePairs = async (pairs) => {
  return makeApiCall(`account/user_favourite_pairs/`, {
    method: 'POST',
    body: JSON.stringify({pairs}),
  });
}

export const deleteUserFavouritePairs = async (pairs) => {
  return makeApiCall(`account/user_favourite_pairs/`, {
    method: 'DELETE',
    body: JSON.stringify({pairs}),
  });
}

export const getOptionData = async (exchange, underlying, date) => {
  const data = {
    exchange,
    underlying,
    date,
  }
  const params = new URLSearchParams(data);

  return makeApiCall(`sor/get_option_data?${params}`, {
    method: 'GET',
  });
}

export const connectNettingServer = async (server) => {
  const data = {server};
  return makeApiCall('dicy/connect/', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const resetPassword = async (user_id, old_password, new_password, confirm_password) => {
  const data = {user_id, old_password, new_password, confirm_password};
  return makeApiCall('account/reset_password/', {
    method: 'POST',
    body: JSON.stringify(data),
  })
}

export const logout = async () => {
  return makeApiCall('account/logout/', {
    method: 'POST',
  });
}

export const getUserMetadata = async () => {
  return makeApiCall('account/user_metadata/', {
    method: 'GET',
  });
}

export const getPlantStatus = async () => {
  return makeApiCall('/account/plant_status/', {
    method: 'GET',
  });
}

export const getAdminPanelData = async () => {
  return makeApiCall('account/admin_panel_data/', {
    method: 'GET',
  });
}

export const assignAccountPermission = async (account_id, group_id) => {
  const data = {account_id, group_id};

  return makeApiCall('account/assign_account_permission/', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const unassignAccountPermission = async (account_id, group_id) => {
  const data = {account_id, group_id};
  return makeApiCall('account/unassign_account_permission/', {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export const getTcaData = async () => {
  return makeApiCall('analytics/tca_stats/', {
    method: 'GET',
  });
}

export const getTokenPairLookup = async () => {
  return makeApiCall('api/get_token_pairs/', {
    method: 'GET',
  });
}

export const closeBalances = async (max_notional, account_names) => {
  const data = {max_notional, account_names}
  return makeApiCall(`api/close_balances/`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}


export const updateUserPreferences = async (preferences) => {
  return makeApiCall('account/user_preferences/', {
    method: 'POST',
    body: JSON.stringify(preferences),
  });
}

export const setBetaAgreedAt = async () => {
  return makeApiCall('account/set_beta_agreed_at/', {
    method: 'POST',
  });
}

export const getServerIp = async () => {
  return makeApiCall('account/get_server_ip', {
    method: 'GET',
  });
}

export const getTableOrders = async () => {
  return makeApiCall('ems/get_order_table_rows', {
    method: 'GET',
  });
}

export const getMarkoutData = async (orderId) => {
  return makeApiCall(`analytics/get_markout_data?order_id=${orderId}&cut_by=exchange,role`, {
    method: 'GET',
  })
};

export const getPointsData = async (orderId) => {
  return makeApiCall(`analytics/get_points_data`,{
    method: 'GET',
  })
};

export const getAccountExchangeSettings = async (accountIds) => {
  const paramsObj = { account_ids: accountIds.join(',') };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`account/get_account_exchange_settings?${params}`, {
    method: 'GET',
  });
}

export const getContractInfo = async (pair, accountIds) => {
  const paramsObj = {pair, account_ids: accountIds.join(',') };
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`sor/get_contract_info?${params}`, {
    method: 'GET',
  });
}

export const getVersionData = async () => {
  return makeApiCall('account/get_VersionData/', {
    method: 'GET',
  });
}

export const getCallDynamic = async (url, paramsObj) => {
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`${url}?${params}`, {
    method: 'GET',
  });
}

export const getDicyCreds = async () => {
  return makeApiCall('account/dicy_credentials/', {
    method: 'GET',
  });
}

export const getOrderSearchData = async () => {
  return makeApiCall('ems/get_filter_order', {
    method: 'GET',
  });
}

export const getOrderTableRows = async (paramsObj) => {
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`ems/get_order_table_rows?${params}`, {
    method: 'GET',
  });
}

export const getOptionOrders = async (paramsObj) => {
  const params = new URLSearchParams(paramsObj);
  return makeApiCall(`ems/get_option_orders?${params}`, {
    method: 'GET',
  });
}

export const getArweaveData = async (limit, cursor) => {
  const data = {limit}
  if(cursor){
    data.cursor = cursor
  }
  return makeApiCall(`analytics/get_arweave_data`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}
