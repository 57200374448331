import { now } from 'moment';

export const BASEURL = window.location.origin

export const isEmpty = (obj) => {
  return (
    (Array.isArray(obj) && obj.length === 0) || // Check for empty array
    (obj.constructor === Object && Object.keys(obj).length === 0) // Check for empty object
  );
}

export function accurateToFixed(num, fixed) {
  // eslint-disable-next-line no-useless-escape
  const re = new RegExp(`^-?\\d+(?:\.\\d{0,${ fixed || -1 }})?`);
  return num.toString().match(re)[0];
}


export const removeFalsyAndEmptyKeys = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] && !isEmpty(obj[key])) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export const replaceFalsyAndEmptyKeys = (obj, replacer) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (!obj[key] || isEmpty(obj[key])) {
      acc[key] = replacer;
    }
    return acc;
  }, {});
}

export const filterOutFalseyValues = (arr) => arr.filter(Boolean);

export function numberWithSpaces(x) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function numberWithCommas(x) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function truncate(x) {
  const parts = x.toString().split(".");
  return Number(parts[0]);
}

export function msAndKs(x, fixed = 2) {
  if ( x >= 1000000000000000){
    return `${(x / 1000000000000000).toFixed(fixed)}Q`;
  }
  if ( x >= 1000000000000){
    return `${(x / 1000000000000).toFixed(fixed)}T`;
  }
  if ( x >= 1000000000){
    return `${(x / 1000000000).toFixed(fixed)}B`;
  }
  if (x >= 1000000) {
    return `${(x / 1000000).toFixed(fixed)}M`;
  }
  if (x >= 1000) {
    return `${(x / 1000).toFixed(fixed)}K`;
  }
  return x.toFixed(fixed);
}

export function titleCase(w) {
  if (!w) {
    return ""
  }
  return w.toLowerCase().replace('_', ' ').replace(/\b\w/g, s => s.toUpperCase());
}

export function smartRound(num) {
  // If the number is larger than a small threshold, round to 4 decimal places
  if (Math.abs(num) > 0.0001) {
    return parseFloat(num.toFixed(4));
  }

  // If the number is very small, round to 4 significant digits
  return parseFloat(num.toPrecision(4));
}

export function formatPrice(value) {
  // force show one decimal place for integers
  if (Number.isInteger(value)) {
    return value.toFixed(1);
  }

  let significantDigits;

  const absValue = Math.abs(value);
  if (absValue <= 9999) {
    significantDigits = 5;
  } else {
    significantDigits = 6 + Math.floor(Math.log10(absValue) - 4);
  }

  return value.toPrecision(significantDigits);;
}

export const noArrowStyle = {
  '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  }
};

export function validatePair(pair) {
  if (pair.includes('FUTURE')) {
    const futurePairRegex = /[a-zA-Z0-9]+:FUTURE_(\d{4})\.(\d{2})\.(\d{2})-[a-zA-Z0-9]+/;

    return !!pair.match(futurePairRegex);
  }
  return true;
}

export const handleDateChange = (value, dateSetter) => {
  dateSetter(value)
}

const pauseWindowToPlotBand = (pauseWindow, theme) => {
  return {
    color: theme.palette.grey.transparent,
    from: pauseWindow[0],
    to: pauseWindow[1],
    label: {
      style: {
        color: theme.palette.grey.transparent,
      }
    }
  }
}

export const buildPausePlotBands = (pauseWindows, isPaused, orderPausedAt, theme) => {
  const plotBands = pauseWindows.map((w) => pauseWindowToPlotBand(w, theme));

  if (isPaused) {
    plotBands.push(pauseWindowToPlotBand([new Date(orderPausedAt).getTime(), now()], theme));
  }

  return plotBands;
}

export const filterPausedData = (seriesData, xAxisPlotBands) => {
  return seriesData.map(point => {

    if (xAxisPlotBands.some(band => point.x >= band.from && point.x <= band.to)) {
      return { x: point.x, y: null };
    }

    return point;
  });
}

export const createEmptyPoints = (pausePlotBands) => {
  const dummyPoints = [];
  pausePlotBands.forEach(band => {
    dummyPoints.push([band.from, null]);
    dummyPoints.push([band.to, null]);
  });

  return dummyPoints;
}

export const insertPauseBandEmptyPoints = (data, pausePlotBands) => {
  const emptyPoints = createEmptyPoints(pausePlotBands);
  return data.concat(emptyPoints).sort((a, b) => a[0] - b[0]);
}

// Hack to prevent scroll from changing the input field value
export const ignoreScrollEvent = (event) => {
  event.target.blur();       // Remove focus from the input field
  event.stopPropagation();   // Stop the event from propagating
  setTimeout(() => event.target.focus(), 0); // Re-focus the input field
};
