import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Container, InputAdornment, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import BorderedStack from "../../../pages/dashboard/orderEntry/AlgoOrderFieldsComponents/BorderedStack";
import DurationField from "../../../pages/dashboard/orderEntry/AlgoOrderFieldsComponents/DurationField";
import { PovTargetField } from '../ParticipationRate';
import { AlphaTiltSlider, DiscretionSlider, PassivenessSlider } from "../Sliders";
import AlgoNumberField from "../../../pages/dashboard/orderEntry/AlgoOrderFieldsComponents/AlgoNumberField";
import { handleDateChange, noArrowStyle } from "../../../util";

export function TargetTimeView({
  selectedDuration,
  setSelectedDuration,
  targetTime,
  setTargetTime,
  isCalculatingDuration,
}) {
  return (
    <Grid container spacing={2}>
      <Grid xs={7}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Container disableGutters>
            <DateTimeField
              ampm={false}
              format="MM/DD/YYYY HH:mm"
              label="Target Time (UTC)"
              name='targetTimeField'
              slotProps={{
                field: { clearable: true, onClear: (e) => setTargetTime(undefined)},
              }}
              sx={{ ...noArrowStyle, width: '100%' }}
              value={targetTime}
              views={['year','month','day','hours', 'minutes']}
              onChange={(value) => handleDateChange(value, setTargetTime)}
            />
          </Container>
        </LocalizationProvider>
      </Grid>
      <Grid xs={5}>
        <AlgoNumberField
          InputProps={{
            endAdornment: (
              isCalculatingDuration &&
              <InputAdornment position='end'>
                <CircularProgress size={20} sx={{color: 'info.main'}} />
              </InputAdornment>
            )
          }}
          label='Interval (minutes)'
          size='medium'
          value={selectedDuration !== null ? (Math.max(selectedDuration / 60, 1)/2) : ''}
          onChange={(e) => {
            const {value} = e.target;
            setSelectedDuration(value === '' ? null : Number(value) * 60 * 2);
          }}
        />
      </Grid>
    </Grid>
  );
}
