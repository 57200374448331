import { useTheme } from '@emotion/react';
import { Stack, Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { StyledTableCell } from '../../../shared/orderTable/util';
import { smartRound } from '../../../util';

function PlacementsTable({ placements }) {
  const theme = useTheme();

  const headerStyles = {
    backgroundColor: theme.palette.background.paper,
    padding: '10px',
    border: `1px solid ${theme.palette.divider}`,
  };

  const cellStyles = {
    padding: '10px',
    border: `1px solid ${theme.palette.divider}`,
  };

  return (
    <Stack direction='column' spacing={2} sx={{margin: '16px'}}>
      <Typography color='text.offWhite' variant='h4'>
        Placements
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell style={headerStyles}>Time</StyledTableCell>
            <StyledTableCell style={headerStyles}>Exchange</StyledTableCell>
            <StyledTableCell style={headerStyles}>External ID</StyledTableCell>
            <StyledTableCell style={headerStyles}>Quantity</StyledTableCell>
            <StyledTableCell style={headerStyles}>Price</StyledTableCell>
            <StyledTableCell style={headerStyles}>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {placements ? placements.map(p => (
            <TableRow key={p.id}>
              <StyledTableCell style={cellStyles}>
                {new Date((p.created_at)).toLocaleString('en-US', { timeZone: 'America/New_York' })}
              </StyledTableCell>
              <StyledTableCell style={cellStyles}>{p.exchange}</StyledTableCell>
              <StyledTableCell style={cellStyles}>{p.external_id}</StyledTableCell>
              <StyledTableCell style={cellStyles}>{smartRound(Number(p.qty))}</StyledTableCell>
              <StyledTableCell style={cellStyles}>{smartRound(Number(p.price))}</StyledTableCell>
              <StyledTableCell style={cellStyles}>{p.status}</StyledTableCell>
            </TableRow>
          )) : (
            <TableRow>
              <td colSpan="6" style={cellStyles}>Loading...</td>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Stack>
  );
}

export default PlacementsTable;
