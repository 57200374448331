import { Box, Divider, Stack, Table, TableBody, TableRow, Typography } from '@mui/material';
import React from 'react';
import { Loader } from '../../shared/Loader';
import { StyledBenchmarkTableCell } from '../../shared/orderTable/util';

function TransactionBenchmark({ benchmarkData, loading }) {
  const {
    arrival_cost,
    vwap_cost,
    departure_cost,
    fee_cost,
  } = benchmarkData;

  if (loading) {
    return <Loader />
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Stack spacing={2}>
        <Typography variant='h6'>
          Benchmark
        </Typography>
        <Divider />
        <Table>
          <TableBody>
            <TableRow>
              <StyledBenchmarkTableCell>Arrival</StyledBenchmarkTableCell>
              <StyledBenchmarkTableCell sx={{ color: arrival_cost > 0 ? 'error.main' : 'success.main' }}>
                {arrival_cost.toFixed(4)} bps
              </StyledBenchmarkTableCell>
            </TableRow>
            <TableRow>
              <StyledBenchmarkTableCell>VWAP</StyledBenchmarkTableCell>
              <StyledBenchmarkTableCell sx={{ color: vwap_cost > 0 ? 'error.main' : 'success.main' }}>
                {vwap_cost.toFixed(4)} bps
              </StyledBenchmarkTableCell>
            </TableRow>
            <TableRow>
              <StyledBenchmarkTableCell>Departure Cost</StyledBenchmarkTableCell>
              <StyledBenchmarkTableCell>
                {departure_cost.toFixed(4)} bps
              </StyledBenchmarkTableCell>
            </TableRow>
            <TableRow>
              <StyledBenchmarkTableCell>Exchange Fee</StyledBenchmarkTableCell>
              <StyledBenchmarkTableCell>
                {fee_cost ? fee_cost.toFixed(4) : 'N/A'} bps
              </StyledBenchmarkTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    </Box>
  );
}

export { TransactionBenchmark };
