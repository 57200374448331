import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { ApiError, addExchangeAccount } from '../../apiServices';

export function AddAccountModal({ open, setOpen, activeExchanges, loadAccounts, showAlert, serverIp }) {
  const [name, setName] = useState('');
  const [selectedExchange, setSelectedExchange] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
  };

  const buttonStyle = {
    width: 70,
    height: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 3,
  }

  const closeButtonStyle = {
    position: 'absolute',
    right: 8,
    top: 8,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await addExchangeAccount(name, selectedExchange, apiKey, apiSecret, password);
      showAlert({
        severity: 'success',
        message: `Successfully linked ${selectedExchange} account ${name}`,
      });
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({
          severity: 'error',
          message: `Could not link account: ${e.message}`,
        });
      } else {
        throw e;
      }
    } finally {
      loadAccounts();
      setOpen(false);
      setIsSubmitting(false);
    }
  }

  return (
    <div>
      <Modal
        closeAfterTransition
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        open={open}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        slots={{ backdrop: Backdrop }}
        onClose={() => setOpen(false)}
      >
        <Fade in={open}>
          <Box display='flex' flexDirection='column' justifyContent='center' sx={modalStyle}>
            <IconButton
              aria-label="close"
              sx={closeButtonStyle}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{marginBottom: 4}} variant="h6">
              Link API Key
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack flexDirection="column" spacing={2}>
                <FormControl>
                  <InputLabel id="exchange-label">
                      Exchange
                  </InputLabel>
                  <Select
                    required
                    label="Exchange"
                    labelId="exchange-label"
                    value={selectedExchange}
                    onChange={(e) => setSelectedExchange(e.target.value)}
                  >
                    {
                      activeExchanges.map((exchange) => (
                        <MenuItem
                          key={exchange}
                          value={exchange}
                        >
                          {exchange}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  placeholder="API Key"
                  onChange={(e) => setApiKey(e.target.value)}
                />
                <TextField
                  fullWidth
                  required
                  autoComplete="off"
                  placeholder="API Secret"
                  onChange={(e) => setApiSecret(e.target.value)}
                />
                <TextField
                  fullWidth
                  autoComplete="off"
                  placeholder="Password (Optional)"
                  required={false}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {serverIp && (
                  <Typography color="textSecondary" sx={{ marginTop: 2 }} variant="caption">
                    Whitelist IP address: {serverIp}
                  </Typography>
                )}
                <Box display='flex' sx={{ marginTop: 1 }}>
                  <Button color="primary" disabled={isSubmitting} sx={buttonStyle} type="submit" variant="contained">
                    {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
                  </Button>
                </Box>
              </Stack>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
