import { useTheme } from '@emotion/react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, { useLayoutEffect, useRef } from 'react';
import useWatermark from '../../../../../shared/hooks/useWatermark';

const calculateTimeInterval = (origTimeEnd, timeStart) => {
  const timeDelta = Date.parse(origTimeEnd) - Date.parse(timeStart)
  const rawInterval = timeDelta / 5
  const roundedInterval = Math.ceil(rawInterval / 60000) * 60000
  return roundedInterval
}

function MarketVolumeChart({volume, origTimeEnd, timeStart}){
  const theme = useTheme();
  const chartComponent = useRef(null);

  useLayoutEffect(() => {
    function updateSize() {
      chartComponent.current.chart.reflow();
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const options = {
    chart: {
      animation: false,
      backgroundColor: 'transparent',
      zooming: {
        mouseWheel: false
      },
      zoomType: null,
      marginRight: 80,
      spacingBottom: -5,
      marginLeft: 80
    },
    series: [{
      type: 'column',
      name: 'Market Volume',
      data: volume,
      color: theme.palette.charts.blue,
      tooltip: {
        valueDecimals: 2,
      },
    }
    ,{
      name: "Maker Fills",
      data: [],
      color: theme.palette.charts.green
    },
    {
      name: "Taker Fills",
      data: [],
      color: theme.palette.charts.red
    },
    {
      type: 'spline',
      name: "Realized Participation Rate",
      data: [[1711389780000, null]],
      color: theme.palette.text.offWhite
    },{
      type: 'line',
      name: "Target",
      data: [[1711389780000, null]],
      color: theme.palette.charts.gray,
      dashStyle: 'Dash'
    }],
    yAxis: {
      title: {
        text: "Market Volume",
        style: {
          color: theme.palette.text.offWhite
        },
      },
      opposite: false,
      gridLineColor: theme.palette.charts.gridLines,
      labels: {
        style: {
          color: theme.palette.text.offWhite
        },
      },
    },
    xAxis: {
      startOnTick: false,
      dateTimeLabelFormats: {
        minute: '%H:%M'
      },
      softMax: Date.parse(origTimeEnd),
      min: Date.parse(timeStart),
      endOnTick: false,
      tickInterval: calculateTimeInterval(origTimeEnd, timeStart), // 4 tick intervals
      type: 'datetime',
      labels: {
        style: {
          color: theme.palette.text.offWhite
        },
      },
    },
    plotOptions:{
      column: {
        pointStart: Date.parse(timeStart),
        pointPadding: 0, // Minimizes the space between points within the same category
        groupPadding: 0.1, // Adjust this to set the space between categories
        borderWidth: 0,
        borderRadius: 0 // Ensures the tops of the columns are flat
      }, },
    legend: {
      enabled: true,
      itemStyle: {
        color: theme.palette.text.offWhite,
      }
    },
    tooltip: {
      outside: true,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    }
  };

  const watermarkedOptions = useWatermark({options, position: 'bottom-right'});

  return (
    <HighchartsReact
      constructorType='stockChart'
      containerProps={{ style: { height: "100%" } }}
      highcharts={Highcharts}
      options={watermarkedOptions}
      ref={chartComponent} />
  );
}

export { MarketVolumeChart };

