import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { FormControl, Stack, TableContainer, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useAtom } from 'jotai';

import {
  ApiError,
  createOrderTemplate,
  deleteOrderTemplates,
  openInNewTab,
  submitOrder,
} from '../../../../apiServices';
import { StyledTableCell } from '../../../../shared/orderTable/util';
import { removeFalsyAndEmptyKeys, titleCase } from '../../../../util';
import { useUserMetadata } from '../../../../shared/context/UserMetadataProvider';
import { OPEN_NEW_TAB_ON_SUBMIT } from '../../../../constants';

dayjs.extend(dayjsPluginUTC)

const parseTargetTime = (selectedDuration, targetTime) => {
  const time = targetTime
  const targetTimeCond = dayjs.utc(time).subtract(selectedDuration / 2, 'seconds').format('YYYY-MM-DDTHH:mm');
  return `TIME>dt${targetTimeCond}`
}

export const useSubmitForm = ({
  setHasError,
  showAlert,
  FormAtoms,
  optionSubmit = false,
}) => {
  const { user } = useUserMetadata();
  const [openModal, setOpenModal] = useState(false);
  const [submitModalMessage, setSubmitModalMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [selectedAccounts] = useAtom(FormAtoms.selectedAccountsAtom);
  const [baseQty] = useAtom(FormAtoms.baseQtyAtom);
  const [selectedDuration] = useAtom(FormAtoms.selectedDurationAtom);
  const [limitPrice] = useAtom(FormAtoms.limitPriceAtom);
  const [notes] = useAtom(FormAtoms.notesAtom);
  const [orderCondition] = useAtom(FormAtoms.orderConditionAtom);
  const [selectedPair] = useAtom(FormAtoms.selectedPairAtom);
  const [quoteQty] = useAtom(FormAtoms.quoteQtyAtom);
  const [selectedSide] = useAtom(FormAtoms.selectedSideAtom);
  const [stopPrice] = useAtom(FormAtoms.stopPriceAtom);
  const [selectedStrategy] = useAtom(FormAtoms.selectedStrategyAtom);
  const [selectedStrategyParams] = useAtom(FormAtoms.selectedStrategyParamsAtom);
  const [updatePairLeverage] = useAtom(FormAtoms.updatePairLeverageAtom);
  const [povLimit] = useAtom(FormAtoms.povLimitAtom);
  const [preTradeEstimationData] = useAtom(FormAtoms.preTradeEstimationDataAtom);
  const [povTarget] = useAtom(FormAtoms.povTargetAtom);
  const [convertedQty] = useAtom(FormAtoms.convertedQtyAtom);
  const [trajectory] = useAtom(FormAtoms.trajectoryAtom);
  const [targetTime] = useAtom(FormAtoms.targetTimeAtom);
  const [isOOLEnabled, setIsOOLEnabled] = useAtom(FormAtoms.isOOLEnabledAtom);
  const [alphaTilt] = useAtom(FormAtoms.alphaTiltAtom);
  const [passiveness] = useAtom(FormAtoms.passivenessAtom);
  const [discretion] = useAtom(FormAtoms.discretionAtom);
  const [durationStartDate] = useAtom(FormAtoms.durationStartTimeAtom);
  const [initialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);
  const [maxOtcPercentage] = useAtom(FormAtoms.maxOtcPercentageAtom);
  const [posSide] = useAtom(FormAtoms.posSideAtom);

  const openNewTabOnSubmit = user.preferences ? user.preferences[OPEN_NEW_TAB_ON_SUBMIT] : false

  const {
    strategies,
    trajectories,
    superStrategies,
  } = initialLoadValue;


  const getStrategyName = () => {
    if (selectedStrategy && superStrategies[selectedStrategy] && superStrategies[selectedStrategy].name) {
      return superStrategies[selectedStrategy].name;
    } if (selectedStrategy && strategies[selectedStrategy] && strategies[selectedStrategy].name) {
      return strategies[selectedStrategy].name;
    }
    return null
  }

  const getTrajectoryName = () => {
    if (trajectory && trajectories[trajectory] && trajectories[trajectory].name) {
      return trajectories[trajectory].name;
    }
    return ''
  }

  const parseFormData = (confirmation = false) => {
    const filterPov = (pov) => {
      return pov ? parseFloat(pov) / 100 : null
    }

    const getPair = () => {
      if (selectedPair) {
        if(optionSubmit){
          return selectedPair.name
        }
        if (!confirmation){

          return selectedPair.id
        }
        return selectedPair.label
      }
      return selectedPair
    }

    let super_strategy = null;
    if (
      selectedStrategy &&
      superStrategies &&
      superStrategies[selectedStrategy] &&
      superStrategies[selectedStrategy].is_super_strategy
    ) {
      super_strategy = selectedStrategy;
    }

    return {
      alpha_tilt: alphaTilt,
      accounts: selectedAccounts,
      base_asset_qty: baseQty,
      duration: confirmation ? Number(selectedDuration / 60).toFixed(1) : selectedDuration,
      engine_passiveness: passiveness,
      limit_price: limitPrice,
      notes,
      order_condition:
        ( superStrategies && superStrategies[super_strategy] && superStrategies[super_strategy].name==='Target Time') ?
          parseTargetTime(selectedDuration, targetTime) : orderCondition,
      pair: getPair(),
      quote_asset_qty: quoteQty,
      schedule_discretion: discretion,
      side: selectedSide,
      stop_price: stopPrice,
      strategy: trajectory,
      strategy_params: {...selectedStrategyParams, 'ool_pause': isOOLEnabled },
      updated_leverage: updatePairLeverage,
      pov_limit: confirmation ? parseFloat(povLimit) : filterPov(povLimit),
      pov_target: confirmation ? parseFloat(povTarget) : filterPov(povTarget),
      pre_trade_data: preTradeEstimationData,
      super_strategy,
      start_datetime: durationStartDate ?
        durationStartDate.setZone("utc").toISO() : null,
      max_otc: maxOtcPercentage / 100,
      pos_side: posSide,
    }
  }

  const getOrderPath = (order) => {
    let url = null;
    if (order.parent_order) {
      url = `/multi_order/${order.parent_order}`;
    } else if (order.is_simple) {
      url = `/simple_order/${order.id}`;
    } else {
      url = `/order/${order.id}`;
    }

    return url;
  }

  const getFormData = (confirmation = false) => {
    const formData = parseFormData(confirmation)
    const vettedFormData = confirmation ? formData : {
      ...removeFalsyAndEmptyKeys(formData),
      alpha_tilt: alphaTilt, engine_passiveness: passiveness, schedule_discretion: discretion
    }
    return vettedFormData
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setHasError(false);
    setOpenModal(false);
    setSubmitModalMessage('')
    setIsSubmitted(true);

    const orderFields = getFormData()

    try {
      const response = await submitOrder(orderFields);

      if (response.id) {
        setIsSubmitted(false);
        if (openNewTabOnSubmit) {
          openInNewTab(getOrderPath(response));
        }
      } else {
        showAlert({
          severity: 'error',
          message: response,
        });
      }
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({
          severity: 'error',
          message: e.message,
        });
      } else {
        throw e;
      }
      setIsSubmitted(false);
    }
  };

  const submitCheck = async (event, relevantExchangePairs) => {
    event.preventDefault();

    if (relevantExchangePairs.length > 0) {
      const baseAssetQty = Number(baseQty || convertedQty);
      const lowestMaxBaseQty = Math.min(...relevantExchangePairs.map(pair => Number(pair.max_base_asset_market)));

      if (baseAssetQty && baseAssetQty > 100 * lowestMaxBaseQty) {
        setSubmitModalMessage(
          `This order may not complete due to trade limits (market order qty limit: ${lowestMaxBaseQty})`
        );
        setOpenModal(true);
        return;
      }
    }

    const {pov} = preTradeEstimationData;
    if (pov && Number(pov) > 2.5) {
      setSubmitModalMessage(
        'This order may have significant market impact ' +
        `due to an expected high participation rate of ${Number(pov).toFixed(2)}%`
      );
      setOpenModal(true);
      return;
    }
    setSubmitModalMessage('')
    setOpenModal(true);
  }

  function ConfirmationModalProps(isBuySide) {
    return {
      convertedQty,
      data: getFormData(true),
      handleConfirm: handleSubmit,
      isBuy: isBuySide,
      modalText: submitModalMessage,
      open: openModal,
      optionSubmit,
      setOpen: setOpenModal,
      strategies,
      superStrategies,
      limitPrice,
    }
  }


  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
  };

  const closeButtonStyle = {
    position: 'absolute',
    right: 8,
    top: 8,
  };

  function OrderTemplateModal({
    type, open, setOpen, orderTemplates,
    setSelectedAccounts, setSelectedSide, setSelectedPair, handleBaseChange, handleQuoteChange,

    setPovLimit, setPovTarget, setOrderCondition, setAlphaTilt, setDiscretion, setPassiveness,
    setSelectedStrategy, setTrajectory, setSelectedStrategyParams, setSelectedDuration, setUpdatePairLeverage,

    setTargetTime, setStopPrice, setLimitPrice, setNotes, setLoading
  }) {
    const [selectedTemplate, setSelectedTemplate] = useState(orderTemplates[0])
    const [orderSaveName, setOrderSaveName] = useState('')
    const [orderFormState, setOrderFormState] = useState([])
    const [limitPriceQuickSetting, setLimitPriceQuickSetting] = useAtom(FormAtoms.limitPriceQuickSettingAtom);

    const getValues = (setter = false) => {
      return {
        accounts: setter ? setSelectedAccounts : selectedAccounts,
        pair:  setter ? setSelectedPair : selectedPair,
        side:  setter ? setSelectedSide : selectedSide,
        base_asset_qty: setter ? handleBaseChange : baseQty,
        quote_asset_qty:  setter ? handleQuoteChange : quoteQty,
        super_strategy : setter ? setSelectedStrategy : selectedStrategy,
        strategy:  setter ? setTrajectory : trajectory,
        // eslint-disable-next-line no-nested-ternary
        duration:  setter ? setSelectedDuration : !povTarget ? selectedDuration : undefined,
        engine_passiveness:  setter ? setPassiveness : passiveness,
        alpha_tilt: setter ? setAlphaTilt : alphaTilt,
        schedule_discretion:  setter ? setDiscretion : discretion,
        strategy_params:  setter ? setSelectedStrategyParams : {...selectedStrategyParams, 'ool_pause': isOOLEnabled },
        order_condition: setter ? setOrderCondition : orderCondition,
        notes:  setter ? setNotes : notes,
        // eslint-disable-next-line no-nested-ternary
        // limit_price:  selectedLimitPriceQuickSetting ?
        //   (setter ? setSelectedLimitPriceQuickSetting : selectedLimitPriceQuickSetting ) :
        //   (setter ? setLimitPrice : limitPrice),
        limit_price: setter ? setLimitPrice : limitPrice,
        limit_price_options: setter ? setLimitPriceQuickSetting : limitPriceQuickSetting,

        updated_leverage:  setter ? setUpdatePairLeverage : updatePairLeverage,
        pov_limit: setter ? setPovLimit : povLimit,
        pov_target: setter ? setPovTarget : povTarget,
        // Target time should only show for Target Time super strat
        target_time:  setter ? setTargetTime : (getStrategyName() === 'Target Time' && targetTime.toISOString()),
        stop_price:  setter ? setStopPrice : stopPrice,
      }
    }

    const getDisplayNames = {
      accounts: 'Accounts',
      pair:  'Pair',
      side: 'Side',
      base_asset_qty: 'Base Quantity',
      quote_asset_qty:  'Quote Quantity',
      super_strategy : 'Super Strategy',
      strategy: 'Trajectory',
      duration: 'Duration',
      engine_passiveness:  'Passiveness',
      alpha_tilt: 'Alpha Tilt',
      schedule_discretion: 'Discretion',
      strategy_params: 'Strategy Params',
      order_condition:'Order Condition',
      notes:  'Notes',
      limit_price:  'Limit Price',
      updated_leverage:  'Updated Leverage',
      pov_limit:'POV Limit',
      pov_target:  'POV Target',
      target_time: 'Target Time',
      stop_price:  'Stop Price',
    }

    const getDisplayValues = () => {
      if(!selectedTemplate){
        return []
      }

      const templateValueMapping = {}

      Object.entries(selectedTemplate.values).forEach(([k,v]) => {
        const displayKey = k
        switch(k){
        case 'accounts':
          templateValueMapping[displayKey] = v ? v.map((names, index) => {
            if(index === v.length-1 ) {
              return `${names  }`
            }
            return `${names  } | `
          }) : ''
          break;
        case 'pair':
          templateValueMapping[displayKey] = v ? v.label : ''
          break;
        case 'side':
          templateValueMapping[displayKey] = titleCase(v)
          break;
        case 'strategy_params':
          templateValueMapping[displayKey] =  Object.keys(v).length !== 0 ?
            Object.keys(v).map((key) => {
              if(!v[key]){
                return null
              }
              return (
                <Typography key={key}><li>{titleCase(key)}</li></Typography>
              )
            }) : ''
          break;
        case 'notes':
        case 'order_condition':
          templateValueMapping[displayKey] = (
            <Typography
              sx = {{    wordWrap: 'breakWord'
              }}>
              {v}
            </Typography>
          )
          break;
        case 'strategy':
          templateValueMapping[displayKey] = getTrajectoryName()
          break;
        case 'super_strategy':
          templateValueMapping[displayKey] = getStrategyName();
          break;
        case 'limit_price_options':
          templateValueMapping.limit_price = v
          break;
        default:
          templateValueMapping[displayKey] = v
        }
      })

      return Object.keys(getDisplayNames).map((key) => {
        if(selectedTemplate && Object.keys(selectedTemplate.values).includes(key)){
          return [getDisplayNames[key], templateValueMapping[key]]
        }
        return [getDisplayNames[key], '']
      })

    }


    useEffect(() => {
      setOrderFormState(getDisplayValues())
    }, [selectedTemplate])


    const loadOrderTemplate = async (template) => {

      if (Object.keys(template.values).length < 1) {
        return
      }
      setOpen(false)
      setLoading(true)

      const getValuesWithSetters = getValues(true)

      const getValuesKeys = Object.keys(getValues())
      if (Object.keys(template.values).includes('side')){
        getValuesWithSetters.side(template.values.side)
      }

      if (Object.keys(template.values).includes('accounts')){
        getValuesWithSetters.accounts(template.values.accounts)
      }

      if (Object.keys(template.values).includes('pair')){
        getValuesWithSetters.pair(template.values.pair)
      }

      if (Object.keys(template.values).includes('quote_asset_qty')){
        getValuesWithSetters.quote_asset_qty(template.values.quote_asset_qty)
      }

      if (Object.keys(template.values).includes('base_asset_qty')){
        getValuesWithSetters.base_asset_qty(template.values.base_asset_qty)
      }

      await new Promise(r => {setTimeout(r, 2000)}).then(() => {
        Object.keys(template.values).forEach(templateKey => {
          const foundValue = getValuesKeys.find((ele) => ele === templateKey)
          if(foundValue) {
            if(foundValue === 'limit_price') {
              if (Object.keys(template.values).includes('limit_price_options')){
                getValuesWithSetters.limit_price_options(template.values.limit_price_options)
              } else {
                getValuesWithSetters[foundValue](template.values[templateKey])
              }

              if (Object.keys(template.values.strategy_params) > 0 && template.values.strategy_params.ool_pause) {
                setIsOOLEnabled(true)
              }
            } else {
              getValuesWithSetters[foundValue](template.values[templateKey])
            }
          }
        })
      }).then(() =>{
        setLoading(false)
      });
    }

    const saveOrderTemplate = (templateName) => {
      if(templateName === ''){
        return
      }
      const newValues = removeFalsyAndEmptyKeys(getValues())

      try {
        const body = {
          name: templateName,
          values: newValues
        }
        createOrderTemplate(body)
      } catch (err) {
        showAlert({ severity: 'error', message: `Unable to create template: ${err.message}` });
      }
      setOpen(false)
    }

    const deleteOrderTemplate = (template) => {
      deleteOrderTemplates( [template.id])
      setOpen(false)
    }

    return (
      <div>
        <Modal
          closeAfterTransition
          aria-describedby="transition-modal-description"
          aria-labelledby="transition-modal-title"
          open={open}
          PaperProps={{
            sx: {
              minHeight: '80%',
              maxHeight: '80%'
            }
          }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
          slots={{ backdrop: Backdrop }}
          onClose={() => setOpen(false)}
        >
          <Fade in={open}>
            <Box display='flex' flexDirection='column' justifyContent='center' sx={modalStyle}>
              <Typography
                gutterBottom
                style={{ marginLeft: '12px', marginBottom: '12px'}}
                variant="h2"
              >
                Order Templates
              </Typography>
              <IconButton
                aria-label="close"
                sx={closeButtonStyle}
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </IconButton>
              <div>
                {type === 'manage' ?
                  <>
                    <Stack direction='row' spacing={2}>
                      <FormControl                           sx={{width: '50%'}}>
                        <InputLabel id="template-label">Templates</InputLabel>
                        <Select
                          label="Templates"
                          labelId="template-label"
                          size='small'
                          sx={{width: '100%'}}
                          value={selectedTemplate}
                          onChange={(e) => setSelectedTemplate(e.target.value)}
                        >
                          {orderTemplates.map((template) => (
                            <MenuItem
                              key={template.id}
                              value={template}
                            >
                              {template.name}
                            </MenuItem>
                          ))}


                        </Select>
                      </FormControl>
                      <Button
                        color='secondary'
                        size='small'
                        sx={{width: '25%'}}
                        variant="contained"
                        onClick={async (e) => {
                          e.preventDefault()
                          await loadOrderTemplate(selectedTemplate)
                        }}
                      >
                        Load
                      </Button>
                      <Button
                        color='secondary'
                        size='small'
                        sx={{width: '25%'}}
                        variant="contained"
                        onClick={() => deleteOrderTemplate(selectedTemplate)}>
                        Delete
                      </Button>
                    </Stack>
                    <TableContainer sx={{height: '70vh'}}>
                      <Table aria-label="a dense table" size="small" sx={{ minWidth: 450, overflow: 'auto'}}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell/>
                            <StyledTableCell/>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orderFormState.map((k) => (
                            <TableRow
                              key={k[0]}
                            >
                              <StyledTableCell
                                sx={{borderRight: "1px solid rgb(81 81 81)", width: '160px' }}
                              >
                                {k[0]}
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{maxWidth: '300px'}}
                              >
                                {k[1]}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </> :
                  <Stack direction='row' spacing={2}>
                    <TextField
                      label="Name"
                      size='small'
                      sx={{width: '75%'}}
                      value={orderSaveName}
                      onChange={e => { setOrderSaveName(e.target.value); } }
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          saveOrderTemplate(e.target.value)
                        }
                      }}
                    />
                    <Button
                      fullWidth
                      color='secondary'
                      label="Name"
                      size='small'
                      sx={{width: '25%'}}
                      variant="contained"
                      onClick={() => saveOrderTemplate(orderSaveName)}
                    >
                      Save
                    </Button></Stack>}
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    )
  }


  return { ConfirmationModalProps, submitCheck, isSubmitted, OrderTemplateModal }

}