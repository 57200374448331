import React from 'react';

import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useAtom } from 'jotai';
import {
  StrategyParamsParsed,
  StyledBorderTableCell,
  StyledHeaderTableCell,
} from '../../../shared/orderTable/util';

const formatAmount = (base, convertedQty) => {
  const amount = base || convertedQty;
  return Number(amount).toFixed(6);
}

const formatNotionalAmount = (quote, convertedQty) => {
  const notional = quote || convertedQty;
  return Number(notional).toFixed(2);
}

const StyledHeaderModalCell = styled(TableCell)(({ theme }) => {
  return {
    [`&.${StyledHeaderTableCell}-head`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      borderBottom: 0,
    },
    borderBottom: 0,
    fontSize: '0.95rem',
  };
})

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 'min-content',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
};

const buttonStyle = {
  marginTop: 1,
  marginBottom: 2,
  marginLeft: 'auto',
  marginRight: 'auto',
}

const closeButtonStyle = {
  position: 'absolute',
  right: 12,
  top: 12,
};


export function OrderConfirmationModal({
  limitPrice, FormAtoms,
  superStrategies, strategies, open, setOpen, data, handleConfirm, isBuy, convertedQty, modalText, optionSubmit
}) {
  const theme = useTheme()

  const [selectedPairPrice] = useAtom(FormAtoms.selectedPairPriceAtom);

  const generatePredictedPov = (pov) => {
    let color;
    const datapPov = Number(pov);

    if (!datapPov) {
      return <Typography fontFamily='monospace'>
        N/A
      </Typography>
    }

    if (datapPov < 0.5) {
      color = theme.palette.success.main;
    } else if (datapPov < 1) {
      color = theme.palette.warning.main;
    } else {
      color = theme.palette.error.main;
    }

    return (
      <Typography color={color} fontFamily='monospace'>
        {`${datapPov.toFixed(4)}%`}
      </Typography>
    )
  }


  let strategyName = "";

  if (
    superStrategies &&
    data.super_strategy &&
    superStrategies[data.super_strategy] &&
    superStrategies[data.super_strategy].name
  ) {
    strategyName = superStrategies[data.super_strategy].name;
  } else if (
    strategies &&
    data.strategy &&
    strategies[data.strategy] &&
    strategies[data.strategy].name
  ) {
    strategyName = strategies[data.strategy].name;
  }
  const { volatility, marketVolume, pov } = data.pre_trade_data

  const limitPriceGuidance = () => {
    if (!selectedPairPrice.price) {
      return null
    }

    const upperBound = selectedPairPrice.price * (1 + volatility / 100)
    const lowerBound = selectedPairPrice.price * (1 - volatility / 100)

    if (limitPrice < lowerBound || limitPrice > upperBound) {
      return (
        <Typography color='primary.light'>
          Limit price is far from the current market.
        </Typography>
      )
    }

    return (
      <Typography color='text.primary'>
          Limit price is currently marketable.
      </Typography>
    )
  }

  return (
    <div>
      <Modal
        closeAfterTransition
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        open={open}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        slots={{ backdrop: Backdrop }}
        onClose={() => setOpen(false)}
      >
        <Fade in={open}>
          <Box display='flex' flexDirection='column' justifyContent='center' sx={modalStyle}>
            <Typography gutterBottom style={{marginTop: '12px', marginLeft: '12px'}} variant="h1">
              Order Confirmation
            </Typography>
            <IconButton
              aria-label="close"
              sx={closeButtonStyle}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <Divider variant='middle'/>
            <Stack direction='column' style={{ whiteSpace: 'nowrap'}}>
              <Stack direction='row' style={{ whiteSpace: 'nowrap' }}>
                <Table size="small" >
                  <TableHead>
                    <TableRow>
                      <StyledHeaderTableCell
                        align='left'
                        key="buy sell"
                        style={{ width: 300 }}
                      >
                          Side
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell
                        align='left'
                        key="token"
                        style={{ width: 300 }}
                      >
                          Token
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell
                        align='left'
                        key="avg exec price"
                        style={{ width: 300 }}
                      >
                        Amount
                      </StyledHeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledBorderTableCell >
                        {isBuy ? 'Buy' : 'Sell'}
                      </StyledBorderTableCell>
                      <StyledBorderTableCell>
                        {data.pair}
                      </StyledBorderTableCell>
                      <StyledBorderTableCell>
                        {formatAmount(data.base_asset_qty, convertedQty)}
                      </StyledBorderTableCell>
                    </TableRow>
                  </TableBody>
                  <TableHead>
                    <TableRow>
                      <StyledHeaderTableCell
                        align='left'
                        key="duration"
                        style={{ width: 300 }}
                      >
                        Duration
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell
                        align='left'
                        key="target quantity"
                        style={{ width: 300 }}
                      >
                        Strategy
                      </StyledHeaderTableCell>
                      <StyledHeaderTableCell
                        align='left'
                        key="notional"
                        style={{ width: 300 }}
                      >
                        {!optionSubmit ? 'Notional' : ''}
                      </StyledHeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledBorderTableCell>
                        {Number(data.duration).toFixed(1)} min
                      </StyledBorderTableCell>
                      <StyledBorderTableCell>
                        {strategyName}
                      </StyledBorderTableCell>
                      <StyledBorderTableCell>
                        {!optionSubmit ? formatNotionalAmount(data.quote_asset_qty, convertedQty) : '' }
                      </StyledBorderTableCell>
                    </TableRow>
                  </TableBody>
                  { data.updated_leverage &&
                  <><TableHead>
                    <TableRow>
                      <StyledHeaderTableCell
                        align='left'
                        key="buy sell"
                        style={{ width: 300 }}
                      >
                          Pair Leverage
                      </StyledHeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledBorderTableCell>
                        {data.updated_leverage}
                      </StyledBorderTableCell>
                    </TableRow>
                  </TableBody>
                  </>
                  }
                </Table>
                <Table size="small" >
                  <TableHead>
                    <TableRow>
                      <StyledHeaderTableCell
                        align='left'
                        key="strategy_params"
                        style={{ width: 300 }}
                      >
                        Strategy Configurations
                      </StyledHeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{
                      '&.MuiTableRow-root': {
                        verticalAlign: 'top',
                      }
                    }}>
                      <StyledBorderTableCell>
                        <StrategyParamsParsed
                          useIBM
                          alpha_tilt={data.alpha_tilt}
                          engine_passiveness={data.engine_passiveness}
                          pov_limit={data.pov_limit}
                          pov_target={data.pov_target}
                          schedule_discretion={data.schedule_discretion}
                          strategy_params={data.strategy_params}
                        />
                      </StyledBorderTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Stack>
              { limitPrice &&
              <Stack direction="row">
                <Table sx={{width: '30%'}}>
                  <TableHead>
                    <TableRow>
                      <StyledHeaderTableCell>
                        Limit Price
                      </StyledHeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableRow>
                    <StyledBorderTableCell>
                      <Typography>
                        {limitPrice}
                      </Typography>
                    </StyledBorderTableCell>
                  </TableRow>
                </Table>
                <Box alignItems="start" display='flex' flexDirection="column" justifyContent="center">
                  <Typography>
                    {limitPriceGuidance()}
                  </Typography>
                </Box>
              </Stack>
              }
              <Table size="small" sx={{
                '&.MuiTable-root': {
                  width: '60%',
                }
              }}>
                <TableHead>
                  <TableRow>
                    <StyledHeaderModalCell
                      align='left'
                      key="pretrade"
                      style={{ width: 400 }}
                    >
                    Pre-Trade Analytics
                    </StyledHeaderModalCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledBorderTableCell style={{ marginTop: '12px'}}>
                      <Typography>Participation Rate:</Typography>
                    </StyledBorderTableCell>
                    <StyledBorderTableCell align='right' style={{ marginTop: '12px'}}>
                      {generatePredictedPov(pov)}
                    </StyledBorderTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledBorderTableCell style={{ marginTop: '12px'}}>
                      <Typography>
                        Expected Volatility:
                      </Typography>
                    </StyledBorderTableCell>
                    <StyledBorderTableCell align='right' style={{ marginTop: '12px'}}>
                      <Typography fontFamily='monospace'>
                        {volatility === undefined ? 'N/A' : `±${Number(volatility).toFixed(4)}%`}
                      </Typography>
                    </StyledBorderTableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Divider variant='middle' />
              <Typography
                color="primary"
                style={{ wordWrap: "break-word", marginLeft: '12px', marginRight: '12px', marginTop: '12px' }}
                variant="subtitle2"
              >{modalText}</Typography>
            </Stack>
            <Button
              color={isBuy ? 'success' : 'error'}
              style={{width: '70%'}}
              sx={buttonStyle}
              variant='contained'
              onClick={handleConfirm}
            >
              <Typography color={isBuy ? 'text.offBlack' : 'text.offWhite'} style={{ whiteSpace: 'nowrap' }}>
                Place {isBuy ? 'Buy' : 'Sell'} Order
              </Typography>
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
