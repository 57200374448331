import { useTheme } from '@emotion/react';
import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

import { Box, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import {
  StyledTableCell
} from '../../shared/orderTable/util';
import { msAndKs, truncate } from '../../util';

import { refreshAccountBalanceCache } from '../../apiServices'
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';

export default function AccountSummaryTable(
  {balances, pastSnapshots, setSelectedAccount, selectedAccount, showAlert, getAccounts, calculateTotalValue}
) {
  const [updatingAccountId, setUpdatingAccountId] = useState('');
  const theme = useTheme();
  const { user } = useUserMetadata();

  const balanceToRow = (balance) => {
    let dayAgoDiff = null;
    let weekAgoDiff = null;

    const currentTotalValue = balance.totalValue;

    if (pastSnapshots && pastSnapshots[balance.account_name]) {
      const dayAgoSnapshot = pastSnapshots[balance.account_name].day_ago;
      const weekAgoSnapshot = pastSnapshots[balance.account_name].week_ago;

      if (dayAgoSnapshot) {
        const prevTotalValue = calculateTotalValue(dayAgoSnapshot);
        dayAgoDiff = 100 * (currentTotalValue - prevTotalValue) / prevTotalValue;
      }

      if (weekAgoSnapshot) {
        const prevTotalValue = calculateTotalValue(weekAgoSnapshot);
        weekAgoDiff = 100 * (currentTotalValue - prevTotalValue) / prevTotalValue;
      }
    }

    return {
      name: balance.account_name,
      exchange: balance.exchange_name,
      userId: balance.user_id,
      username: balance.username,
      totalValue: currentTotalValue,
      dayAgoDiff,
      weekAgoDiff,
      accountId: balance.account_id,
      lastUpdated: `Updated ${moment(balance.timestamp_millis).fromNow()}`,
    }
  }

  const sortedBalances = (balanceInput) => {
    const [netBalance, ...restInput] = balanceInput

    const sortedOtherBalances = [...restInput].sort((a, b) => {
      const aName = a.account_name.toLowerCase();
      const bName = b.account_name.toLowerCase();
      return aName.localeCompare(bName);
    });

    return [netBalance, ...sortedOtherBalances]
  }

  const refreshCacheOnClick = async (event, accountId) => {
    event.stopPropagation();

    setUpdatingAccountId(accountId);

    try {
      await refreshAccountBalanceCache(accountId);
    } catch (e) {
      showAlert({severity: 'error', message: e.message});
    }

    getAccounts(false);
    setUpdatingAccountId('');
  }

  const diffStyleByValue = (value) => {
    if (value >= 0) {
      return "success.main";
    }

    return "error.main";
  }

  const signByValue = (value) => {
    if (value >= 0) {
      return "+";
    }

    return "-";
  }

  const formatDiff = (value) => {
    const percentage = Number(Math.abs(value));

    return `${signByValue(value)}${percentage < 0.01 ? percentage.toFixed(3) : percentage.toFixed(2)}%`;
  }

  const handleAccountClick = (account) => {
    const scopedAccName = user.user_id === account.userId ? account.name : `${account.username}/${account.name}`;
    setSelectedAccount([account.accountId, account.name, scopedAccName]);

  }
  const renderRow = (row) => {
    const displayAccName = user.user_id !== row.userId  && row.name !== 'All Accounts'
      ? `${row.username} - ${row.name}`: row.name ;

    return (
      <Table>
        <TableBody>
          <TableRow
            key={`${row.accountId} ${row.name} TableRow`}
            style={{width: '100%'}}
          >
            <StyledTableCell
              align="left"
              key={`${row.accountId} ${row.name} TableCell`}
              style={{whiteSpace: 'nowrap', padding: '4px 16px'}}
              width='100%'
            >
              <Stack direction='column'>
                <Typography variant="h6">{displayAccName}</Typography>
                <Typography display="block" variant="body2">
                  {`${row.exchange}`}
                </Typography>
                <Box alignItems='center' display='flex' gap={1}>
                  <Typography gutterBottom color='grey.main' display="block" variant="caption">
                    {`${row.lastUpdated}`}
                  </Typography>
                  {row.accountId !== 'All Accounts' &&
                  <Tooltip title='Update to latest account balance'>
                    <IconButton
                      disabled={updatingAccountId === row.accountId}
                      sx={{
                        alignSelf: 'center',
                      }}
                      onClick={(e) => refreshCacheOnClick(e, row.accountId)}
                    >
                      <SyncIcon sx={{fontSize: '1rem', color: theme.palette.grey.main}} />
                    </IconButton>
                  </Tooltip>}
                </Box>
              </Stack>
            </StyledTableCell>
            <StyledTableCell
              align="right"
              key="totalNotional"
              style={{whiteSpace: 'nowrap', padding: '4px 16px'}}
              width='100%'
            >
              <Stack direction='column'>
                <Typography sx={{ fontFamily: 'IBM Plex Mono' }}>
                  {`$${msAndKs(truncate(row.totalValue || 0))}`}
                </Typography>
                { row.dayAgoDiff ?
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="right"
                    marginBottom={row.weekAgoDiff ? "0px" : "5px"}
                    marginTop="5px" width="100%"
                  >
                    <Typography
                      color={diffStyleByValue(row.dayAgoDiff)}
                      sx={{fontFamily: 'IBM Plex Mono' }}
                      variant="body2"
                    >
                      {formatDiff(row.dayAgoDiff)}
                    </Typography>
                    <Typography color="info.dark" marginLeft="0.8rem" variant="body2" width="0.8rem">
                      1d
                    </Typography>
                  </Box> : null
                }
                { row.weekAgoDiff ?
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="right"
                    marginBottom="5px"
                    width="100%"
                  >
                    <Typography color={diffStyleByValue(row.weekAgoDiff)} fontFamily="monospace" variant="body2">
                      {formatDiff(row.weekAgoDiff)}
                    </Typography>
                    <Typography color="info.dark" marginLeft="0.8rem" variant="body2" width="0.8rem">
                      7d
                    </Typography>
                  </Box> : null
                }
              </Stack>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  return (
    <TableContainer sx={{ maxHeight: '90%' }}>

      {
        sortedBalances(balances).map((x, i) => {
          const row = balanceToRow(x);
          return (

            <Button
              key={`${row.accountId  } ${  row.name}`}
              style={{width: '100%', border: selectedAccount[0] === row.accountId ? "solid 1px" : null}}
              variant="text"
              onClick={() => handleAccountClick(row)}
            >
              {renderRow(row)}
            </Button>
          )
        })
      }

    </TableContainer>
  )
}