import Box from '@mui/material/Box';
import React, { useContext, useEffect, useState } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { useAtom } from 'jotai';
import { SharedOrderTable } from '../../shared/orderTable/SharedOrderTable';
import { BASEURL } from '../../util';

import { ErrorContext } from '../../shared/context/ErrorProvider';
import ChipStatusFilter from '../../shared/orderTable/ChipStatusFilter';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';


function OrderTable({FormAtoms}) {
  const [orders, setOrders] = useState([])
  const [statusHighlight, setStatusHighlight] = useState([])
  const [loading, setLoading] = useState(true);
  const [typeFilter, setTypeFilter] = useState([]);
  const [pairFilter, setPairFilter] = useState(false);
  const [selectedPair, setSelectedPair] = useAtom(FormAtoms.selectedPairAtom);

  const {setHasError, setErrorContent} = useContext(ErrorContext);
  const { user } = useUserMetadata();

  useEffect(() => {
    setPairFilter(false)
  }, [selectedPair]);

  const getDashboardOrders = async (reload = false) => {
    try {
      const url = new URL(`${BASEURL}/ems/get_dashboard_orders`);
      const params = {
        'status': statusHighlight,
        'type': typeFilter };
      if(pairFilter && selectedPair.id) {
        params.pair = selectedPair.id;
      }
      url.search = new URLSearchParams(params).toString();

      // don't show loading mask for interval reload
      if (!reload) {
        setLoading(true);
      }
      const response = await fetch(url);
      const messageData = await response.json();

      setOrders(messageData.orders);
      if (!reload) {
        setLoading(false);
      }
    } catch (error) {
      setHasError(true);
      setErrorContent(error.message);
    }
  };

  useEffect(() => {
    if(user && user.is_authenticated){

      getDashboardOrders();

      const intervalId = setInterval(() => { getDashboardOrders(true) }, 2000);
      return () => clearInterval(intervalId);

    }
    setLoading(false);
    return () => {};

  }, [statusHighlight, typeFilter, pairFilter]);

  return (
    <>
      <ChipStatusFilter
        dashboardView
        loadOrders={getDashboardOrders}
        pairFilter={pairFilter}
        selectedPair={selectedPair}
        setPairFilter={setPairFilter}
        setStatusHighlight={setStatusHighlight}
        setTypeFilter={setTypeFilter}
        statusHighlight={statusHighlight}
        typeFilter={typeFilter}
      />
      <Box style={{height: 'calc(100% - 52px)', overflow: 'auto' }}>
        {loading ? (
          <Box
            alignItems="center"
            display="flex"
            height='100%'
            justifyContent="center"
          >
            <ScaleLoader color="#FFFFFF" />
          </Box>) : (
          <SharedOrderTable
            dashboardView
            FormAtoms={FormAtoms}
            orderData={orders}
            orderRefresh={getDashboardOrders} />
        )}
      </Box>
    </>
  )
}

export default OrderTable;
