import React, { useContext, useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { Collapse, Box, Table, TableBody, TableRow, TableHead } from '@mui/material';
import { getBulkChainedOrders } from '../../apiServices';
import DisplayRowDetails from './DisplayRowDetails';
import { ThinLoader } from '../Loader';
import { ErrorContext } from '../context/ErrorProvider';

export default function CollapsedChainedsRow({
  ordersInChain,
  StyledCell,
  columns,
  open,
  ViewOrderTooltip,
}) {
  const [chainedOrderDetails, setChainedOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const theme = useTheme();

  async function fetchChainedOrders(orders) {
    try {
      const fetchedDetails = await getBulkChainedOrders(orders);
      setChainedOrderDetails(fetchedDetails);
    } catch (error) {
      setErrorContent({
        severity: 'error',
        message: `Error fetching chained orders: ${error}`,
      });
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open) {
      fetchChainedOrders(ordersInChain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Collapse in={open}>
      {loading || !chainedOrderDetails ? (
        ordersInChain.map((ChainedId) => (
          <Box key={ChainedId.id || ChainedId} style={{ padding: '10px' }}>
            <ThinLoader />
          </Box>
        ))
      ) : (
        <Table aria-label="chained collapsed table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledCell
                  align={column.align}
                  key={`collapsable chained ${column.id}`}
                  style={{
                    minWidth: column.minWidth,
                    width: column.width || undefined,
                  }}
                />
              ))}
              <StyledCell align="left" key="actions" style={{ width: 190 }}>
                {}
              </StyledCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chainedOrderDetails.map((row) => {
              const order = row.order;
              return (
                <TableRow
                  hover
                  key={order.id}
                  sx={{
                    '& .MuiTableCell-root': {
                      borderBottom: 0,
                    },
                    width: '70%',
                    justifyContent: 'flex-end',
                    alignItems: 'right',
                  }}
                >
                  {columns.map((column) => (
                    <DisplayRowDetails
                      key={column.id}
                      row={order}
                      column={column}
                      StyledCell={StyledCell}
                      theme={theme}
                    />
                  ))}
                  <StyledCell
                    sx={{
                      height: 32,
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      textAlign: 'end',
                    }}
                  >
                    <ViewOrderTooltip row={order} theme={theme} />
                  </StyledCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Collapse>
  );
}
