const defaultStrategySettings = {
  duration: 900,
  passiveness: 0.02,
  discretion: 0.08,
  alphaTilt: 0,
  povTarget: '',
  passive_only: false,
  reduce_only: false,
  dicy: false,
  ool_pause: false,
  activeLimit: false,
};

export default defaultStrategySettings;
