import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { ApiError, deleteAccount, getAccounts, getActiveExchanges, getServerIp } from '../../apiServices';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import { AccountItem } from './AccountItem';
import { AddAccountModal } from './AddAccountModal';
import { BasicModal } from '../../shared/Modal';

export default function KeyManagementPage() {
  const [accounts, setAccounts] = useState([]);
  const [activeExchanges, setActiveExchanges] = useState([]);
  const [openAddAccountModal, setOpenAddAccountModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');
  const [deleteAccountName, setDeleteAccountName] = useState('');
  const [serverIp, setServerIp] = useState('');

  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  }

  const loadAccounts = async () => {
    try {
      const fetchedAccounts = await getAccounts();
      fetchedAccounts.sort((a, b) => a.name.localeCompare(b.name));
      setAccounts(fetchedAccounts);
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({
          severity: 'error',
          message: `Could not load accounts: ${e.message}`,
        });
      } else {
        throw e;
      }
    }
  }

  const loadActiveExchanges = async () => {
    try {
      const fetchedActiveExchanges = await getActiveExchanges();
      setActiveExchanges(fetchedActiveExchanges);
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({
          severity: 'error',
          message: `Could not load active exchanges: ${e.message}`,
        });
      } else {
        throw e;
      }
    }
  }

  const handleAddAccountOnClick = async (event) => {
    setOpenAddAccountModal(true);
  }

  const handleDeleteConfirmation = async () => {
    try {
      await deleteAccount(deleteAccountName);
      showAlert({ severity: 'success', message: `Successfully unlinked account ${deleteAccountName}` })
    } catch (e) {
      if (e instanceof ApiError) {
        showAlert({
          severity: 'error',
          message: `Could not unlink account: ${e.message}`,
        });
      } else {
        throw e;
      }
    } finally {
      setOpenConfirmModal(false);
      loadAccounts();
      setDeleteAccountName('');
    }
  }

  const loadServerIp = async () => {
    try {
      const result = await getServerIp();
      setServerIp(result.server_ip);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Failed to get server IP: ", e)
    }
  }

  useEffect(() => {
    loadAccounts();
    loadActiveExchanges();
    loadServerIp();
  }, []);

  return (
    <Box style={{ height: '100%' }}>
      <Card style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <Grid container spacing={4} sx={{ mx: 2, my: 2 }}>
          <Grid xs={12}>
            <Box alignItems="center" display='flex'>
              <Button color="secondary" size="large" variant="contained" onClick={handleAddAccountOnClick}>
                <Typography variant="subtitle3">
                  Link Exchange API Key (CeFi)
                </Typography>
              </Button>
            </Box>
          </Grid>
          {
            accounts.map((acc) =>
              <Grid key={acc.name} xs={6}>
                <AccountItem
                  accountName={acc.name} apiKey={acc.api_key} createdAt={acc.created_at} exchangeName={acc.exchange}
                  exchangeSettings={acc.settings} id={acc.id} loadAccounts={loadAccounts} marginMode={acc.margin_mode}
                  setConfirmModalText={setConfirmModalText} setDeleteAccountName={setDeleteAccountName}
                  setOpenConfirmModal={setOpenConfirmModal} showAlert={showAlert}
                />
              </Grid>
            )
          }
        </Grid>
      </Card>
      <AddAccountModal
        activeExchanges={activeExchanges}
        loadAccounts={loadAccounts}
        open={openAddAccountModal}
        serverIp={serverIp}
        setOpen={setOpenAddAccountModal}
        showAlert={showAlert}
      />
      <BasicModal
        confirmButtonText="Yes"
        handleConfirm={handleDeleteConfirmation}
        message={confirmModalText}
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
      />
    </Box>
  )
}