import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { Container, InputAdornment, Stack, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { useAtom } from "jotai";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import BorderedStack from "../../../pages/dashboard/orderEntry/AlgoOrderFieldsComponents/BorderedStack";
import DurationField from "../../../pages/dashboard/orderEntry/AlgoOrderFieldsComponents/DurationField";
import { PovTargetField } from '../ParticipationRate';
import { AlphaTiltSlider, DiscretionSlider, PassivenessSlider } from "../Sliders";
import AlgoNumberField from "../../../pages/dashboard/orderEntry/AlgoOrderFieldsComponents/AlgoNumberField";
import { noArrowStyle } from "../../../util";
import { TimezoneAutoComplete, timeZoneNoOffset } from "../../TimezoneUtil";
import { timezoneAtom } from "../../hooks/useGlobalFormReducer";

const timezone = require("dayjs/plugin/timezone");
dayjs.extend(timezone);
const timeZone = dayjs().tz(dayjs.tz.guess()).format('z');

export function TargetTimeView({
  selectedDuration,
  setSelectedDuration,
  targetTime,
  setTargetTime,
  isCalculatingDuration,
}) {


  const [timeZone,] = useAtom(timezoneAtom);

  const handleDateChange = (value) => {
    if (value !== null) {
      setTargetTime(value.setZone(timeZoneNoOffset(timeZone)).toFormat('MM/dd/yyyy HH:mm'));
    }


  }

  return (
    <Stack direction="column" spacing={2}>
      <TimezoneAutoComplete sx={{ width: '100%' }} />

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Stack direction="row" spacing={2} width="100%">
          <Container disableGutters>
            <DateTimeField
              ampm={false}
              format="MM/dd/yyyy HH:mm"
              label={`Target Time (${timeZoneNoOffset(timeZone)})`}
              name="targetTimeField"
              slotProps={{
                textField: {
                  size: 'small',
                },
                field: {
                  clearable: true,
                  onClear: () => setTargetTime(undefined),
                },
              }}
              sx={{ ...noArrowStyle, width: '100%' }}
              value={targetTime.setZone(timeZoneNoOffset(timeZone))}
              views={['year', 'month', 'day', 'hours', 'minutes']}
              onChange={(value) => handleDateChange(value)}
            />
          </Container>

          <AlgoNumberField
            InputProps={{
              endAdornment: (
                isCalculatingDuration &&
              <InputAdornment position='end'>
                <CircularProgress size={20} sx={{color: 'info.main'}} />
              </InputAdornment>
              )
            }}
            label='Interval (minutes)'
            size='small'
            sx={{
              width: '30%'
            }}
            value={selectedDuration !== null ? (Math.max(selectedDuration / 60, 1)/2) : ''}
            onChange={(e) => {
              const {value} = e.target;
              setSelectedDuration(value === '' ? null : Number(value) * 60 * 2);
            }}
          />

        </Stack>
      </LocalizationProvider>

    </Stack>
  );
}
