import { useTheme } from '@emotion/react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import React, { useLayoutEffect, useRef, useEffect, useState } from 'react';
import { buildPausePlotBands, filterPausedData } from '../../../../util';
import useWatermark from '../../../../shared/hooks/useWatermark';

function FillOrderChart({data, orderData}) {

  const theme = useTheme()
  const { offWhite } = theme.palette.text
  const { red, green } = theme.palette.charts

  const chartComponent = useRef(null);

  const [updateOTCOnce, setUpdateOTCOnce] = useState(false)

  useLayoutEffect(() => {
    function updateSize() {
      if(chartComponent.current){
        chartComponent.current.chart.reflow();
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const isPaused = orderData.status === 'PAUSED';

  const pausePlotBands = buildPausePlotBands(data.order_pause_windows, isPaused, orderData.paused_at, theme);

  let {time_end, orig_time_end, time_start} = orderData
  time_end = Date.parse(time_end)
  orig_time_end = Date.parse(orig_time_end)
  time_start = Date.parse(time_start)

  const last_fill_data_time = data.cumulative_fills_data &&
    data.cumulative_fills_data.length > 0 ? data.cumulative_fills_data[data.cumulative_fills_data.length - 1].x : 0
  const xAxisMax = Math.max(time_end, last_fill_data_time);

  const lastOTCFillData = data.cumulative_otc_fills_data
    .reduce((last, item) => {
      return item.x > last.x ? item : last;
    }, { x: 0, y: 0 });

  const lastOTCFillTime = lastOTCFillData.x;
  const lastOTCYValue = lastOTCFillData.y;

  const allFillsData = [...data.cumulative_passive_fills_data, ...data.cumulative_aggressive_fills_data]

  const lastFillY = allFillsData.reduce((max, item) => {
    return item.y > max ? item.y : max;
  }, 0);

  const bidAskMaxLength =
  time_end < orig_time_end ?
    orig_time_end :
    time_end

  useEffect(() => {
    const {chart} = chartComponent.current;

    if (chart && lastOTCYValue !== undefined && data.cumulative_otc_fills_data.length > 0 && !updateOTCOnce) {
      const yAxis = chart.yAxis[0];
      const xPos1 = chart.xAxis[0].toPixels(lastOTCFillTime);
      const xPos2 = chart.xAxis[0].toPixels(time_end);
      const yPos1 = yAxis.toPixels(lastFillY);
      const yPos2 = yAxis.toPixels(lastOTCYValue);

      chart.renderer.rect(xPos1, yPos2, xPos2 - xPos1, yPos1 - yPos2)
        .attr({
          fill: 'rgba(255, 115, 0, 0.25)',
          zIndex: 3
        })
        .add();

      setUpdateOTCOnce(true);
    }
  }, [lastOTCFillTime, time_end, lastOTCYValue, data]); // Ensure dependencies are correct to avoid unnecessary redraws


  const options = {
    chart: {
      animation: false,
      backgroundColor: 'transparent',
      zooming: {
        mouseWheel: false
      },
      zoomType: null,
      marginLeft: 80,
    },
    series:[
      {
        type: 'line',
        color: '#808080',
        data: filterPausedData(data.schedule_target_data, pausePlotBands),
        lineWidth: 2,
        enableMouseTracking: false,
      },
      {
        type: 'line',
        color: 'rgb(255, 115, 0)',
        data: filterPausedData(data.schedule_target_dicy_upper, pausePlotBands),
        dashStyle: 'Dash',
        lineWidth: 3,
        enableMouseTracking: false,

      },
      {
        name: 'Cumulative Fill',
        type: 'line',
        color: offWhite,
        data: data.cumulative_fills_data,
        step: 'left',
        lineWidth: 3,
        enableMouseTracking: false,
      },
      {
        type: 'line',
        color: offWhite,
        data: filterPausedData(data.schedule_target_lower, pausePlotBands),
        lineWidth: 3,
        dashStyle: 'Dash',
        enableMouseTracking: false,
      },
      {
        type: 'line',
        color: offWhite,
        data: filterPausedData(data.schedule_target_upper, pausePlotBands),
        lineWidth: 3,
        dashStyle: 'Dash',
        enableMouseTracking: false,
      },
      {
        name: 'Maker Fill',
        type: 'scatter',
        color: green,
        borderColor: offWhite,
        data: data.cumulative_passive_fills_data,
        marker:{
          lineWidth: 1,
          enabled: true,
          radius: 4,
          symbol: 'circle',
        }
      },
      {
        name: 'Taker Fill',
        type: 'scatter',
        color: red,
        borderColor: offWhite,
        data: data.cumulative_aggressive_fills_data,
        marker:{
          lineWidth: 1,
          enabled: true,
          radius: 4,
          symbol: 'circle',
        }
      },
      {
        name: 'Cross Fills',
        type: 'scatter',
        color: 'rgb(255, 115, 0)',
        borderColor: offWhite,
        data: data.cumulative_cross_fills_data,
        enableMouseTracking: true,
        marker:{
          lineWidth: 1,
          enabled: true,
          radius: 4,
          symbol: 'circle'
        }
      },
      {
        name: 'OTC Fills',
        type: 'scatter',
        color: 'rgb(255, 115, 0)',
        borderColor: offWhite,
        data: data.cumulative_otc_fills_data,
        enableMouseTracking: true,
        marker:{
          lineWidth: 1,
          enabled: true,
          radius: 4,
          symbol: 'square'
        }
      },
    ],
    yAxis: {
      title: {
        text: "Amount",
        style: {
          color: theme.palette.text.offWhite
        },
      },
      max: Number(orderData.sell_token_amount),
      opposite: false,
      gridLineColor: theme.palette.charts.gridLines,
      labels: {
        style: {
          color: theme.palette.text.offWhite
        },
      },
    },
    xAxis: {
      type: 'datetime',
      startOnTick: false,
      endOnTick: false,
      softMax: bidAskMaxLength,
      min: time_start,
      max: xAxisMax,
      plotBands: [
        ...pausePlotBands,

      ],
      dateTimeLabelFormats: {
        minute: '%H:%M'
      },
      labels: {
        style: {
          color: theme.palette.text.offWhite
        },
      },
      ordinal: false,
    },
    legend: {
      enabled: false,
      itemStyle: {
        color: theme.palette.text.offWhite,
      }
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          },
          select: {
            enabled: false
          }
        }
      }
    },
    tooltip: {
      outside: true,
      shared: true,
      useHTML: true,
      formatter () {
        try {
          const { series, x } = this
          let s = `<b>${  Highcharts.dateFormat('%A, %b %e, %Y', x)  }</b>`;

          if(series.type === 'scatter'){
            const { point } = this
            // Only add series that are not named 'BidLine' or 'AskLine'
            s += `<br/>${  point.series.name  }: ${ point.y}`;
            return s
          }
          return ''

        } catch (err) {
          return false
        }
      }
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    scrollbar: {
      enabled: false,
    },
    credits: {
      enabled: false,
    }
  };

  const watermarkedOptions = useWatermark({options});

  return (
    <HighchartsReact
      constructorType='stockChart'
      containerProps={{ style: { height: "100%", marginY: '8px' } }}
      highcharts={Highcharts}
      options={watermarkedOptions}
      ref={chartComponent} />
  )}

export { FillOrderChart };
