import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import HelpOutline from "@mui/icons-material/HelpOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { theme } from "../../theme/theme";
import { ApiError, validateOrderCondition } from "../../apiServices";

const snakeToTitleCase = (str) => {
  return str
    .split('_')
    .map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join(' ');
}

const infoIconStyle = {
  color: 'rgba(255,255,255,0.6)',
};

const boxInfoHoverStyles = {
  '&:hover': {
    '.icon-hover': {
      color: 'rgba(255, 255, 255, 0.8)',
    },
    '.text-hover': {
      textDecoration: 'underline',
    }
  }
};

function StrategyParamSelect({
  strategyParams,
  handleStrategyParamChange,
  selectedStrategyParams,
  showHeading=true
}) {
  return (
    <>
      <Link
        display={showHeading ? 'block' : 'none'}
        href= "https://tread-labs.gitbook.io/api-docs/strategy-configuration/strategy-parameters"
        rel= "noopener noreferrer"
        target= "_blank"
      >
        <Box alignItems='center' display='flex' gap={1} sx={boxInfoHoverStyles}>
          <Typography sx={{marginBottom: '5px'}} variant="h6">
              Strategy Parameters
          </Typography>
          <HelpOutline className='icon-hover' sx={infoIconStyle}/>
        </Box>
      </Link>
      <FormGroup>
        {
          strategyParams.map(param => (
            <FormControlLabel
              control={
                <Checkbox
                  // Ensure default false if undefined
                  checked={selectedStrategyParams ? !!selectedStrategyParams[param] : false}
                  name={param}
                  size='small'
                  sx={{
                    fontSize: '0.7rem',
                    '&.MuiCheckbox-root': {
                      p: '5px',
                      ml: '4px',
                    }
                  }}
                  onChange={handleStrategyParamChange}
                />
              }
              key={param}
              label={snakeToTitleCase(param)}
            />
          ))
        }
      </FormGroup>
    </>
  )
}

function ConditionValidateField({
  orderCondition, setOrderCondition, isOrderConditionValidated, setIsOrderConditionValidated,
  showAlert, rows=1,
}) {
  const [orderConditionLoading, setOrderConditionLoading] = useState(false);
  const validateOrderConditionOnClick = async () => {
    setOrderConditionLoading(true);

    const minLoadingTimeMillis = 1000;
    const validationPromise = validateOrderCondition(orderCondition)
      .catch(error => {
        if (error instanceof ApiError) {
          showAlert({message: `Invalid Order Condition: ${error.message}`, severity: 'error'});
        } else {
          showAlert({message: `Failed to run validation: ${error.message}`, severity: 'error'});
        }
        return -1;
      });
    const timerPromise = new Promise(resolve => { setTimeout(resolve, minLoadingTimeMillis) });

    await Promise.all([validationPromise, timerPromise]);

    setOrderConditionLoading(false);

    const result = await validationPromise;
    if (result === -1) {
      return;
    }

    setIsOrderConditionValidated(true);
    showAlert({message: 'Order condition validated!', severity: 'success'});
  }

  return(
    <FormControl fullWidth>
      <OutlinedInput
        fullWidth
        endAdornment={
          <ConditionValidateButton
            isOrderConditionValidated={isOrderConditionValidated}
            orderCondition={orderCondition}
            orderConditionLoading={orderConditionLoading}
            validateOrderConditionOnClick={validateOrderConditionOnClick}
          />
        }
        multiline={rows > 1}
        placeholder="Order Condition"
        rows={rows}
        size="small"
        sx={{
          '& .MuiFormHelperText-root': {
            color: "rgba(255, 255, 255, 0.75)",
          }
        }}
        value={orderCondition}
        onChange={(e) => { setOrderCondition(e.target.value); setIsOrderConditionValidated(false); }}
      />
      <FormHelperText
        style={{marginLeft: '0px'}}>
        <Link color="inherit" href="https://tread-labs.gitbook.io/api-docs/conditional-order" underline="hover">
          Condition must be validated before submitting
        </Link>
      </FormHelperText>
    </FormControl>
  )
}

function ConditionValidateButton({
  orderCondition, validateOrderConditionOnClick,
  orderConditionLoading, isOrderConditionValidated
}) {
  const successColor = theme.palette.success.main;
  const styles = {
    justifySelf: 'center',
    fontSize: 35,
    '&.animatedCheck': {
      animation: 'checkmark-animation 0.7s ease-in-out',
      color: successColor,
    },
    '@keyframes checkmark-animation': {
      '0%': {
        transform: 'scale(0)',
        opacity: 0,
      },
      '100%': {
        transform: 'scale(1)',
        opacity: 1,
      },
    },
  };

  const renderComponent = () => {
    if (isOrderConditionValidated) {
      return <CheckCircleOutline className='animatedCheck' sx={styles} />;
    } if (orderConditionLoading) {
      return <Button
        disabled
        fullWidth
        variant="contained"
      >
        <CircularProgress color='inherit' size={20} />
      </Button>;
    }
    return <Button
      fullWidth
      color='secondary'
      disabled={!orderCondition}
      variant="contained"
      onClick={validateOrderConditionOnClick}>
        Validate
    </Button>;

  }

  return (
    <div>
      {renderComponent()}
    </div>
  )
}

export { ConditionValidateButton, ConditionValidateField, StrategyParamSelect };
