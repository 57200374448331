import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import {
  CustomColorStyledTableCell,
  StyledTableCell,
  StyledIBMTypography
} from '../../shared/orderTable/util';
import { numberWithSpaces } from '../../util';

export default function AssetsTableCell({column, value, row, cellColor}) {
  let CellRender = StyledTableCell

  if (column.id === 'symbol') {
    CellRender = CustomColorStyledTableCell(cellColor)
  }

  let cellValue = value;

  const pnlStyleByValue = (val) => {
    if (val >= 0) {
      return 'success.main';
    }

    return 'error.main';
  }

  const signByValue = (val) => {
    if (val > 0) {
      return '+';
    } if (val < 0) {
      return '-'
    }

    return '';
  }

  if (column.number) {
    if (column.id === 'amount') {
      if (row.asset_type === 'mixed') {
        cellValue = 'N/A';
      } else {
        cellValue = Number(value).toFixed(4);
      }
    } else if (column.id === 'unrealized_profit') {
      if (row.asset_type === 'token' || row.asset_type === 'mixed') {
        cellValue = 'N/A';
      } else {
        cellValue = <StyledIBMTypography color={pnlStyleByValue(value)} fontFamily='monospace' variant='body1'>
          {signByValue(value)}{Number(Math.abs(value)).toFixed(2)}%
        </StyledIBMTypography>
      }
    } else {
      cellValue = `$${numberWithSpaces(Number(value).toFixed(2))}`;
    }
  }

  return (
    <CellRender
      align={column.align}
      key={column.id}
      style={{whiteSpace: 'nowrap'}}
      width='20%'
    >
      {cellValue}
    </CellRender>
  );
}