import {ApiError, emailHelp} from "../../apiServices";

export const handleHelpClick = async (id, time_start, duration, account_names, status, pct_filled, showAlert) => {
  try {
    const errorsData = await emailHelp(id);
    const startDate = new Date(time_start);
    const timeEnd = new Date(startDate.getTime() + duration * 1000);

    const emailBody = `
Order ID: ${id}
Order Errors: ${JSON.stringify(errorsData)}
Account Names: ${account_names}
Time Start: ${time_start}
Time End: ${timeEnd.toISOString()}
Status: ${status}
Duration: ${duration}
Fill Percentage: ${pct_filled}%`.trim();

    const hiddenLink = document.createElement('a');
    hiddenLink.href = `mailto:help@tread.fi?subject=Issue%20with%20order%20${id}&body=${encodeURIComponent(emailBody)}`;
    hiddenLink.style.display = 'none';
    document.body.appendChild(hiddenLink);
    hiddenLink.click();
    document.body.removeChild(hiddenLink);
  } catch (e) {
    if (e instanceof ApiError) {
      showAlert({ severity: 'error', message: e.message });
    } else {
      throw e;
    }
  }
};
