import { useTheme } from '@emotion/react';
import { Box, Card, CardContent } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { StyledBenchmarkTableCell } from '../orderTable/util';


function OrderBenchmarks({ benchmarkData }) {
  const {
    arrival_price,
    arrival_cost,
    vwap,
    vwap_cost,
    fee_notional,
    fee_asset,
    fee_cost,
    interval_volume,
    base_asset,
    pov,
  } = benchmarkData

  const theme = useTheme()

  const renderFee = () => {
    if (fee_asset === 'USD' || !fee_asset) {
      return `$${Number(fee_notional).toFixed(6)}`
    }

    return `${Number(fee_notional).toFixed(6)} ${fee_asset}`
  }

  const renderFeeCost = () => {
    if (!fee_cost) {
      return 'Not Available'
    }

    return `${Number(fee_cost).toFixed(4)} bps`
  }

  return (
    <Card style={{height: '100%', overflow: 'auto', p: 0, margin: 0}}>
      { Object.keys(benchmarkData).length === 0 ? (
        <CardContent style={{height: '100%', padding: 0}}>
          <Box alignItems="center"
            display="flex"
            height="100%"
            justifyContent="center">
            <ScaleLoader color="#FFFFFF" />
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <Typography variant='h4'>Benchmarks</Typography>
          <TableContainer style={{height: 'calc(100% - 26px)'}}>
            <Table style={{ tableLayout: 'fixed' }}>
              <TableHead>
                <TableRow>
                  <StyledBenchmarkTableCell />
                  <StyledBenchmarkTableCell align="right">
                    <Typography>Value</Typography>
                  </StyledBenchmarkTableCell>
                  <StyledBenchmarkTableCell align="right">
                    <Typography>Benchmark</Typography>
                  </StyledBenchmarkTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledBenchmarkTableCell>
                    <Typography>Arrival</Typography>
                  </StyledBenchmarkTableCell>
                  <StyledBenchmarkTableCell align="right">
                    <Typography fontFamily="monospace" variant="body2">
                        ${Number(arrival_price).toFixed(6)}
                    </Typography>
                  </StyledBenchmarkTableCell>
                  <StyledBenchmarkTableCell align="right">
                    <Typography
                      color={arrival_cost > 0 ? theme.palette.charts.red : theme.palette.charts.green}
                      fontFamily="monospace"
                      variant="body2"
                    >
                      {Number(arrival_cost).toFixed(4)} bps
                    </Typography>
                  </StyledBenchmarkTableCell>
                </TableRow>
                <TableRow>
                  <StyledBenchmarkTableCell>
                    <Typography>VWAP</Typography>
                  </StyledBenchmarkTableCell>
                  <StyledBenchmarkTableCell align="right">
                    <Typography fontFamily="monospace" variant="body2">
                        ${Number(vwap).toFixed(6)}
                    </Typography>
                  </StyledBenchmarkTableCell>
                  <StyledBenchmarkTableCell align="right">
                    <Typography
                      color={vwap_cost > 0 ? theme.palette.charts.red : theme.palette.charts.green}
                      fontFamily="monospace"
                      variant="body2"
                    >
                      {Number(vwap_cost).toFixed(4)} bps
                    </Typography>
                  </StyledBenchmarkTableCell>
                </TableRow>
                <TableRow>
                  <StyledBenchmarkTableCell>
                    <Typography>Exchange Fee</Typography>
                  </StyledBenchmarkTableCell>
                  <StyledBenchmarkTableCell align="right">
                    <Typography fontFamily="monospace" variant="body2">
                      {renderFee()}
                    </Typography>
                  </StyledBenchmarkTableCell>
                  <StyledBenchmarkTableCell align="right">
                    <Typography fontFamily="monospace" variant="body2">
                      {renderFeeCost()}
                    </Typography>
                  </StyledBenchmarkTableCell>
                </TableRow>
                <TableRow>
                  <StyledBenchmarkTableCell>
                    <Typography>Participation Rate</Typography>
                  </StyledBenchmarkTableCell>
                  <StyledBenchmarkTableCell align="right">
                    <Typography fontFamily="monospace" variant="body2">
                      {Number(interval_volume).toFixed(3)} {base_asset}
                    </Typography>
                  </StyledBenchmarkTableCell>
                  <StyledBenchmarkTableCell align="right">
                    <Typography fontFamily="monospace" variant="body2">
                      {Number(pov).toFixed(3)}%
                    </Typography>
                  </StyledBenchmarkTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      )}
    </Card>
  )
}

export { OrderBenchmarks };
