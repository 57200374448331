import { useTheme } from '@emotion/react';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import QueryStatsIcon from '@mui/icons-material/QueryStatsOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useAtom } from 'jotai';
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ApiError, cancelMultiOrder, openInNewTab, pauseMultiOrder, pauseOrder, resubmitOrder, resubmitRemainingOrder,
  resumeMultiOrder,
  resumeOrder, submitCancel
} from '../../apiServices';
import { OPEN_NEW_TAB_ON_SUBMIT } from '../../constants';
import { BASEURL, removeFalsyAndEmptyKeys } from '../../util';
import { BasicModal } from '../Modal';
import { ErrorContext } from '../context/ErrorProvider';
import { useUserMetadata } from '../context/UserMetadataProvider';
import CollapsedRow from './CollapsedRow';
import TableOrderConfirmationModel from './TableOrderConfirmationModel';
import {
  StyledHeaderTableCellWithLine, StyledPaddingTableCell,
  StyledTableCell, getOrderPath,
} from './util';
import CollapsedChildsRow from './CollapsedChildsRow';
import CollapsedChainedRow from './CollapsedChainedRow';
import DisplayRowDetails from './DisplayRowDetails';


const getColumns = ( dashboardView ) => {
  return [
    ...(!dashboardView ? [{ id: 'account_names', label: 'Accounts', width: 100,  align: 'left' }] : []),
    { id: 'pair' || 'pairs', label: 'Pair', width: 160,  align: 'left' },
    { id: 'side', label: 'Side', width: 30,  align: 'left' },
    ...(!dashboardView ? [{ id: 'executed_notional', label: 'Executed Notional',
      width: 100,  align: 'right' }] : []),
    { id: 'target_qty', label: 'Target Quantity', width: 150,  align: 'right' },
    ...(!dashboardView ?
      [{ id: 'executed_price', label: 'Average Executed Price', width: 100,  align: 'right' }] : []),
    { id: 'pct_filled', label: 'Filled', width: 30, align: 'center' },
    { id: 'time_start', label: 'Time Start', width: 170, align: 'left' },
    { id: 'super_strategy', label: 'Strategy', width: 140, align: 'left' },
    { id: 'status', label: 'Status', width: 30, align: 'left' },
  ]
};

const getOrderType = (row) => {
  if (row.side === 'Multi') {
    return 'Multi';
  } if (row.side === 'Chained') {
    return 'Chained';
  }
  return 'Single';

}

function ViewOrderTooltip({row, theme}) {
  const [viewTooltipOpen, setViewTooltipOpen] = useState(false);

  const navigate = useNavigate();

  const handleViewOrder = (order, isMiddleClick = false) => {
    const url = getOrderPath(order);

    if (isMiddleClick) {
      window.open(`${BASEURL}${url}`, '_blank');
    } else {
      navigate(url);
    }
  }

  return (<Tooltip
    disableHoverListener
    open={viewTooltipOpen}
    placement="top"
    title="View Order"
  >
    <span>
      <IconButton
        aria-label="view"
        color='main.info2'
        size='small'
        sx={{
          marginRight: '3px',
          padding: '0',
          ':hover': {
            color: theme.palette.primary.main,
          }
        }}
        onAuxClick={(event) => {
          event.stopPropagation();
          handleViewOrder(row, true);
        }}
        // eslint-disable-next-line react/jsx-sort-props
        onClick={(event) => {
          event.stopPropagation();
          handleViewOrder(row);
        }}
        // eslint-disable-next-line react/jsx-sort-props
        variant="outlined"
        onMouseEnter={() => setViewTooltipOpen(true)}
        onMouseLeave={() => setViewTooltipOpen(false)}
      >
        <QueryStatsIcon/>
      </IconButton>
    </span>
  </Tooltip>)
}

function DisplayRow({
  row, columns, setCancelModalData, dashboardView,
  setIsResubmit, setRowData, handlePause, handleResume, setOpenModal, theme
}){

  const orderRow = row

  const [open, setOpen] = useState(false);
  const [pauseTooltipOpen, setPauseTooltipOpen] = useState(false);
  const [resumeTooltipOpen, setResumeTooltipOpen] = useState(false);
  const [cancelTooltipOpen, setCancelTooltipOpen] = useState(false);
  const [resubmitTooltipOpen, setResubmitTooltipOpen] = useState(false);
  const [resubmitRemainingTooltipOpen, setResubmitRemainingTooltipOpen] = useState(false);

  const StyledCell = dashboardView ? StyledPaddingTableCell : StyledTableCell;

  let isTerminalStatus = ["COMPLETE", "CANCELED"].includes(orderRow.status);
  if ((orderRow.side === 'Multi' || 'Chained') && orderRow.calculated_status) {
    isTerminalStatus = ["COMPLETE", "CANCELED"].includes(orderRow.calculated_status);
    orderRow.status = orderRow.calculated_status;
  }

  const orderType = getOrderType(orderRow)

  // onClick, get the child order data and display in collapsable
  return (
    <>
      <TableRow
        hover
        key={orderRow.id}
        sx={{
          // already a border on collapseable row
          '& .MuiTableCell-root': {
            borderBottom: 0
          }
        }}
        onClick={() => { setOpen(!open); }}
      >
        {columns.map((column) => {
          return DisplayRowDetails({row: orderRow, column, StyledCell, theme})
        })}
        <StyledCell
          sx={{
            minWidth: 124,
            height: 32,
            justifyContent: 'flex-end', // Aligns the content to the right
            alignItems: 'center', // Aligns the content to the center
            textAlign: 'end'
          }}>
          <Tooltip
            disableHoverListener
            disabled={orderRow.side === 'Multi' || orderRow.side === 'Chained'}
            open={resubmitRemainingTooltipOpen}
            placement="top"
            title='Resubmit Remaining Order'>
            <span>
              <IconButton aria-label="resubmit_remaining"
                color='main.info2'
                disabled={!isTerminalStatus}
                size='small'
                sx={{
                  marginRight: '3px',
                  padding: '0',
                  ':hover': {
                    color: theme.palette.primary.main,
                  }
                }}
                variant="contained"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsResubmit(false)
                  setRowData(orderRow)
                  setOpenModal(true)
                }}
                onMouseEnter={() => setResubmitRemainingTooltipOpen(true)}
                onMouseLeave={() => setResubmitRemainingTooltipOpen(false)}
              >
                <CallMissedOutgoingIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            disableHoverListener
            disabled={orderRow.side === 'Multi' || orderRow.side === 'Chained'}
            open={resubmitTooltipOpen}
            placement="top"
            title='Resubmit Order'>
            <span>
              <IconButton aria-label="resubmit"
                color='main.info2'
                disabled={!isTerminalStatus}
                size='small'
                sx={{
                  marginRight: '3px',
                  padding: '0',
                  ':hover': {
                    color: theme.palette.primary.main,
                  }

                }}
                variant="contained"
                onClick={(event) => {
                  event.stopPropagation();
                  setIsResubmit(true)
                  setRowData(orderRow)
                  setOpenModal(true)
                }}
                onMouseEnter={() => setResubmitTooltipOpen(true)}
                onMouseLeave={() => setResubmitTooltipOpen(false)}
              >
                <ReplayIcon />
              </IconButton>
            </span>
          </Tooltip>
          <ViewOrderTooltip row={orderRow} theme={theme}/>
          {orderRow.status === 'PAUSED' ?
            <Tooltip disableHoverListener
              open={pauseTooltipOpen}
              placement="top"
              title='Resume Order'>
              <span>
                <IconButton aria-label="resume"
                  color='main.info2'
                  size='small'
                  sx={{
                    marginRight: '3px',
                    padding: '0',
                    ':hover': {
                      color: theme.palette.primary.main,
                    }
                  }}
                  variant="contained"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleResume(orderRow.id, orderType);
                  }}
                  onMouseEnter={() => setPauseTooltipOpen(true)}
                  onMouseLeave={() => setPauseTooltipOpen(false)}
                >
                  <PlayCircleOutlineIcon />
                </IconButton>
              </span>
            </Tooltip> :
            <Tooltip
              disableHoverListener
              open={resumeTooltipOpen}
              placement="top"
              title='Pause Order'>
              <span>
                <IconButton aria-label="pause"
                  color='main.info2'
                  disabled = { !["ACTIVE"].includes(orderRow.status) }
                  size='small'
                  sx={{
                    marginRight: '3px',
                    padding: '0',
                    ':hover': {
                      color: theme.palette.primary.main,
                    }
                  }}
                  variant="contained"
                  onClick={(event) => {
                    event.stopPropagation();
                    handlePause(orderRow.id, orderType);
                  }}
                  onMouseEnter={() => setResumeTooltipOpen(true)}
                  onMouseLeave={() => setResumeTooltipOpen(false)}
                >
                  <PauseCircleOutlineIcon />
                </IconButton>
              </span>
            </Tooltip>
          }
          <Tooltip
            disableHoverListener
            open={cancelTooltipOpen}
            title='Cancel Order'>
            <span>
              <IconButton aria-label="cancel"
                color='error'
                disabled = { isTerminalStatus }
                placement="top"
                size='small'
                sx={{
                  padding: '0'
                }}
                variant="contained"
                onClick={(event) => {
                  event.stopPropagation();
                  setCancelModalData({open: true, orderId: orderRow.id, orderType});
                }}
                onMouseEnter={() => setCancelTooltipOpen(true)}
                onMouseLeave={() => setCancelTooltipOpen(false)}
              >
                <HighlightOffIcon />
              </IconButton>
            </span>
          </Tooltip>
        </StyledCell>
      </TableRow>
      <TableRow>
        {/* + 1 for colSpan is for actions */}
        <TableCell colSpan={getColumns(dashboardView).length + 1} style={{ padding: 0 }}>
          {orderRow.side === 'Multi' ? (
            <CollapsedChildsRow
              childOrders={orderRow.child_order_ids}
              columns={columns}
              open={open}
              row={orderRow}
              StyledCell={StyledCell}
              ViewOrderTooltip={ViewOrderTooltip}
            />
          ) : orderRow.side === 'Chained' ? (
            <CollapsedChainedRow
              columns={columns}
              open={open}
              ordersInChain={orderRow.orders_in_chain}
              StyledCell={StyledCell}
              ViewOrderTooltip={ViewOrderTooltip}
            />
          ) : (
            <CollapsedRow
              dashboardView={dashboardView}
              open={open}
              row={orderRow}
              style={{ padding: 0 }}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

function SharedOrderTable({
  orderData = [],
  orderRefresh,
  dashboardView = false,
  FormAtoms,
}) {
  const { user, isRetail } = useUserMetadata();

  const [initialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [cancelModalData, setCancelModalData] = useState({open: false, orderId: null, orderType: null});
  const {setHasError, setErrorContent} = useContext(ErrorContext);

  const [openModal, setOpenModal] = useState(false);
  const [submitModalMessage, setSubmitModalMessage] = useState('');

  const [rowData, setRowData] =  useState({ side: 'buy'})

  // refactor this to reducers
  const [isResubmit, setIsResubmit] = useState(true)

  const theme = useTheme()
  const cancelModalOpen = cancelModalData.open
  const openNewTabOnSubmit = user.preferences ? user.preferences[OPEN_NEW_TAB_ON_SUBMIT] : false

  const handleCancel = async (rowId, orderType) => {
    setCancelModalData({open: false, orderId: null, orderType: null});
    try{
      if(orderType === 'Multi'){
        await cancelMultiOrder(rowId)
      } else {
        await submitCancel(rowId);
      }
      setErrorContent({severity: 'success', message: 'Successfully canceled the specified order.'})
      orderRefresh()
    } catch(e) {
      if (e instanceof ApiError) {
        setErrorContent({severity: 'error', message: e.message})
      } else {
        throw e;
      }
    }
    setHasError(true);
  }

  const reSubmit = async (row) => {
    try {
      const orderResubmitData = {
        ...removeFalsyAndEmptyKeys(row),
        alpha_tilt: row.alpha_tilt,
        engine_passiveness: row.engine_passiveness,
        schedule_discretion: row.schedule_discretion,
      }
      const result = await resubmitOrder({...orderResubmitData});

      if (openNewTabOnSubmit) {
        openInNewTab(getOrderPath(result.order))
      }

      setErrorContent({severity: 'success', message: 'Successfully resubmitted the specified order.'})
      orderRefresh()
    } catch(e) {
      if (e instanceof ApiError) {
        setErrorContent({severity: 'error', message: e.message})
      } else {
        throw e;
      }
    }
    setOpenModal(false)
    setHasError(true);
  };

  const reSubmitRemaining = async (row) => {
    try {
      const orderResubmitData = {
        ...removeFalsyAndEmptyKeys(row),
        alpha_tilt: row.alpha_tilt,
        engine_passiveness: row.engine_passiveness,
        schedule_discretion: row.schedule_discretion,
      }
      const result = await resubmitRemainingOrder({...orderResubmitData});

      if (openNewTabOnSubmit) {
        openInNewTab(getOrderPath(result.order))
      }

      setErrorContent({severity: 'success', message: 'Successfully resubmitted the specified order.'})
      orderRefresh()
    } catch(e) {
      if (e instanceof ApiError) {
        setErrorContent({severity: 'error', message: e.message})
      } else {
        throw e;
      }
    }
    setOpenModal(false)
    setHasError(true)
  }

  const handlePause = async (id, orderType) => {
    try {
      if(orderType === 'Multi'){
        await pauseMultiOrder(id)
      } else {
        await pauseOrder(id);
      }
      setErrorContent({ severity: 'success', message: 'Successfully paused the specified order.' });
      orderRefresh()
    } catch (e) {
      if (e instanceof ApiError) {
        setErrorContent({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }
    setHasError(true);
  };

  const handleResume = async (id, orderType) => {
    try {
      if(orderType === 'Multi'){
        await resumeMultiOrder(id)
      } else {
        await resumeOrder(id);
      }
      setErrorContent({ severity: 'success', message: 'Successfully resumed the specified order.' });
      orderRefresh()
    } catch (e) {
      if (e instanceof ApiError) {
        setErrorContent({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }

    setHasError(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const columns = getColumns(dashboardView)
  const tableContainerHeight = dashboardView ? '100%' : 'calc(100% - 60px)';

  return (
    <Box sx={{ height: '100%' }}>
      <TableContainer style={{height: tableContainerHeight}}>
        <Table stickyHeader aria-label="sticky table" size='small'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledHeaderTableCellWithLine
                  align={column.align}
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    width : column.width || undefined,
                  }}
                >
                  {column.label}
                </StyledHeaderTableCellWithLine>
              ))}
              <StyledHeaderTableCellWithLine
                align='left'
                key="actions"
                style={{ width: 190 }}
              >
                {}
              </StyledHeaderTableCellWithLine>
            </TableRow>
          </TableHead>
          <TableBody sx={{overflow: 'auto'}}>
            {orderData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <DisplayRow
                  columns={columns}
                  dashboardView={dashboardView} handlePause={handlePause}
                  handleResume={handleResume}
                  key={row.id}
                  row={row}
                  setCancelModalData={setCancelModalData}
                  setIsResubmit={setIsResubmit}
                  setOpenModal={setOpenModal}
                  setRowData={setRowData}
                  theme={theme}
                />
              ))}
          </TableBody>
        </Table>
        {orderData.length === 0 && (
          <Box alignItems="center" display="flex" height='calc(100% - 60px)' justifyContent="center">
            { user && user.is_authenticated ?
              <Typography variant='h6'>No orders found</Typography> :
              <Stack direction='row' gap={1}>
                <Button href='account/login/'
                  size='small' sx={{ backgroundColor: theme.palette.primary.dark2 }} variant='contained'>
                  <Typography variant='h6'>Log in</Typography></Button>
                { isRetail && <><Typography sx={{paddingTop: '4px'}} variant='h6' > or </Typography>
                  <Button color='primary' href='account/signup/' size='small' variant='contained'>
                    <Typography color={theme.palette.text.offBlack} variant="h6">
                Sign up
                    </Typography>
                  </Button></>}
                <Typography sx={{paddingTop: '4px'}} variant='h6' > to see orders</Typography>
              </Stack>
            }
          </Box>
        )}
      </TableContainer>

      { !dashboardView ? <TablePagination
        component="div"
        count={orderData.length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[20]}
        sx={{height: '60px'}}
        onPageChange={handleChangePage}
      /> : null }
      <BasicModal
        confirmButtonText='Yes'
        handleConfirm={() => handleCancel(cancelModalData.orderId, cancelModalData.orderType)}
        message='Are you sure you want to cancel this order?'
        open={cancelModalOpen}
        setOpen={(e) => setCancelModalData((prev) => { return  {...prev, open: e }}) }/>
      <TableOrderConfirmationModel
        dashboardView={dashboardView}
        data={rowData}
        FormAtoms={FormAtoms}
        handleResubmit={reSubmit}
        handleResubmitRemaining={reSubmitRemaining}
        initialLoadValue={initialLoadValue}
        isBuy={rowData.side === 'buy'}
        isResubmit={isResubmit}
        modalText={submitModalMessage}
        open={openModal}
        setOpen={setOpenModal}
      />
    </Box>
  )
}

export { SharedOrderTable };