/* eslint-disable react-hooks/exhaustive-deps */
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef, useState } from 'react';
import CheckBox from '@mui/material/Checkbox';
import { useAtom } from 'jotai';
import { getOrderBook } from "../../apiServices";
import { ignoreScrollEvent, noArrowStyle, smartRound } from "../../util";
import PairAutoComplete from './PairAutoComplete';
import { NumericFormatCustom } from './NumberFieldFormat';

export default function LimitPriceField(
  {
    exchanges,
    isBuySide,
    limitPrice,
    selectedAccountExchangeNames,
    selectedPairName,
    setLimitPrice,
    showAlert,
    simple = false,
    tokenPairs,
    FormAtoms,
  }
) {
  const [dynamicLimitPrice, setDynamicLimitPrice] = useState({pair: '', exchange: 'Binance', offset: ''});
  const [previewPrice, setPreviewPrice] = useState(-1);
  const [openDynamicLimitPriceDialog, setOpenDynamicLimitPriceDialog] = useState(false);
  const [previewLimitPrice, setPreviewLimitPrice] = useState('');
  const [isLoadingPreviewPrice, setIsLoadingPreviewPrice] = useState(false);
  const [isLoadingLimitPrice, setIsLoadingLimitPrice] = useState(false);
  const [selectedPair, setSelectedPair] = useState(null)
  const [limitPriceQuickSetting, setLimitPriceQuickSetting] = useAtom(FormAtoms.limitPriceQuickSettingAtom);
  const [isOOLEnabled, setIsOOLEnabled] = useAtom(FormAtoms.isOOLEnabledAtom);
  const previousOffset = useRef(dynamicLimitPrice.offset);

  const fetchPreviewPrice = async () => {
    let price = -1;
    setIsLoadingPreviewPrice(true);
    try {
      const result = await getOrderBook(dynamicLimitPrice.exchange, dynamicLimitPrice.pair);

      if (result && result.asks.length > 0 && result.bids.length > 0) {
        price = (result.asks[0].price + result.bids[0].price) / 2;
      }
    } catch (e) {
      showAlert({message: 'Failed to preview dynamic limit price.', severity: 'error'});
    }
    setIsLoadingPreviewPrice(false);

    return price;
  }

  useEffect(() => {
    const loadPreviewPrice = async (fetchPrice) => {
      let price = previewPrice;

      if (fetchPrice) {
        price = await fetchPreviewPrice();

        if (price === -1) {
          return;
        }

        setPreviewPrice(price);
      }

      const offset = Number(dynamicLimitPrice.offset);
      setPreviewLimitPrice((price + offset).toFixed(8));
    }

    if (dynamicLimitPrice.exchange && dynamicLimitPrice.pair) {
      loadPreviewPrice(previousOffset.current === dynamicLimitPrice.offset);
    }

    previousOffset.current = dynamicLimitPrice.offset;
  }, [dynamicLimitPrice])

  useEffect(() => {
    if(limitPrice === ''){
      setIsOOLEnabled(false)
    }
  }, [limitPrice])


  const handleDynamicLimitPriceParamChange = async (val, attr) => {
    if (!val) {
      return;
    }
    setDynamicLimitPrice({...dynamicLimitPrice, [attr]: val})
  }

  const handleLimitPriceDialogClose = (e) => {
    const baseLimitPrice = `${dynamicLimitPrice.pair}@${dynamicLimitPrice.exchange}`;
    const offset = Number(dynamicLimitPrice.offset);

    let newLimitPrice = '';

    if (offset) {
      const operator = offset >= 0 ? '+' : '-';
      newLimitPrice = `${baseLimitPrice} ${operator} ${Math.abs(offset)}`;
    } else {
      newLimitPrice = baseLimitPrice;
    }

    setLimitPrice(newLimitPrice);
    setOpenDynamicLimitPriceDialog(false);
  }

  const getMidPrice = async () => {
    try {
      const result = await getOrderBook(selectedAccountExchangeNames[0], selectedPairName);

      if (result && result.asks.length > 0 && result.bids.length > 0) {
        return (result.asks[0].price + result.bids[0].price) / 2;
      }
    } catch (e) {
      showAlert({message: 'Failed to get price, please input manually.', severity: 'error'});
    }

    setLimitPriceQuickSetting(null);

    return NaN;
  }

  const getTopOfBook = async (side) => {
    try {
      const result = await getOrderBook(selectedAccountExchangeNames[0], selectedPairName);

      if (result && result[side] && result[side].length > 0) {
        return result[side][0].price;
      }
    } catch (e) {
      showAlert({message: 'Failed to get price, please input manually.', severity: 'error'});
    }

    setLimitPriceQuickSetting(null);

    return NaN;
  }

  const numSelectedExchanges = selectedAccountExchangeNames ? selectedAccountExchangeNames.length : 0;

  useEffect(() => {
    if (!limitPrice && limitPriceQuickSetting) {
      setLimitPriceQuickSetting(null);
    }
  }, [limitPrice]);

  useEffect(() => {
    setLimitPrice('');
  }, [isBuySide, selectedPairName, numSelectedExchanges]);

  const handleLimitPriceChange = (e) => {
    const val = e.target.value;
    setLimitPrice(val)
  }

  const limitPriceQuickSettingOptions = {
    'Mid': getMidPrice,
    'Bid': async () => getTopOfBook('bids'),
    'Ask': async () => getTopOfBook('asks'),
    '1%': async () => await getMidPrice() * (isBuySide ? 0.99 : 1.01),
    '2%': async () => await getMidPrice() * (isBuySide ? 0.98 : 1.02),
  }

  useEffect(() => {
    const loadNewLimitPrice = async () => {
      const selectedOption = limitPriceQuickSetting;

      setLimitPriceQuickSetting(selectedOption)
      const callable = limitPriceQuickSettingOptions[selectedOption];

      if (!callable) {
        return;
      }

      setIsLoadingLimitPrice(true);
      const price = await callable();

      if (price) {
        setLimitPrice(smartRound(price));
      }

      setIsLoadingLimitPrice(false);
    }

    loadNewLimitPrice()
  }, [limitPriceQuickSetting])

  const readyToPickLimitPrice = selectedPairName && selectedAccountExchangeNames.length > 0;

  const limitPriceRadioLabel = (option) => {
    if (!option.includes('%')) {
      return option;
    }

    const commonStyle = {marginBottom: '3px', marginLeft: '-4px', marginRight: '-2px'}

    const icon = isBuySide ?
      <ArrowDownwardIcon fontSize='small' sx={commonStyle}/> :
      <ArrowUpwardIcon fontSize='small' sx={commonStyle}/>;

    return <Stack direction='row' display='flex' flexWrap='nowrap'>
      {icon}{option}
    </Stack>;
  }

  return (
    <div>
      <TextField
        fullWidth
        disabled={!readyToPickLimitPrice}
        InputLabelProps={{
          shrink: Boolean(limitPrice) || Boolean(limitPriceQuickSetting),
          sx: {
            color: 'gray', // Default color for the label
            '&.Mui-focused': {
              color: 'white', // Color when the input is focused
            },
            '&.MuiInputLabel-shrink': {
              color: 'white', // Color when the label is shrunk (after input is entered)
            }
          }
        }}
        InputProps={{
          step: 'any',
          endAdornment: (
            <InputAdornment position="end">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {isLoadingLimitPrice && (
                  <CircularProgress size={20} sx={{ color: 'info.main', marginRight: '4px' }} />
                )}
                {limitPrice && (
                  <IconButton onClick={() => setLimitPrice('')}>
                    <ClearIcon fontSize='small' />
                  </IconButton>
                )}
                { !simple &&
                  <Button
                    disabled={!readyToPickLimitPrice}
                    variant='outlined'
                    onClick={() => setOpenDynamicLimitPriceDialog(true)}
                  >
                    <Typography variant='subtitle'>
                      Dynamic
                    </Typography>
                  </Button>
                }
              </div>
            </InputAdornment>
          )
        }}
        label="Limit Price"
        value={limitPrice}
        onChange={handleLimitPriceChange}
      />
      <FormControl>
        <Stack direction='row'>
          <RadioGroup
            row
            aria-labelledby="limit-price-quick-setting"
            disabled={!readyToPickLimitPrice}
            name="limit-price-quick-setting"
            sx={{flexWrap: 'nowrap', fontSize: '0.7rem'}}
            value={limitPriceQuickSetting}
            onChange={(e) => setLimitPriceQuickSetting(e.target.value)}
          >
            {
              Object.keys(limitPriceQuickSettingOptions)
                .filter(option => isBuySide ? option !== 'Ask' : option !== 'Bid')
                .map(option => <FormControlLabel
                  control={<Radio />}
                  disabled={!readyToPickLimitPrice}
                  key={option}
                  label={limitPriceRadioLabel(option)}
                  sx={{
                    marginRight: '7px',
                    '& .MuiTypography-root': {
                      width: '30px',
                      fontSize: '0.65rem',
                    },
                    '& .MuiRadio-root': {
                      padding: '9px 3px 9px 9px',
                    },
                  }}
                  value={option}
                />)
            }
          </RadioGroup>
          { !simple &&
            <FormControlLabel
              color="grey.disabled"
              control={
                <CheckBox
                  checked={isOOLEnabled}
                  color='primary'
                  sx={{
                    paddingRight: '4px',
                    '& .MuiFormControlLabel-label-root': {
                      width: '30px',
                      fontSize: '0.7rem',
                    },
                  }}
                  onChange={() => setIsOOLEnabled(prevState => !prevState)} />}
              disabled={!readyToPickLimitPrice || limitPrice === ''}
              label="OOL Pause"
              sx={{
                whiteSpace: 'nowrap',
              }}/>
          }
        </Stack>
      </FormControl>
      { !simple &&
        <Dialog maxWidth='md' open={openDynamicLimitPriceDialog}>
          <DialogTitle width='400px'>
            Dynamic Limit Price
          </DialogTitle>
          <DialogContent dividers>
            <Stack direction='column' gap={2}>
              <PairAutoComplete
                fullWidth
                handleSelectedPair={(pair) => {
                  handleDynamicLimitPriceParamChange(pair?.id, 'pair')
                  setSelectedPair(pair)
                }}
                selectedPair={selectedPair}
                tokenPairs={tokenPairs}
              />
              <Stack direction='row' display='flex' gap={1}>
                <Select
                  fullWidth
                  value={dynamicLimitPrice.exchange}
                  onChange={(e) => handleDynamicLimitPriceParamChange(e.target.value, 'exchange')}
                >
                  {exchanges && exchanges.length > 0 && (
                    exchanges.map(e => <MenuItem key={e} value={e}>{e}</MenuItem>)
                  )}
                </Select>
                <TextField
                  fullWidth
                  autoComplete='off'
                  InputProps={{ step: 'any', inputComponent: NumericFormatCustom,}}
                  label="Offset ($)"
                  sx={noArrowStyle}
                  value={dynamicLimitPrice.offset}
                  onChange={(e) => handleDynamicLimitPriceParamChange(e.target.value, 'offset')}
                  onWheel={ignoreScrollEvent}
                />
              </Stack>
              <Stack direction='row' display='flex'>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    step: 'any',
                    endAdornment: isLoadingPreviewPrice ?
                      <CircularProgress size={20} sx={{color: 'info.main'}} /> : '',
                    inputComponent: NumericFormatCustom,
                  }}
                  label="Preview Relative Price ($)"
                  value={previewLimitPrice}
                  variant='filled'
                  onWheel={ignoreScrollEvent}
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDynamicLimitPriceDialog(false)}>Cancel</Button>
            <Button onClick={handleLimitPriceDialogClose}>Submit</Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  )
}