import { Autocomplete, Divider, Stack, TextField, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DateTime, Settings } from 'luxon';
import { useAtom } from "jotai";
import React, { useEffect, useState, useRef } from 'react';
import AlgoNumberField from "./AlgoNumberField";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc)
dayjs.extend(timezone);

const timeZoneNoOffset = (timeZone) => {
  if(timeZone !== null && timeZone !== undefined) {
    return timeZone.split(" ")[0]
  }
  return null
}

class CustomLuxonAdapter extends AdapterLuxon {
  constructor({ locale, timeZone }) {
    super({ locale });
    this.timeZone = timeZone; // Set the timezone
  }

  // Override date methods to apply the timezone
  date(value) {
    if (typeof value === 'undefined') {
      return DateTime.now().setZone(this.timeZone);
    }
    return DateTime.fromJSDate(value, { zone: this.timeZone });
  }

  // Continue overriding methods as necessary to handle timezone
}

const timezoneList = Intl.supportedValuesOf("timeZone");
const formattedTimezoneList = timezoneList.map(tz => {
  const now = dayjs();
  const offset = now.tz(tz).format('Z');  // Format as +HH:mm or -HH:mm
  return `${tz} UTC${offset}`;
});

const initialTimeZone = () =>  `${dayjs.tz.guess()} UTC${dayjs().tz(dayjs.tz.guess()).format('Z')}`

function DurationField({
  selectedDuration,
  setSelectedDuration,
  isCalculatingDuration,
  disabled,
  FormAtoms,
}) {

  const [durationStartDate, setDurationStartDate] = useAtom(FormAtoms.durationStartTimeAtom);
  const [durationEndDate, setDurationEndDate] = useAtom(FormAtoms.durationEndTimeAtom);

  const [startDatePlaceholder, setStartDatePlaceholder] = useState(dayjs.utc().local().format('MM/DD/YYYY HH:mm'));
  const [endDatePlaceholder, setEndDatePlaceholder] = useState(dayjs.utc().local()
    .add(selectedDuration, 'seconds').format('MM/DD/YYYY HH:mm'));

  const [timeZone, setTimeZone] = useState(initialTimeZone);
  const [inputTimeZone, setInputTimeZone] = useState(initialTimeZone);

  const timeoutRef = useRef(null);

  const [recentTimeZones, setRecentTimeZones] = useState( JSON.parse(localStorage.getItem('recentTimeZones')) || []);


  const updateRecentTimeZones = (selectedTimeZone) => {
    if(recentTimeZones.includes(selectedTimeZone)){
      return
    }
    let updatedRecent = recentTimeZones.filter(zone => zone !== selectedTimeZone);
    updatedRecent.unshift(selectedTimeZone);
    if (updatedRecent.length > 3) {
      updatedRecent = updatedRecent.slice(0, 3);
    }
    setRecentTimeZones(updatedRecent);
    localStorage.setItem('recentTimeZones', JSON.stringify(updatedRecent));
  };

  const handleStartDateChange = (value) => {
    const currentTime = DateTime.local().setZone(timeZoneNoOffset(timeZone));
    const currentMinute = currentTime.minute;
    const nextFifthMinute = Math.ceil((currentMinute + 1) / 5) * 5;

    let newDate = value;
    if (value && value < currentTime) {
      newDate = currentTime.set({ minute: nextFifthMinute, second: 0, millisecond: 0 });
    }

    setEndDatePlaceholder(dayjs.utc().local().add(selectedDuration, 'seconds').format('MM/DD/YYYY HH:mm'))
    if (!durationEndDate && selectedDuration) {
      setDurationEndDate(newDate.plus({seconds: selectedDuration}));
    }
    else if (value && durationEndDate) {
      const duration = durationEndDate.diff(value, 'seconds').seconds;
      setSelectedDuration(duration);
    }
    setDurationStartDate(newDate);
  };

  const handleEndDateChange = (value) => {
    setDurationEndDate(value);

    if (durationStartDate && value) {
      const duration = value.diff(durationStartDate, 'seconds').seconds;
      setSelectedDuration(duration);
    }
  };

  const handleDurationChange = (value) => {
    const durationSeconds = Number(value) * 60;
    setSelectedDuration(value === '' ? null : durationSeconds);
    if (value && durationStartDate) {
      setDurationEndDate(durationStartDate.plus({seconds: durationSeconds}));
    }
  }

  // handles updates on placeholders
  useEffect(() => {
    const updateCurrentTime = () => {
      const currentTime = dayjs.utc().tz(timeZoneNoOffset(timeZone)).local()
      setStartDatePlaceholder(currentTime.format('MM/DD/YYYY HH:mm'));
      setEndDatePlaceholder(currentTime.add(selectedDuration, 'seconds').format('MM/DD/YYYY HH:mm'))
      timeoutRef.current = setTimeout(updateCurrentTime, 1000); // Update every 10 seconds
    };


    updateCurrentTime();
    return () => {
      clearTimeout(timeoutRef.current);
    };

  }, [timeZone, selectedDuration]);

  const divider = { isDivider: true };

  let options = [...recentTimeZones];
  if (recentTimeZones.length > 0) {
    options.push(divider);
  }
  options = options.concat(formattedTimezoneList.filter(zone => !recentTimeZones.includes(zone)));


  return (

    <Stack direction='column' spacing={2}>
      <Stack direction='row' spacing={2} width='100%'>

        <AlgoNumberField
          disabled={disabled}
          InputProps={{
            step: 'any',
            endAdornment: (
              isCalculatingDuration &&
          <InputAdornment position='end'>
            <CircularProgress size={20} sx={{color: 'info.main'}} />
          </InputAdornment>
            )
          }}
          label='Duration (minutes)'
          sx={{
            width: '30%',
            minWidth: 70,
          }}
          value={selectedDuration !== null ? (Math.max(selectedDuration / 60, 1)).toFixed(0) : ''}
          onChange={(e) => {
            const {value} = e.target;
            handleDurationChange(value);
          }}
        />
        <Autocomplete
          disableClearable
          disablePortal
          getOptionLabel={(option) => option.isDivider ? '' : option}
          inputValue={inputTimeZone}
          options={options}
          renderInput={(params) => <TextField {...params} label="Timezone" />}
          renderOption={(props, option) => {
            const { key, ...restProps } = props;
            if (option.isDivider) {
              return (
                <div key={key} {...restProps} style={{ pointerEvents: 'none', width: '100%' }}>
                  <Divider style={{ margin: '10px 0' }} />
                  <Typography color="textSecondary" style={{ marginLeft: 10 }} variant="caption">
                                All Time Zones
                  </Typography>
                </div>
              );
            }
            return <li key={key} {...restProps}>{option}</li>;
          }}
          size='small'
          sx={{
            width: '70%',
            minWidth: 140,
          }}
          value={timeZone}
          onChange={(event, newValue) => {
            if(newValue !== null && newValue !== undefined){
              setTimeZone(newValue);
              updateRecentTimeZones(newValue);
            }

          }}
          onInputChange={(event, newInputValue) => {
            setInputTimeZone(newInputValue);
          }}
        />
      </Stack>
      <Stack direction='row' spacing={2} width='100%'>
        <LocalizationProvider adapterLocale="en-US" adapterZone={timeZone} dateAdapter={CustomLuxonAdapter}>
          <DateTimePicker
            disablePast
            fullwidth
            ampm={false}
            label={`Time Start (${timeZoneNoOffset(timeZone)})`}
            slotProps={{
              textField: {
                size: 'small',
                inputProps: {
                  placeholder: startDatePlaceholder, style: { textAlign: 'right', fontSize: '0.8rem' }
                },
                InputLabelProps: { shrink: true },
              },
              field: { clearable: true, onClear: () => setDurationStartDate(undefined) },
            }}
            sx={{width: '50%'}}
            timezone={timeZoneNoOffset(timeZone)}
            value={durationStartDate || null}
            onChange={(value) => handleStartDateChange(value)}
          />
          <DateTimePicker
            disablePast
            fullwidth
            ampm={false}
            label={`Time End (${timeZoneNoOffset(timeZone)})`}
            slotProps={{
              textField: {
                size: 'small' ,
                inputProps: {
                  placeholder: endDatePlaceholder, style: { textAlign: 'right', fontSize: '0.8rem' }
                },
                InputLabelProps: { shrink: true },
              },
              field: { clearable: true, onClear: () => setDurationEndDate(undefined) },
            }}
            sx={{width: '50%'}}
            timezone={timeZoneNoOffset(timeZone)}
            value={durationEndDate || null}
            onChange={(value) => handleEndDateChange(value)}
          />
        </LocalizationProvider>

      </Stack>


    </Stack>
  );
}

export default DurationField;
