import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import {
  StyledTableCell
} from '../../shared/orderTable/util';
import AssetsTableCell from './AssetsTableCell';

export default function AccountAssetsTable({ assets, columns, topN = 10 }) {
  const theme = useTheme();
  const chartColors = [
    ...Object.values(theme.palette.exchangeBalance),
  ];

  const getSymbolColor = (index) => {
    if (index >= 10) {
      return '#000';
    }

    return chartColors[index];
  };

  const filterTop = (rows, n = 10) => {
    const topAssets = rows.slice(0, topN);
    return topAssets;
  }

  const filterAfterN = (rows, n = 10) => {
    return rows.slice(topN);
  };

  const calculatePnL = (row) => {
    if (row.asset_type === "token") {
      return 0;
    }

    // % of unrealized PnL over original notional
    return 100 * row.unrealized_profit / (Math.abs(row.notional) - row.unrealized_profit);
  };

  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <TableContainer sx={{ height: '90%' }}>
      <Table stickyHeader aria-label="sticky table" size='small'>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell
                align={column.align}
                key={column.id}
                style={{
                  minWidth: column.minWidth,
                  width: column.width || undefined,
                }}
              >
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filterTop(assets).map((row, i) => (
            <TableRow key={row.symbol} style={{ width: '100%' }}>
              {columns.map((column) => {
                let value = row[column.id];
                if (column.id === "unrealized_profit") {
                  value = calculatePnL(row);
                }
                return (
                  <AssetsTableCell
                    cellColor={getSymbolColor(i)}
                    column={column}
                    key={column.id}
                    row={row}
                    value={value}
                  />
                );
              })}
            </TableRow>
          ))}

          {assets.length > 10 && ( // if assets > 10 then display this option
            <>
              <TableRow>
                <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
                  <IconButton aria-label="expand row" size="small" onClick={handleToggle}>
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                  {open ? "See Less" : "See More"} (${assets.slice(topN).reduce((sum, row) => sum + Math.abs(row.notional), 0).toFixed(2)} Notional)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={columns.length} style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Collapse unmountOnExit in={open} timeout="auto">
                    <Table size="small">
                      <TableBody>
                        {filterAfterN(assets).map((row, i) => (
                          <TableRow key={row.symbol} style={{ width: '100%' }}>
                            {columns.map((column) => {
                              let value = row[column.id];
                              if (column.id === "unrealized_profit") {
                                value = calculatePnL(row);
                              }
                              return (
                                <AssetsTableCell
                                  cellColor={getSymbolColor(i + topN)}
                                  column={column}
                                  key={column.id}
                                  row={row}
                                  value={value}
                                />
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>

    </TableContainer>
  );
}
