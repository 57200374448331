import FormControl from "@mui/material/FormControl/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ListSubheader from "@mui/material/ListSubheader";
import React from "react";

export default function StrategyDropdown({
  value,
  setValue,
  strategies,
  setTrajectory,
  trajectories,
  includeSimple = false,
  applyPresets,
}){

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    const isStrategy = selectedValue in strategies;
    setValue(selectedValue);
    if (isStrategy) {
      const trajectory_id = strategies[selectedValue].strategy_id; // uuid of trajectory
      setTrajectory(trajectory_id); // set trajectory to strategy id
      applyPresets(selectedValue)
    } else {
      setTrajectory(selectedValue); // set trajectory to strategy id (same as strategy name)
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="select-strategy-dropdown-label">
        <Typography>
          Strategy
        </Typography>
      </InputLabel>
      { includeSimple ? (
        <Select
          id="select-strategy-dropdown"
          label="Strategy"
          labelId="select-strategy-dropdown-label"
          value={value}
          onChange={(e) => handleChange(e)}
        >
          <ListSubheader>
            <Typography>
              Strategies
            </Typography>
          </ListSubheader>
          {
            Object.keys(strategies).filter((id) => strategies[id].is_super_strategy).map((id) => (
              <MenuItem key={id} sx={{paddingLeft: '2em'}} value={id}>
                {strategies[id].name}
              </MenuItem>
            ))
          }
          <ListSubheader>
            <Typography>
              Simple
            </Typography>
          </ListSubheader>
          {
            Object.keys(trajectories).filter((id) => !trajectories[id].schedule).map((id) => (
              <MenuItem key={id} sx={{paddingLeft: '2em'}} value={id}>
                {trajectories[id].name}
              </MenuItem>
            ))
          }

        </Select>
      ) : (
        <Select
          id="select-strategy-dropdown"
          label="Strategy"
          labelId="select-strategy-dropdown-label"
          value={value}
          onChange={(e) => {
            setValue(e.target.value)
          }}>
          {
            Object.keys(strategies).filter((id) => !strategies[id].schedule).map((id) => (
              <MenuItem key={id} sx={{paddingLeft: '2em'}} value={id}>
                {strategies[id].name}
              </MenuItem>
            ))
          }
        </Select>
      )}
    </FormControl>
  )
}