import Box from '@mui/material/Box';
import React, { useContext, useEffect, useState } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { SharedOrderTable } from '../../shared/orderTable/SharedOrderTable';
import { BASEURL } from '../../util';

import { ErrorContext } from '../../shared/context/ErrorProvider';
import ChipStatusFilter from '../../shared/orderTable/ChipStatusFilter';

function OptionOrderTable({FormAtoms}) {
  const [orders, setOrders] = useState([])
  const [statusHighlight, setStatusHighlight] = useState([])
  const [loading, setLoading] = useState(true);
  const [typeFilter, setTypeFilter] = useState([]);

  const {setHasError, setErrorContent} = useContext(ErrorContext);

  const getDashboardOrders = async (reload = false) => {
    try {
      const url = new URL(`${BASEURL}/ems/get_option_orders`);
      const params = {
        'status': statusHighlight,
        'type': typeFilter };
      url.search = new URLSearchParams(params).toString();

      // don't show loading mask for interval reload
      if (!reload) {
        setLoading(true);
      }
      const response = await fetch(url);
      const messageData = await response.json();

      setOrders(messageData.orders);
      if (!reload) {
        setLoading(false);
      }
    } catch (error) {
      setHasError(true);
      setErrorContent(error.message);
    }
  };

  useEffect(() => {
    getDashboardOrders();
    const intervalId = setInterval(() => { getDashboardOrders(true) }, 2000);

    return () => clearInterval(intervalId);
  }, [statusHighlight, typeFilter]);

  return (
    <>
      <ChipStatusFilter
        dashboardView
        optionsView
        loadOrders={getDashboardOrders}
        setStatusHighlight={setStatusHighlight}
        setTypeFilter={setTypeFilter}
        statusHighlight={statusHighlight}
        typeFilter={typeFilter}
      />
      <div style={{height: '100%', overflow: 'auto' }}>
        {loading ? (
          <Box
            alignItems="center"
            display="flex"
            height='100%'
            justifyContent="center"
          >
            <ScaleLoader color="#FFFFFF" />
          </Box>) : (
          <SharedOrderTable
            dashboardView
            FormAtoms={FormAtoms}
            orderData={orders}
            orderRefresh={getDashboardOrders} />
        )}
      </div>
    </>
  )
}

export default OptionOrderTable;
