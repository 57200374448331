import { useAtom } from 'jotai';

export const CoreFormHandlers = ({
  resetForm,
  setBaseQtyPlaceholder,
  setQuoteQtyPlaceholder,
  setSelectedAccounts,
  setSelectedSide,
  setSelectedPair,
  selectedAccounts,
  accounts,
}) => {

  const handleSelectedAccountsChange = (value, setCommonExchangeName) => {

    resetForm()
    setSelectedAccounts(value);
  }

  const handleSelectedSide = (event, newSide, selectedSide, selectedPair) => {
    if (newSide === null || selectedSide === newSide) {
      return;
    }

    if (!selectedPair) {
      setBaseQtyPlaceholder('Base Asset Qty');
      setQuoteQtyPlaceholder('Quote Asset Qty');
    } else {
      setBaseQtyPlaceholder(selectedPair.base);
      setQuoteQtyPlaceholder(selectedPair.quote);
    }

    resetForm()
    setSelectedSide(newSide);
  };

  const handleSelectedPair = async (newPair) => {
    if (newPair) {
      setBaseQtyPlaceholder(newPair.base);
      setQuoteQtyPlaceholder(newPair.quote);
    }
    setSelectedPair(newPair);
    resetForm()
  };

  return {
    handleSelectedAccountsChange, handleSelectedSide, handleSelectedPair
  }

}