import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

export function BasicModal({open, setOpen, message, handleConfirm, confirmButtonText}) {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3,
  };

  const buttonStyle = {
    width: 70,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 3,
  }

  const closeButtonStyle = {
    position: 'absolute',
    right: 8,
    top: 8,
  };

  return (
    <div>
      <Modal
        closeAfterTransition
        aria-describedby="transition-modal-description"
        aria-labelledby="transition-modal-title"
        open={open}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        slots={{ backdrop: Backdrop }}
        onClose={() => setOpen(false)}
      >
        <Fade in={open}>
          <Box display='flex' flexDirection='column' justifyContent='center' sx={modalStyle}>
            <IconButton
              aria-label="close"
              sx={closeButtonStyle}
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              component="h2"
              id="transition-modal-title"
              style={{
                marginTop: 8,
                marginLeft: 12,
                marginRight: 12,
              }}
              variant="h6"
            >
              {message}
            </Typography>
            <Button
              color='primary'
              sx={buttonStyle}
              variant='contained'
              onClick={handleConfirm}
            >
              <Typography color='black'>
                {confirmButtonText}
              </Typography>
            </Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
