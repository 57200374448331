/* eslint-disable no-param-reassign */
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { fetchOrderEntryFormData } from '../../apiServices';
import ChipStatusFilter from '../../shared/orderTable/ChipStatusFilter';
import { SharedOrderTable } from '../../shared/orderTable/SharedOrderTable';
import * as FormAtoms from '../dashboard/orderEntry/hooks/useFormReducer';
import { OrderSearch } from './OrderSearch';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';

function OrderViewPage() {
  const [orders, setOrders] = useState([])
  const [statusHighlight, setStatusHighlight] = useState([])
  const [typeFilter, setTypeFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setInitialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);

  const { isDev } = useUserMetadata();

  const getInitialOrders = async () => {
    let data;
    try {
      data = await fetchOrderEntryFormData();
    } catch (e) {
      console.error(e)
      return;
    }

    setLoading(false)

    const pairs = data.pairs.map((p) => {
      return {
        base: p.base,
        exchanges: p.exchanges,
        id: p.name,
        is_contract: p.is_contract,
        is_inverse: p.is_inverse,
        label: p.name,
        market_type: p.market_type,
        quote: p.quote,
      }
    });

    const accounts = {}
    data.accounts.forEach((acc) => {
      const scopedAccName = acc.user === data.user_id ? acc.name : `${acc.username}/${acc.name}`;
      const displayName = `${acc.exchange} - ${scopedAccName}`;
      accounts[scopedAccName] =  {
        displayName,
        id: acc.id,
        name: scopedAccName,
        exchangeName: acc.exchange,
      }
    });

    const indexedStrategies = data.strategies.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});

    const indexedSuperStrategies = data.super_strategies.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {});

    setInitialLoadValue({
      tokenPairs: pairs,
      accounts,
      exchanges: data.exchanges,
      strategies: indexedSuperStrategies,
      trajectories: indexedStrategies,
      superStrategies: indexedSuperStrategies,
      strategyParams: data.strategy_params,
      orderTemplates: data.order_templates,
    });
  }

  useEffect(() => {
    getInitialOrders()
  }, [])

  return (
    <Stack spacing={1} sx={{height: '100%'}}>
      <Card sx={{margin: 0, height: '80px'}}>
        <CardContent sx = {{overflowX: 'auto', margin: 0}}>
          <OrderSearch
            setLoading={setLoading}
            setOrders={setOrders}
            statusHighlight={statusHighlight}
            typeFilter={typeFilter}
          />
        </CardContent>
      </Card>
      <Card style={{height:'calc(100% - 80px)'}}>
        <CardContent>
          <ChipStatusFilter
            isDev={isDev}
            setStatusHighlight={setStatusHighlight}
            setTypeFilter={setTypeFilter}
            statusHighlight={statusHighlight}
            typeFilter={typeFilter}
          />
          <Box style={{height: 'calc(100% - 40px)'}}>
            { loading ? (
              <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
                minHeight='100%'
              >
                <ScaleLoader color="#FFFFFF" />
              </Box>) : (
              <SharedOrderTable
                FormAtoms={FormAtoms}
                orderData={orders}
                orderRefresh={getInitialOrders}
              />)
            }
          </Box>
        </CardContent>
      </Card>
    </Stack>
  )
}

export default OrderViewPage;

