import treadDark from './tread-logo-dark.png';
import treadFullDark from './full-tread-dark.png';
import treadFullDarkSvg from './tread-full-dark.svg';

const LOGOS = {
  treadDark,
  treadFullDark,
  treadFullDarkSvg,
};

export default LOGOS;
